import openWebSearchLogo from 'assets/images/intro/openWebSearch-logo.png';
import biodtLogo from 'assets/images/intro/biodt-logo.png';
import everestLogo from 'assets/images/intro/everest_logo.png';
import compbiomedLogo from 'assets/images/intro/compbiomed-logo.png';
import ACROSSLogo from 'assets/images/intro/across_logo.png';
import ligateLogo from 'assets/images/intro/ligate-logo.png';
import KubernetesLogo from 'assets/images/intro/Kubernetes-logo.svg';
import keycloakLogo from 'assets/images/intro/keycloak-logo.svg';
import celeryLogo from 'assets/images/intro/celery-logo.svg';
import airflowLogo from 'assets/images/intro/airflow-logo.svg';
import iRODSLogo from 'assets/images/intro/iRODS-logo.svg';
import HEAppE_logo from 'assets/images/intro/HEAppE_logo.png';

export const usedByProjects = [
    {
        logo: openWebSearchLogo,
        logoWidth: '300px',
        logoHeight: '160px',
        url: 'https://openwebsearch.eu/',
    },
    {
        logo: biodtLogo,
        logoWidth: '300px',
        logoHeight: '130px',
        url: 'https://biodt.eu',
    },
    {
        logo: everestLogo,
        logoWidth: '330px',
        logoHeight: '50px',
        url: 'https://everest-h2020.eu',
    },
    {
        logo: compbiomedLogo,
        logoWidth: '300px',
        logoHeight: '75px',
        url: 'https://www.hpccoe.eu/BioComputation-2/',
    },
    {
        logo: ACROSSLogo,
        logoWidth: '300px',
        logoHeight: '60px',
        url: 'https://www.acrossproject.eu/',
    },
    {
        logo: ligateLogo,
        logoWidth: '300px',
        logoHeight: '140px',
        url: 'https://www.ligateproject.eu',
    },
];

export const technologies = [
    {
        logo: KubernetesLogo,
        logoWidth: '45px',
        logoHeight: '45px',
        url: 'https://kubernetes.io/',
    },
    {
        logo: keycloakLogo,
        logoWidth: '120px',
        logoHeight: '50px',
        url: 'https://www.keycloak.org/',
    },
    {
        logo: celeryLogo,
        logoWidth: '120px',
        logoHeight: '55px',
        url: 'https://docs.celeryq.dev/en/stable/',
    },
    {
        logo: airflowLogo,
        logoWidth: '120px',
        logoHeight: '70px',
        url: 'https://airflow.apache.org/',
    },
    {
        logo: iRODSLogo,
        logoWidth: '120px',
        logoHeight: '55px',
        url: 'https://irods.org/',
    },
    {
        logo: HEAppE_logo,
        logoWidth: '80px',
        logoHeight: '58px',
        url: 'https://heappe.eu/web/',
    },
];

export const footers = [
    {
        title: 'IT4Innovations',
        mail: 'support@lexis.tech',
        link: null,
        linkTitle: null,
    },
    {
        title: 'About',
        mail: null,
        link: 'https://docs.lexis.tech/_pages/architecture/architecture.html',
        linkTitle: 'Documentation',
    },
];
