import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook } from 'react-redux';
import reducers from './reducers';
import { airflowApi } from './airflow/airflowSlice';
import { stagingApi } from './staging/stagingSlice';
import { iRODSApi } from './iRODS/iRODSSlice';
import { transferApi } from './transfer/transferSlice';
import { userOrgApi } from './userOrg/userOrgSlice';
import { workflowCatalogV1Api } from './workflowCatalogV1/workflowCatalogV1Slice';
import { airflowApiQueryErrorLogger } from './airflow/airflowApiQueryErrorLogger';
import { stagingApiQueryErrorLogger } from './staging/stagingApiQueryErrorLogger';
import { iRODSApiQueryErrorLogger } from './iRODS/iRODSApiQueryErrorLogger';
import { transferApiQueryErrorLogger } from './transfer/transferApiQueryErrorLogger';
import { userOrgApiQueryErrorLogger } from './userOrg/userOrgApiQueryErrorLogger';
import { workflowCatalogV1ApiQueryErrorLogger } from './workflowCatalogV1/workflowCatalogV1ApiQueryErrorLogger';
import { workflowContainersApiQueryErrorLogger } from './workflowContainers/workflowContainersApiQueryErrorLogger';
import { workflowContainersApi } from './workflowContainers/workflowContainersSlice';

const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false }).concat([
            airflowApi.middleware,
            stagingApi.middleware,
            iRODSApi.middleware,
            transferApi.middleware,
            userOrgApi.middleware,
            workflowCatalogV1Api.middleware,
            workflowContainersApi.middleware,
            transferApiQueryErrorLogger,
            airflowApiQueryErrorLogger,
            stagingApiQueryErrorLogger,
            iRODSApiQueryErrorLogger,
            userOrgApiQueryErrorLogger,
            workflowCatalogV1ApiQueryErrorLogger,
            workflowContainersApiQueryErrorLogger,
        ]),
});

export type RootState = ReturnType<typeof reducers>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, dispatch, useSelector, useDispatch };
