import React, { createContext, useEffect, useReducer } from 'react';
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { AuthProps, JWTContextType } from 'types/auth';

const initialState: AuthProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
};

const JWTContext = createContext<JWTContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const getAuth = await axios.get('/oauth2/auth');

                if (getAuth.status === 202) {
                    const response = await axios.get('/oauth2/userinfo');
                    const { user } = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user,
                        },
                    });
                } else {
                    dispatch({
                        type: LOGOUT,
                    });
                    localStorage.clear();
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT,
                });
                localStorage.clear();
            }
        };

        init();
    }, []);

    const logout = () => {
        dispatch({ type: LOGOUT });
        let rd_url = encodeURIComponent(`${window.location.protocol}//${window.location.host}/login`);
        let kc_logout_uri =
            `${process.env.REACT_APP_KEYCLOAK_URI}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout?client_id=${process.env.REACT_APP_CLIENT_ID}&post_logout_redirect_uri=` +
            rd_url;
        location.replace(
            `${window.location.protocol}//${window.location.host}/oauth2/sign_out?rd=${encodeURIComponent(
                kc_logout_uri
            )}`
        );
    };

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, logout, updateProfile }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
