import { Avatar, ListItemText, ListItemSecondaryAction, Box, ListItemIcon, MenuItem, Tooltip } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Typography } from '@mui/material';
import { Dag } from 'store/airflow/airflowApi';
import { useGetDagRunsQuery } from 'store/airflow/enhancedAirflowApi';
import { LinearProgress } from '@mui/material';
import BasicAccordion from 'components/basicAccordion';
import { useNavigate } from 'react-router';
import { workflowExecutionDetailsPath } from 'routes/paths';

interface DagRunsListProps {
    dag: Dag;
}

const WorkflowExecutionsList = ({ dag }: DagRunsListProps) => {
    const navigate = useNavigate();
    const getDagRuns = useGetDagRunsQuery({ dagId: dag?.dag_id as string });
    const dagRuns = getDagRuns?.data?.dag_runs;

    const data =
        dagRuns &&
        [...dagRuns]
            ?.sort(
                (a, b) =>
                    new Date(b.data_interval_start as string).getTime() -
                    new Date(a.data_interval_start as string).getTime()
            )
            ?.slice(0, 5);

    return dagRuns?.length ? (
        <Box>
            <BasicAccordion
                key={`${dag?.dag_id}`}
                name={`${dag?.dag_id}`}
                content={
                    getDagRuns?.isLoading ? (
                        <LinearProgress />
                    ) : (
                        data?.map(dagRun => (
                            <MenuItem
                                key={`${dagRun?.dag_id}-${dagRun?.start_date}-${dagRun?.data_interval_start}`}
                                onClick={() =>
                                    navigate(workflowExecutionDetailsPath(dagRun?.dag_id, dagRun?.dag_run_id as string))
                                }>
                                <ListItemIcon>
                                    <Tooltip title={`${dagRun?.state}`} placement='left'>
                                        <Avatar
                                            sx={
                                                (dagRun?.state as string) === 'success'
                                                    ? {
                                                          color: 'success.main',
                                                          bgcolor: 'success.lighter',
                                                      }
                                                    : (dagRun?.state as string) === 'running'
                                                    ? {
                                                          color: '#3CC0C7',
                                                          bgcolor: '#B3E9E9',
                                                      }
                                                    : (dagRun?.state as string) === 'failed'
                                                    ? {
                                                          color: '#671704',
                                                          bgcolor: '#F3250E',
                                                      }
                                                    : null
                                            }>
                                            <AccountTreeIcon />
                                        </Avatar>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ ml: 2, mr: 15 }}
                                    primary={
                                        <Typography variant='h6'>{dagRun?.dag_run_id?.substring(0, 30)}</Typography>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Typography variant='caption' noWrap>
                                        {new Date(dagRun?.data_interval_start as string).toLocaleDateString()},
                                        {new Date(dagRun?.data_interval_start as string).getHours()} :
                                        {new Date(dagRun?.data_interval_start as string).getMinutes()}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </MenuItem>
                        ))
                    )
                }
            />
        </Box>
    ) : (
        <></>
    );
};

export default WorkflowExecutionsList;
