import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import lexisLogo from './../../images/intro/LEXIS_The_Platform_RGB_transparent.png';
import { ThemeDirection } from 'types/config';

const AuthBackground = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'fixed',
                filter: 'blur(18px)',
                zIndex: -1,
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
                transform: theme.direction === ThemeDirection.RTL ? 'rotate(180deg)' : 'inherit',
            }}>
            <img src={lexisLogo} alt='Lexis' width='100%' />
        </Box>
    );
};

export default AuthBackground;
