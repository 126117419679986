import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import { ReactNode } from 'react';

interface BasicAccordionProps {
    name?: string | ReactNode;
    content?: any;
    additionalText?: string;
    mt?: number;
    mb?: number;
    ml?: number;
    mr?: number;
    p?: number;
    defaultExpanded?: boolean;
    disabled?: boolean;
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function BasicAccordion({
    name,
    content,
    mt,
    mb,
    ml,
    mr,
    p,
    additionalText,
    defaultExpanded,
    disabled,
}: BasicAccordionProps) {
    return (
        <Box mt={mt} mb={mb} ml={ml} mr={mr}>
            <Accordion defaultExpanded={defaultExpanded} disabled={disabled}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
                    {name && (
                        <>
                            <Typography variant='h4' sx={{ width: '33%', flexShrink: 0 }}>
                                {name}
                            </Typography>
                        </>
                    )}
                    {additionalText && <Typography sx={{ color: 'text.secondary' }}>{additionalText}</Typography>}
                </AccordionSummary>
                {content && <AccordionDetails sx={{ p: p }}>{content}</AccordionDetails>}
            </Accordion>
        </Box>
    );
}
