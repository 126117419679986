// Dashboard
export const workflowsDashboardPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'workflowsDashboard' : '/workflowsDashboard';

export const datasetsDashboardPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'datasetsDashboard' : '/datasetsDashboard';

export const generalDashboardPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'generalDashboard' : '/generalDashboard';

export const downloadTransferStatusesDashboardPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'downloadTransferStatusesDashboard' : '/downloadTransferStatusesDashboard';
export const uploadTransferStatusesDashboardPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'uploadTransferStatusesDashboard' : '/uploadTransferStatusesDashboard';

// Workflows
export const workflowsPath = (isDefaultPath?: boolean) => (!isDefaultPath ? 'workflows' : '/workflows');
export const workflowDetailsPath = (workflowId?: string): string => {
    if (!workflowId) {
        return 'workflows/:workflowId/details';
    } else return `/workflows/${workflowId}/details`;
};
export const workflowExecutionsPath = (workflowId?: string): string => {
    if (!workflowId) {
        return 'workflows/:workflowId/workflowExecutions';
    } else return `/workflows/${workflowId}/workflowExecutions`;
};
export const workflowExecutionDetailsPath = (workflowId?: string, workflowExId?: string): string => {
    if (!workflowId && !workflowExId) {
        return 'workflows/:workflowId/workflowExecutions/:workflowExId/details';
    } else return `/workflows/${workflowId}/workflowExecutions/${workflowExId}/details`;
};
export const workflowExecutionLogsPath = (workflowId?: string, workflowExId?: string, taskId?: string): string => {
    if (!workflowId && !workflowExId && !taskId) {
        return 'workflows/:workflowId/workflowExecutions/:workflowExId/details/:taskId/logs/';
    } else return `/workflows/${workflowId}/workflowExecutions/${workflowExId}/details/${taskId}/logs/`;
};

// Datasets
export const uploadDataSetDetailsPath = (dataSetId?: string): string => {
    if (!dataSetId) {
        return 'uploadDataSets/:dataSetId/details';
    } else return `/uploadDataSets/${dataSetId}/details`;
};
export const uploadDataSetsPath = (isDefaultPath?: boolean) => (!isDefaultPath ? 'uploadDataSets' : '/uploadDataSets');
export const uploadDataSetPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'uploadDataSets/upload' : '/uploadDataSets/upload';
export const uploadFileListPath = (dataSetId?: string): string => {
    if (!dataSetId) {
        return 'uploadDataSets/:dataSetId/details/fileList';
    } else return `/uploadDataSets/${dataSetId}/details/fileList`;
};
export const uploadFileEditPath = (dataSetId?: string, requestId?: string): string => {
    if (!dataSetId) {
        return 'uploadDataSets/:dataSetId/details/fileList/:requestId/edit';
    } else return `/uploadDataSets/${dataSetId}/details/fileList/${requestId}/edit`;
};

export const workflowDataSetsPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'workflowDataSets' : '/workflowDataSets';
export const workflowDataSetDetailsPath = (dataSetId?: string): string => {
    if (!dataSetId) {
        return 'workflowDataSets/:dataSetId/details';
    } else return `/workflowDataSets/${dataSetId}/details`;
};
export const workflowsFileListPath = (dataSetId?: string): string => {
    if (!dataSetId) {
        return 'workflowDataSets/:dataSetId/details/fileList';
    } else return `/workflowDataSets/${dataSetId}/details/fileList`;
};
export const workflowsFileEditPath = (dataSetId?: string, requestId?: string): string => {
    if (!dataSetId) {
        return 'workflowDataSets/:dataSetId/details/fileList/:requestId/edit';
    } else return `/workflowDataSets/${dataSetId}/details/fileList/${requestId}/edit`;
};

export const publicDataSetsPath = (isDefaultPath?: boolean) => (!isDefaultPath ? 'publicDataSets' : '/publicDataSets');
export const publicDataSetDetailsPath = (dataSetId?: string): string => {
    if (!dataSetId) {
        return 'publicDataSets/:dataSetId/details';
    } else return `/publicDataSets/${dataSetId}/details`;
};
export const publicFileListPath = (dataSetId?: string): string => {
    if (!dataSetId) {
        return 'publicDataSets/:dataSetId/details/fileList';
    } else return `/publicDataSets/${dataSetId}/details/fileList`;
};
export const publicFileEditPath = (dataSetId?: string, requestId?: string): string => {
    if (!dataSetId) {
        return 'publicDataSets/:dataSetId/details/fileList/:requestId/edit';
    } else return `/publicDataSets/${dataSetId}/details/fileList/${requestId}/edit`;
};

// Projects
export const projectsPath = (isDefaultPath?: boolean) => (!isDefaultPath ? 'projects' : '/projects');
export const projectDetailsPath = (shortName?: string): string => {
    if (!shortName) {
        return 'projects/:shortName/details';
    } else return `/projects/${shortName}/details`;
};

export const projectAccessRequestsPath = (shortName?: string): string => {
    if (!shortName) {
        return 'projects/:shortName/details/accessRequests';
    } else return `/projects/${shortName}/details/accessRequests`;
};

export const projectDetailsManageTagsPath = (shortName?: string): string => {
    if (!shortName) {
        return 'projects/:shortName/details/manageTags';
    } else return `/projects/${shortName}/details/manageTags`;
};

export const usersPath = (shortName?: string): string => {
    if (!shortName) {
        return 'projects/:shortName/details/users';
    } else return `/projects/${shortName}/details/users`;
};

export const profilePath = (isDefaultPath?: boolean) => (!isDefaultPath ? 'profile' : '/profile');

export const resourcesPath = (shortName?: string): string => {
    if (!shortName) {
        return 'projects/:shortName/details/resources';
    } else return `/projects/${shortName}/details/resources`;
};

// Management
export const usersManagementPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'usersManagement' : `/usersManagement`;
export const providersPath = (isDefaultPath?: boolean) => (!isDefaultPath ? 'providers' : `/providers`);
export const locationsPath = (isDefaultPath?: boolean) => (!isDefaultPath ? 'locations' : `/locations`);
export const resourceManagementPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'resourceManagement' : `/resourceManagement`;

export const resourceAssignmentsPath = (resourceId?: string): string => {
    if (!resourceId) {
        return 'resourceManagement/:resourceId/resourceAssignments';
    } else return `/resourceManagement/${resourceId}/resourceAssignments`;
};

// Containers
export const containersPath = (isDefaultPath?: boolean) => (!isDefaultPath ? 'containers' : `/containers`);
export const containerCreatePath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'containers/create' : `/containers/create`;
export const containerWorkflowCreatePath = (dataSetId?: string): string => {
    if (!dataSetId) {
        return 'containers/:dataSetId/workflowCreate';
    } else return `/containers/${dataSetId}/workflowCreate`;
};

// HPC App
export const HPCApplicationsPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'HPCApplications' : `/HPCApplications`;
export const HPCApplicationsCreatePath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'HPCApplications/create' : `/HPCApplications/create`;

export const HPCApplicationsWorkflowCreatePath = (commandTemplateName?: string) => {
    if (commandTemplateName === undefined) {
        return 'HPCApplications/:commandTemplateName/workflowCreate';
    } else {
        return `/HPCApplications/${commandTemplateName}/workflowCreate`;
    }
};

// Workflow Templates
export const workflowTemplatesPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'workflowTemplates' : `/workflowTemplates`;

// Custom HPC Jobs
export const customHPCJobsPath = (isDefaultPath?: boolean) => (!isDefaultPath ? 'customHPCJobs' : `/customHPCJobs`);
export const customHPCJobsExecutePath = (workflowId?: string) =>
    !workflowId ? 'customHPCJobs/:workflowId/execute' : `/customHPCJobs/${workflowId}/execute`;
export const customHPCJobsReExecutePath = (workflowId?: string, workflowExId?: string) => {
    if (!workflowId && !workflowExId) {
        return 'customHPCJobs/:workflowId/workflowExecutions/:workflowExId/reExecute';
    } else return `/customHPCJobs/${workflowId}/workflowExecutions/${workflowExId}/reExecute`;
};
export const createJobscriptPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'customHPCJobs/createJobscript' : `/customHPCJobs/createJobscript`;

export const createJobscriptWorkflowPath = (dataSetId?: string): string => {
    if (!dataSetId) {
        return 'customHPCJobs/:dataSetId/workflowCreate';
    } else return `/customHPCJobs/${dataSetId}/workflowCreate`;
};

export const customHPCJobsWorkflowExecutionsPath = (workflowId?: string): string => {
    if (!workflowId) {
        return 'customHPCJobs/:workflowId/workflowExecutions';
    } else return `/customHPCJobs/${workflowId}/workflowExecutions`;
};

export const customHPCJobsWorkflowExecutionDetailsPath = (workflowId?: string, workflowExId?: string): string => {
    if (!workflowId && !workflowExId) {
        return 'customHPCJobs/:workflowId/workflowExecutions/:workflowExId/details';
    } else return `/customHPCJobs/${workflowId}/workflowExecutions/${workflowExId}/details`;
};

export const customHPCJobsWorkflowExecutionLogsPath = (
    workflowId?: string,
    workflowExId?: string,
    taskId?: string
): string => {
    if (!workflowId && !workflowExId && !taskId) {
        return 'customHPCJobs/:workflowId/workflowExecutions/:workflowExId/details/:taskId/logs/';
    } else return `/customHPCJobs/${workflowId}/workflowExecutions/${workflowExId}/details/${taskId}/logs/`;
};
// User Workflows
export const userWorkflowsPath = (isDefaultPath?: boolean) => (!isDefaultPath ? 'userWorkflows' : `/userWorkflows`);

export const userWorkflowsWorkflowCreatePath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'userWorkflows/workflowCreate' : `/userWorkflows/workflowCreate`;

export const userWorkflowsExecutePath = (workflowId?: string) =>
    !workflowId ? 'userWorkflows/:workflowId/execute' : `/userWorkflows/${workflowId}/execute`;

export const userWorkflowsReExecutePath = (workflowId?: string, workflowExId?: string) => {
    if (!workflowId && !workflowExId) {
        return 'userWorkflows/:workflowId/workflowExecutions/:workflowExId/reExecute';
    } else return `/userWorkflows/${workflowId}/workflowExecutions/${workflowExId}/reExecute`;
};

export const userWorkflowsWorkflowExecutionsPath = (workflowId?: string): string => {
    if (!workflowId) {
        return 'userWorkflows/:workflowId/workflowExecutions';
    } else return `/userWorkflows/${workflowId}/workflowExecutions`;
};

export const userWorkflowsWorkflowExecutionDetailsPath = (workflowId?: string, workflowExId?: string): string => {
    if (!workflowId && !workflowExId) {
        return 'userWorkflows/:workflowId/workflowExecutions/:workflowExId/details';
    } else return `/userWorkflows/${workflowId}/workflowExecutions/${workflowExId}/details`;
};

export const userWorkflowsWorkflowExecutionLogsPath = (
    workflowId?: string,
    workflowExId?: string,
    taskId?: string
): string => {
    if (!workflowId && !workflowExId && !taskId) {
        return 'userWorkflows/:workflowId/workflowExecutions/:workflowExId/details/:taskId/logs/';
    } else return `/userWorkflows/${workflowId}/workflowExecutions/${workflowExId}/details/${taskId}/logs/`;
};

// Additional
export const error404Path = (isDefaultPath?: boolean) => (!isDefaultPath ? '404' : '/maintenance/404');
export const error500Path = (isDefaultPath?: boolean) => (!isDefaultPath ? '500' : '/maintenance/500');
export const underConstructionPath = (isDefaultPath?: boolean) =>
    !isDefaultPath ? 'under-construction' : '/maintenance/under-construction';

// Mail Sender
export const resourceRequestMailPath = () => 'resourceRequest/:requestId';
