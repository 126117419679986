import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider as AuthProvider } from 'contexts/JWTContext';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';
import { useEffect } from 'react';

ReactGA.initialize(`${process.env.REACT_APP_MEASUREMENT_ID}`);

const App = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname + location.search,
        });
    }, [location]);

    return (
        <ThemeCustomization>
            <Locales>
                <ScrollTop>
                    <AuthProvider>
                        <Notistack>
                            <ToastContainer />
                            <Routes />
                            <Snackbar />
                        </Notistack>
                    </AuthProvider>
                </ScrollTop>
            </Locales>
        </ThemeCustomization>
    );
};

export default App;
