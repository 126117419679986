import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tab: localStorage.getItem('selectedWfExTab') ? localStorage.getItem('selectedWfExTab') : undefined,
};

const selectedWorkflowsTabProjectSlice = createSlice({
    name: 'selectedWfExTab',
    initialState,
    reducers: {
        setSelectedSelectedWfExTab: (state, action) => {
            state.tab = action.payload;
            localStorage.setItem('selectedWfExTab', action.payload);
        },

        resetSelectedWfExTab: state => {
            state.tab = undefined;
            localStorage.removeItem('selectedWfExTab');
        },
    },
});

export const { setSelectedSelectedWfExTab, resetSelectedWfExTab } = selectedWorkflowsTabProjectSlice.actions;
export default selectedWorkflowsTabProjectSlice.reducer;
