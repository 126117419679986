import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { profilePath } from 'routes/paths';
import useConfig from 'hooks/useConfig';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PersonIcon from '@mui/icons-material/Person';

interface Props {
    handleLogout: () => void;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileTab = ({ handleLogout, setOpen }: Props) => {
    const { onChangeShowGuide } = useConfig();
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    return (
        <List component='nav' sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
            <ListItemButton
                selected={pathname === '/profile'}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    navigate(profilePath(true));
                }}>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText primary='View Profile' />
            </ListItemButton>
            <ListItemButton
                onClick={() => {
                    onChangeShowGuide(true), setOpen(false);
                }}>
                <ListItemIcon>
                    <HelpOutlineIcon />
                </ListItemIcon>
                <ListItemText primary='Show Guide' />
            </ListItemButton>
            <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary='Logout' />
            </ListItemButton>
        </List>
    );
};

export default ProfileTab;
