import additional from './additional';
import administrationManagement from './administrationManagement';
import menu from './menu';
import { NavItemType } from 'types/menu';

const menuItems: { items: NavItemType[] } = {
    items: [menu, administrationManagement, additional],
};

export default menuItems;
