import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const { protocol, host, port } = window.location;

export const iRODSApi = createApi({
    reducerPath: 'iRODSReducer',
    baseQuery: fetchBaseQuery({
        baseUrl: `${protocol}//${host}${port.length > 0 ? '' : `:${port}`}/api/irodsapi`,
    }),
    endpoints: () => ({}),
});
