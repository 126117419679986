import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    projectShortName: localStorage.getItem('selectedDatasetsTabProject')
        ? localStorage.getItem('selectedDatasetsTabProject')
        : undefined,
};

const selectedDatasetsTabProjectSlice = createSlice({
    name: 'selectedDatasetsTabProject',
    initialState,
    reducers: {
        setSelectedDatasetsTabProject: (state, action) => {
            state.projectShortName = action.payload;
            localStorage.setItem('selectedDatasetsTabProject', action.payload);
        },

        resetSelectedDatasetsTabProject: state => {
            state.projectShortName = undefined;
            localStorage.removeItem('selectedDatasetsTabProject');
        },
    },
});

export const { setSelectedDatasetsTabProject, resetSelectedDatasetsTabProject } =
    selectedDatasetsTabProjectSlice.actions;
export default selectedDatasetsTabProjectSlice.reducer;
