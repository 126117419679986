import { transferApi as api } from './transferSlice';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        addPet: build.mutation<AddPetApiResponse, AddPetApiArg>({
            query: queryArg => ({ url: `/transfer/download`, method: 'POST', body: queryArg.downloadRequest }),
        }),
        getdata: build.query<GetdataApiResponse, GetdataApiArg>({
            query: queryArg => ({ url: `/transfer/download/${queryArg.requestId}` }),
        }),
        postTransferUpload: build.mutation<PostTransferUploadApiResponse, PostTransferUploadApiArg>({
            query: () => ({ url: `/transfer/upload`, method: 'POST' }),
        }),
        patchTransferUploadByChunkId: build.mutation<
            PatchTransferUploadByChunkIdApiResponse,
            PatchTransferUploadByChunkIdApiArg
        >({
            query: queryArg => ({ url: `/transfer/upload/${queryArg.chunkId}`, method: 'PATCH' }),
        }),
        getTransferStatus: build.query<GetTransferStatusApiResponse, GetTransferStatusApiArg>({
            query: () => ({ url: `/transfer/status` }),
        }),
        getTransferStatusByRequestId: build.query<
            GetTransferStatusByRequestIdApiResponse,
            GetTransferStatusByRequestIdApiArg
        >({
            query: queryArg => ({ url: `/transfer/status/${queryArg.requestId}` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as transfer };
export type AddPetApiResponse = unknown;
export type AddPetApiArg = {
    /** Download request parameters */
    downloadRequest: DownloadRequest;
};
export type GetdataApiResponse = unknown;
export type GetdataApiArg = {
    /** request id */
    requestId: string;
};
export type PostTransferUploadApiResponse = unknown;
export type PostTransferUploadApiArg = void;
export type PatchTransferUploadByChunkIdApiResponse = unknown;
export type PatchTransferUploadByChunkIdApiArg = {
    /** chunk id */
    chunkId: string;
};
export type GetTransferStatusApiResponse = unknown;
export type GetTransferStatusApiArg = void;
export type GetTransferStatusByRequestIdApiResponse = unknown;
export type GetTransferStatusByRequestIdApiArg = {
    /** request id */
    requestId: string;
};
export type DownloadRequest = {
    access: 'project' | 'public' | 'user';
    zone: string;
    project: string;
    dataset_id: string;
    path: string;
};
export const {
    useAddPetMutation,
    useGetdataQuery,
    usePostTransferUploadMutation,
    usePatchTransferUploadByChunkIdMutation,
    useGetTransferStatusQuery,
    useGetTransferStatusByRequestIdQuery,
} = injectedRtkApi;
