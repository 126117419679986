import { Link } from 'react-router-dom';
import { To } from 'history';
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';
import { APP_DEFAULT_PATH } from 'config';
import FooterLogoIcon from './FooterLogoIcon';
import FooterLogoMain from './FooterLogoMain';

interface Props {
    reverse?: boolean;
    isIcon?: boolean;
    sx?: SxProps;
    to?: To;
    disabled?: boolean;
}

const FooterLogoSection = ({ reverse, isIcon, sx, to, disabled }: Props) => (
    <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to} disabled={disabled} sx={sx}>
        {isIcon ? <FooterLogoIcon /> : <FooterLogoMain reverse={reverse} />}
    </ButtonBase>
);

export default FooterLogoSection;
