import { Box } from '@mui/material';
import { MainCardProps } from 'components/MainCard';

const IntroCard = ({ children, ...other }: MainCardProps) => (
    <Box
        sx={{
            margin: { xs: 2.5, md: 3 },
            '& > *': {
                flexGrow: 1,
                flexBasis: '50%',
            },
        }}>
        <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>{children}</Box>
    </Box>
);

export default IntroCard;
