import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface initialStateType {
    currentKey: string;
    currentValue: string;
    newValue: string;
    specificationsList: { Key: string; Value: string }[] | [];
    showAddSpecifications: boolean;
    showEditSpecifications: boolean;
}

const initialState: initialStateType = {
    currentKey: '',
    currentValue: '',
    newValue: '',
    specificationsList: [],
    showAddSpecifications: false,
    showEditSpecifications: false,
};

const specificationsSlice = createSlice({
    name: 'tagColors',
    initialState,
    reducers: {
        addSpecification: state => {
            state.specificationsList = [
                ...state.specificationsList,
                { Key: state.currentKey, Value: state.currentValue },
            ];
            state.currentKey = initialState.currentKey;
            state.currentValue = initialState.currentValue;
        },
        deleteSpecification: (state, action: PayloadAction<{ currentValue: string; currentKey: string }>) => {
            const { currentKey, currentValue } = action.payload;

            state.specificationsList?.splice(
                state.specificationsList?.findIndex(
                    current => state.currentKey === currentKey && state.currentValue === currentValue
                ) as number,
                1
            );
            state.currentKey = initialState.currentKey;
            state.currentValue = initialState.currentValue;
        },
        resetCurrentSpecKeyVal: state => {
            state.currentKey = initialState.currentKey;
            state.currentValue = initialState.currentValue;
        },
        resetSpec: state => {
            state.currentKey = initialState.currentKey;
            state.currentValue = initialState.currentValue;
            state.specificationsList = initialState.specificationsList;
            state.showAddSpecifications = initialState.showAddSpecifications;
            state.showAddSpecifications = initialState.showEditSpecifications;
        },
        setShowAddSpecifications: (
            state,
            action: PayloadAction<{ currentValue?: string; currentKey?: string; show: boolean }>
        ) => {
            const { currentKey, currentValue, show } = action.payload;
            state.currentKey = currentKey ?? '';
            state.currentValue = currentValue ?? '';
            state.showAddSpecifications = show;
            state.showEditSpecifications = false;
            state.newValue = '';
        },
        setShowEditSpecifications: (
            state,
            action: PayloadAction<{ currentValue?: string; currentKey?: string; show: boolean }>
        ) => {
            const { currentKey, currentValue, show } = action.payload;
            state.newValue = '';
            state.currentKey = currentKey ?? '';
            state.currentValue = currentValue ?? '';
            state.showAddSpecifications = false;
            state.showEditSpecifications = show;
        },
        setSpecCurrentKey: (state, action) => {
            state.currentKey = action.payload;
        },
        setSpecNewValue: (state, action) => {
            state.newValue = action.payload;
        },
        resetSpecNewValue: state => {
            state.newValue = '';
        },

        editSpecValue: (
            state,
            action: PayloadAction<{ currentValue: string; currentKey: string; newValue: string }>
        ) => {
            const { currentKey, currentValue, newValue } = action.payload;
            const index = state.specificationsList.findIndex(
                spec => spec.Key === currentKey && spec.Value === currentValue
            );
            index !== -1 ? (state.specificationsList[index].Value = newValue) : null;
            state.showEditSpecifications = false;
            state.newValue = '';
            state.currentKey = initialState.currentKey;
        },
        setSpecCurrentValue: (state, action) => {
            state.currentValue = action.payload;
        },
        setSpecificationsList: (state, action) => {
            state.specificationsList = action.payload;
        },
    },
});

export const {
    addSpecification,
    deleteSpecification,
    resetCurrentSpecKeyVal,
    resetSpec,
    setShowAddSpecifications,
    setSpecCurrentKey,
    setShowEditSpecifications,
    editSpecValue,
    resetSpecNewValue,
    setSpecNewValue,
    setSpecCurrentValue,
    setSpecificationsList,
} = specificationsSlice.actions;
export default specificationsSlice.reducer;
