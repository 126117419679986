import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import {
    workflowsDashboardPath,
    workflowExecutionLogsPath,
    profilePath,
    projectDetailsPath,
    projectsPath,
    locationsPath,
    resourcesPath,
    usersPath,
    workflowDetailsPath,
    workflowExecutionDetailsPath,
    workflowExecutionsPath,
    workflowsPath,
    resourceAssignmentsPath,
    error404Path,
    error500Path,
    underConstructionPath,
    resourceManagementPath,
    containersPath,
    workflowDataSetsPath,
    publicDataSetsPath,
    uploadDataSetsPath,
    HPCApplicationsPath,
    workflowTemplatesPath,
    containerCreatePath,
    HPCApplicationsCreatePath,
    containerWorkflowCreatePath,
    HPCApplicationsWorkflowCreatePath,
    datasetsDashboardPath,
    workflowDataSetDetailsPath,
    uploadDataSetDetailsPath,
    publicDataSetDetailsPath,
    projectDetailsManageTagsPath,
    uploadDataSetPath,
    uploadFileListPath,
    workflowsFileListPath,
    publicFileListPath,
    providersPath,
    usersManagementPath,
    resourceRequestMailPath,
    downloadTransferStatusesDashboardPath,
    uploadTransferStatusesDashboardPath,
    projectAccessRequestsPath,
    uploadFileEditPath,
    workflowsFileEditPath,
    publicFileEditPath,
    generalDashboardPath,
    customHPCJobsPath,
    customHPCJobsExecutePath,
    customHPCJobsReExecutePath,
    createJobscriptPath,
    createJobscriptWorkflowPath,
    customHPCJobsWorkflowExecutionsPath,
    customHPCJobsWorkflowExecutionDetailsPath,
    userWorkflowsPath,
    userWorkflowsWorkflowCreatePath,
    userWorkflowsExecutePath,
    userWorkflowsReExecutePath,
    userWorkflowsWorkflowExecutionsPath,
    userWorkflowsWorkflowExecutionDetailsPath,
    userWorkflowsWorkflowExecutionLogsPath,
    customHPCJobsWorkflowExecutionLogsPath,
} from './paths';
import ComingSoon from 'pages/maintenance/coming-soon';

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));
const WorkflowsDashboard = Loadable(lazy(() => import('pages/dashboard/workflows')));
const Workflows = Loadable(lazy(() => import('pages/workflows')));
const WorkflowDetails = Loadable(lazy(() => import('pages/workflows/workflowsDetails')));
const WorkflowExecutions = Loadable(lazy(() => import('pages/workflows/workflowExecutions')));
const WorkflowExecutionDetails = Loadable(
    lazy(() => import('pages/workflows/workflowExecutions/workflowExecutionDetails'))
);
const Logs = Loadable(lazy(() => import('pages/workflows/workflowExecutions/log')));
const WorkflowDatasets = Loadable(lazy(() => import('pages/datasets/workflowDatasets')));
const PublicDatasets = Loadable(lazy(() => import('pages/datasets/publicDatasets')));
const UploadDatasets = Loadable(lazy(() => import('pages/datasets/uploadDatasets')));
const CreateDataset = Loadable(lazy(() => import('pages/datasets/uploadDatasets/createDataset')));
const DataSetDetails = Loadable(lazy(() => import('pages/datasets/dataSetDetails')));
const FileList = Loadable(lazy(() => import('pages/datasets/fileList')));
const EditFile = Loadable(lazy(() => import('pages/datasets/fileList/file/editFile')));
const DownloadTransferStatuses = Loadable(lazy(() => import('pages/dashboard/transferStatuses/downloads')));
const UploadTransferStatuses = Loadable(lazy(() => import('pages/dashboard/transferStatuses/uploads')));
const Projects = Loadable(lazy(() => import('pages/projects')));
const ProjectDetails = Loadable(lazy(() => import('pages/projects/projectsDetails')));
const ProjectAccessRequests = Loadable(lazy(() => import('pages/projects/projectsDetails/accessRequests')));
const TagsManage = Loadable(lazy(() => import('pages/projects/tagsManage')));
const Users = Loadable(lazy(() => import('pages/users')));
const Resources = Loadable(lazy(() => import('pages/projects/resources/resources')));
const UsersManagement = Loadable(lazy(() => import('pages/usersManagement')));
const Profile = Loadable(lazy(() => import('pages/profile')));
const Locations = Loadable(lazy(() => import('pages/locations')));
const Providers = Loadable(lazy(() => import('pages/providers')));
const ResourceAssignments = Loadable(lazy(() => import('pages/resourceManagement/resourceAssignments')));
const ResourceManagement = Loadable(lazy(() => import('pages/resourceManagement')));
const Containers = Loadable(lazy(() => import('pages/applications/containers')));
const ContainerCreate = Loadable(lazy(() => import('pages/applications/containers/containerCreate')));
const ContainerWorkflowCreate = Loadable(lazy(() => import('pages/applications/containers/containerWorkflowCreate')));
const HPCApplication = Loadable(lazy(() => import('pages/applications/HPCApplication')));
const HPCApplicationWorkflowCreate = Loadable(
    lazy(() => import('pages/applications/HPCApplication/HPCApplicationWorkflowCreate'))
);
const DirectAccess = Loadable(lazy(() => import('pages/applications/customHPCJobs')));
const DirectAccessWorkflowExecute = Loadable(lazy(() => import('pages/applications/customHPCJobs/workflows/execute')));
const DirectAccessWorkflowReExecute = Loadable(
    lazy(() => import('pages/applications/customHPCJobs/workflows/workflowExecutions/reExecute'))
);

const JobscriptCreate = Loadable(lazy(() => import('pages/applications/customHPCJobs/jobscriptCreate')));
const JobscriptWorkflowCreate = Loadable(
    lazy(() => import('pages/applications/customHPCJobs/jobscriptWorkflowCreate'))
);
const CustomHPCJobWorkflowExecutions = Loadable(
    lazy(() => import('pages/applications/customHPCJobs/workflows/workflowExecutions'))
);
const CustomHPCJobsWorkflowExecutionDetails = Loadable(
    lazy(
        () =>
            import(
                'pages/applications/customHPCJobs/workflows/workflowExecutions/workflowExecutionDetails/workflowExecutionStatus'
            )
    )
);

const UserWorkflows = Loadable(lazy(() => import('pages/applications/userWorkflows')));
const UserWorkflowsWorkflowCreate = Loadable(
    lazy(() => import('pages/applications/userWorkflows/workflows/userWorkflowsWorkflowCreate'))
);

const UserWorkflowsWorkflowExecute = Loadable(lazy(() => import('pages/applications/userWorkflows/workflows/execute')));

const UserWorkflowsWorkflowReExecute = Loadable(
    lazy(() => import('pages/applications/userWorkflows/workflows/workflowExecutions/reExecute'))
);

const UserWorkflowWorkflowExecutions = Loadable(
    lazy(() => import('pages/applications/userWorkflows/workflows/workflowExecutions'))
);

const GeneralDashboard = Loadable(lazy(() => import('pages/dashboard/general')));

const WorkflowTemplates = Loadable(lazy(() => import('pages/applications/workflowTemplates')));
const ResourceRequestMailPath = Loadable(lazy(() => import('pages/emailRequest/resourceRequest')));

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <AuthGuard>
                    <MainLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: resourceRequestMailPath(),
                    element: <ResourceRequestMailPath />,
                },
                {
                    path: workflowsDashboardPath(),
                    element: <WorkflowsDashboard />,
                },
                {
                    path: datasetsDashboardPath(),
                    element: <ComingSoon />,
                },
                {
                    path: generalDashboardPath(),
                    element: <GeneralDashboard />,
                },
                {
                    path: workflowsPath(),
                    element: <Workflows />,
                },
                {
                    path: workflowDetailsPath(),
                    element: <WorkflowDetails />,
                },
                {
                    path: workflowExecutionsPath(),
                    element: <WorkflowExecutions />,
                },
                {
                    path: workflowExecutionDetailsPath(),
                    element: <WorkflowExecutionDetails />,
                },
                {
                    path: workflowExecutionLogsPath(),
                    element: <Logs />,
                },

                {
                    path: uploadDataSetsPath(),
                    element: <UploadDatasets />,
                },
                {
                    path: uploadDataSetPath(),
                    element: <CreateDataset />,
                },
                {
                    path: uploadDataSetDetailsPath(),
                    element: <DataSetDetails />,
                },
                {
                    path: workflowDataSetsPath(),
                    element: <WorkflowDatasets />,
                },
                {
                    path: publicDataSetsPath(),
                    element: <PublicDatasets />,
                },
                {
                    path: publicDataSetDetailsPath(),
                    element: <DataSetDetails />,
                },
                {
                    path: workflowDataSetDetailsPath(),
                    element: <DataSetDetails />,
                },

                {
                    path: uploadFileListPath(),
                    element: <FileList />,
                },
                {
                    path: workflowsFileListPath(),
                    element: <FileList />,
                },
                {
                    path: publicFileListPath(),
                    element: <FileList />,
                },
                {
                    path: uploadFileEditPath(),
                    element: <EditFile />,
                },
                {
                    path: workflowsFileEditPath(),
                    element: <EditFile />,
                },
                {
                    path: publicFileEditPath(),
                    element: <EditFile />,
                },
                {
                    path: downloadTransferStatusesDashboardPath(),
                    element: <DownloadTransferStatuses />,
                },
                {
                    path: uploadTransferStatusesDashboardPath(),
                    element: <UploadTransferStatuses />,
                },
                {
                    path: projectsPath(),
                    element: <Projects />,
                },
                {
                    path: projectDetailsPath(),
                    element: <ProjectDetails />,
                },
                {
                    path: projectAccessRequestsPath(),
                    element: <ProjectAccessRequests />,
                },
                {
                    path: projectDetailsManageTagsPath(),
                    element: <TagsManage />,
                },
                {
                    path: usersPath(),
                    element: <Users />,
                },
                {
                    path: profilePath(),
                    element: <Profile />,
                },
                {
                    path: resourcesPath(),
                    element: <Resources />,
                },
                {
                    path: providersPath(),
                    element: <Providers />,
                },

                {
                    path: locationsPath(),
                    element: <Locations />,
                },
                {
                    path: usersManagementPath(),
                    element: <UsersManagement />,
                },
                {
                    path: resourceAssignmentsPath(),
                    element: <ResourceAssignments />,
                },
                {
                    path: resourceManagementPath(),
                    element: <ResourceManagement />,
                },
                {
                    path: containersPath(),
                    element: <Containers />,
                },
                {
                    path: containerCreatePath(),
                    element: <ContainerCreate />,
                },
                {
                    path: containerWorkflowCreatePath(),
                    element: <ContainerWorkflowCreate />,
                },
                {
                    path: HPCApplicationsPath(),
                    element: <HPCApplication />,
                },
                {
                    path: HPCApplicationsCreatePath(),
                    element: <ComingSoon />,
                },
                {
                    path: HPCApplicationsWorkflowCreatePath(),
                    element: <HPCApplicationWorkflowCreate />,
                },
                {
                    path: customHPCJobsPath(),
                    element: <DirectAccess />,
                },
                {
                    path: customHPCJobsExecutePath(),
                    element: <DirectAccessWorkflowExecute />,
                },
                {
                    path: customHPCJobsReExecutePath(),
                    element: <DirectAccessWorkflowReExecute />,
                },
                {
                    path: createJobscriptPath(),
                    element: <JobscriptCreate />,
                },
                {
                    path: createJobscriptWorkflowPath(),
                    element: <JobscriptWorkflowCreate />,
                },
                {
                    path: customHPCJobsWorkflowExecutionsPath(),
                    element: <CustomHPCJobWorkflowExecutions />,
                },
                {
                    path: customHPCJobsWorkflowExecutionDetailsPath(),
                    element: <CustomHPCJobsWorkflowExecutionDetails />,
                },
                {
                    path: customHPCJobsWorkflowExecutionLogsPath(),
                    element: <Logs />,
                },
                {
                    path: workflowTemplatesPath(),
                    element: <WorkflowTemplates />,
                },
                {
                    path: userWorkflowsPath(),
                    element: <UserWorkflows />,
                },
                {
                    path: userWorkflowsWorkflowCreatePath(),
                    element: <UserWorkflowsWorkflowCreate />,
                },
                {
                    path: userWorkflowsExecutePath(),
                    element: <UserWorkflowsWorkflowExecute />,
                },
                {
                    path: userWorkflowsReExecutePath(),
                    element: <UserWorkflowsWorkflowReExecute />,
                },
                {
                    path: userWorkflowsWorkflowExecutionsPath(),
                    element: <UserWorkflowWorkflowExecutions />,
                },
                {
                    path: userWorkflowsWorkflowExecutionDetailsPath(),
                    element: <WorkflowExecutionDetails />,
                },
                {
                    path: userWorkflowsWorkflowExecutionLogsPath(),
                    element: <Logs />,
                },
            ],
        },
        {
            path: '/maintenance',
            element: <CommonLayout />,
            children: [
                {
                    path: error404Path(),
                    element: <MaintenanceError />,
                },
                {
                    path: error500Path(),
                    element: <MaintenanceError500 />,
                },
                {
                    path: underConstructionPath(),
                    element: <MaintenanceUnderConstruction />,
                },
                {
                    path: 'coming-soon',
                    element: <MaintenanceComingSoon />,
                },
            ],
        },
    ],
};

export default MainRoutes;
