import { CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import useConfig from 'hooks/useConfig';
import { useGetApiUserPreferencesQuery, usePutApiUserPreferencesMutation } from 'store/userOrg/enhancedUserOrgApi';

const ThemeFontFamily = () => {
    const { fontFamily } = useConfig();
    const { data: preferences, isLoading: preferencesIsLoading } = useGetApiUserPreferencesQuery();
    const [putPreferences, { isLoading: putPreferencesIsLoading }] = usePutApiUserPreferencesMutation();
    const preferencesObj = preferences ? JSON.parse(preferences) : {};

    const handleRadioChange = event => {
        putPreferences({
            body: {
                ...preferencesObj,
                fontFamily: event.target.value,
            },
        });
    };

    return (
        <FormControl>
            <FormLabel>Font Family</FormLabel>

            <RadioGroup value={fontFamily} onChange={handleRadioChange}>
                <FormControlLabel value={`'Inter', sans-serif`} control={<Radio />} label='Inter' />
                <FormControlLabel value={`'Poppins', sans-serif`} control={<Radio />} label='Poppins' />
                <FormControlLabel value={`'Roboto', sans-serif`} control={<Radio />} label='Roboto' />
                <FormControlLabel value={`'Public Sans', sans-serif`} control={<Radio />} label='Public Sans' />
            </RadioGroup>
            {preferencesIsLoading || (putPreferencesIsLoading && <CircularProgress />)}
        </FormControl>
    );
};

export default ThemeFontFamily;
