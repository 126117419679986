export const permissions = {
    usrRequest: 'usr_request',
    usrRequestManage: 'usr_request_manage',
    prjCreate: 'prj_create',
    prjListUsers: 'prj_list_users',
    prjUpdateUserPerm: 'prj_update_user_perm',
    prjAddUser: 'prj_add_user',
    prjRemove: 'prj_remove',
    wfList: 'wf_list',
    wfCreate: 'wf_create',
    wfUpdate: 'wf_update',
    wfRemove: 'wf_remove',
    wfexeList: 'wfexe_list',
    wfexeCreate: 'wfexe_create',
    wfexeRead: 'wfexe_read',
    dataCreate: 'data_create',
    dataList: 'data_list',
    dataUpdate: 'data_update',
    dataDownload: 'data_download',
    dataUpload: 'data_upload',
    dataStage: 'data_stage',
    dataRemove: 'data_remove',
    resAsign: 'res_asign',
    resList: 'res_list',
    resRemove: 'res_remove',
    resCreate: 'res_create',
    resUpdate: 'res_update',
    resAssignmentResAssignment: 'res_assignment_res_assignment',
    prjtagCreate: 'prjtag_create',
    prjtagList: 'prjtag_list',
    prjtagUpdate: 'prjtag_update',
    prjtagRemove: 'prjtag_remove',
    prjUserAssignment: 'prj_user_assignment',
    prjPrjtagAssignment: 'prj_prjtag_assignment',
    prjResAssignment: 'prj_res_assignment',
    prjList: 'prj_list',
    prjUpdate: 'prj_update',
    locCreate: 'loc_create',
    locList: 'loc_list',
    locUpdate: 'loc_update',
    locRemove: 'loc_remove',
    providerCreate: 'provider_create',
    providerList: 'provider_list',
    providerUpdate: 'provider_update',
    providerRemove: 'provider_remove',
    wfexeDetail: 'wfexe_detail',
    assignedResList: 'assigned_res_list',
    assignedResCreate: 'assigned_res_create',
    assignedResUpdate: 'assigned_res_update',
    assignedResRemove: 'assigned_res_remove',
    roleCreate: 'role_create',
    roleUpdate: 'role_update',
    roleRemove: 'role_remove',
    roleList: 'role_list',
    platformadminList: 'platformadmin_list',
    platformadmin: 'platformadmin',
    serviceAccess: 'service_access',
};
