import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import {
    resetSelectedHPCApplicationTabProject,
    setSelectedHPCApplicationTabProject,
} from 'store/additional/applications/HPCApplications/selectedHPCApplicationsTabProject';
import {
    resetSelectedContainersTabProject,
    setSelectedContainersTabProject,
} from 'store/additional/applications/containers/selectedContainersTabProject';
import {
    resetSelectedDashboardTabProject,
    setSelectedDashboardTabProject,
} from 'store/additional/dashboard/selectedDashboardTabProject';
import {
    resetSelectedDatasetsTabProject,
    setSelectedDatasetsTabProject,
} from 'store/additional/datasets/selectedDatasetsTabProject';
import {
    resetSelectedTransferStatusesTabProject,
    setSelectedTransferStatusesTabProject,
} from 'store/additional/transferStatuses/selectedTransferStatusesTabProject';
import {
    resetSelectedWorkflowsTabProject,
    setSelectedWorkflowsTabProject,
} from 'store/additional/workflows/selectedWorkflowsTabProject';
import { GetApiAdministrationProjectApiResponse, GetProjectApiResponse } from 'store/userOrg/userOrgApi';

export const removeElementFromArray = (array, valueToRemove) => {
    return array.filter(element => element !== valueToRemove);
};

export const findProjectShortName = (
    projects: GetApiAdministrationProjectApiResponse | undefined,
    projectShortName: string
) => projects?.find(project => project.ShortName === projectShortName)?.ShortName;

interface setGlobalFilterValueProps {
    projectShortName: string;
    selectedGlobalFilterValues?: any;
    putPreferences?: any;
    preferencesObj?: any;
    projects?: GetApiAdministrationProjectApiResponse | undefined;
    selectedContainersTabProject?: string | null | undefined;
    selectedWorkflowsTabProject?: string | null | undefined;
    selectedDatasetsTabProject?: string | null | undefined;
    selectedDashboardTabProject?: string | null | undefined;
    selectedTransferStatusesTabProject?: string | null | undefined;
    selectedHPCApplicationTabProject?: string | null | undefined;
    firstProjectShortName?: string | null | undefined;
    dispatch: Dispatch<AnyAction>;
}
export const setGlobalFilterValue = ({
    projectShortName,
    selectedGlobalFilterValues,
    putPreferences,
    preferencesObj,
    projects,
    selectedWorkflowsTabProject,
    selectedDatasetsTabProject,
    selectedDashboardTabProject,
    selectedTransferStatusesTabProject,
    selectedContainersTabProject,
    selectedHPCApplicationTabProject,
    firstProjectShortName,
    dispatch,
}: setGlobalFilterValueProps) => {
    const selectedGlobalFilterShortNamesCopy = selectedGlobalFilterValues ? [...selectedGlobalFilterValues] : [];

    // If projectId is not included and not 'null' or 'all', add it to globalFilterValues
    !selectedGlobalFilterShortNamesCopy.includes(projectShortName) &&
        projectShortName !== 'null' &&
        projectShortName !== 'all' &&
        putPreferences({
            body: { ...preferencesObj, globalFilterValues: [...selectedGlobalFilterShortNamesCopy, projectShortName] },
        });

    // If multiple elements and current projectId is included, remove it from globalFilterValues
    selectedGlobalFilterShortNamesCopy.length > 1 &&
        selectedGlobalFilterShortNamesCopy.includes(projectShortName) &&
        putPreferences({
            body: {
                ...preferencesObj,
                globalFilterValues: removeElementFromArray(selectedGlobalFilterValues, projectShortName),
            },
        })
            .unwrap()
            .then(() => {
                findProjectShortName(projects, projectShortName) === selectedDashboardTabProject &&
                    dispatch(resetSelectedDashboardTabProject());
                findProjectShortName(projects, projectShortName) === selectedDatasetsTabProject &&
                    dispatch(resetSelectedDatasetsTabProject());
                findProjectShortName(projects, projectShortName) === selectedContainersTabProject &&
                    dispatch(resetSelectedContainersTabProject());
                findProjectShortName(projects, projectShortName) === selectedWorkflowsTabProject &&
                    dispatch(resetSelectedWorkflowsTabProject());
                findProjectShortName(projects, projectShortName) === selectedTransferStatusesTabProject &&
                    dispatch(resetSelectedTransferStatusesTabProject());
                findProjectShortName(projects, projectShortName) === selectedHPCApplicationTabProject &&
                    dispatch(resetSelectedHPCApplicationTabProject());
            });

    // If projectId is 'all', set globalFilterValues to all project IDs
    projectShortName === 'all' &&
        putPreferences({
            body: {
                ...preferencesObj,
                globalFilterValues: projects?.map(project => project?.ShortName) || [],
            },
        });

    // If projectId is 'null', set globalFilterValues based on conditions
    projectShortName === 'null' &&
        (projects && firstProjectShortName
            ? putPreferences({
                  body: {
                      ...preferencesObj,
                      globalFilterValues: [firstProjectShortName],
                  },
              })
                  .unwrap()
                  .then(() => {
                      dispatch(setSelectedDashboardTabProject(findProjectShortName(projects, firstProjectShortName)));
                      dispatch(setSelectedWorkflowsTabProject(findProjectShortName(projects, firstProjectShortName)));
                      dispatch(setSelectedContainersTabProject(findProjectShortName(projects, firstProjectShortName)));
                      dispatch(
                          setSelectedHPCApplicationTabProject(findProjectShortName(projects, firstProjectShortName))
                      );
                      dispatch(setSelectedDatasetsTabProject(findProjectShortName(projects, firstProjectShortName)));

                      selectedTransferStatusesTabProject !== 'All' &&
                          dispatch(
                              setSelectedTransferStatusesTabProject(
                                  findProjectShortName(projects, firstProjectShortName)
                              )
                          );
                  })
            : putPreferences({
                  body: {
                      ...preferencesObj,
                      globalFilterValues: [],
                  },
              })
                  .unwrap()
                  .then(() => {
                      dispatch(resetSelectedDashboardTabProject());
                      dispatch(resetSelectedWorkflowsTabProject());
                      dispatch(resetSelectedContainersTabProject());
                      dispatch(resetSelectedHPCApplicationTabProject());
                      dispatch(resetSelectedDatasetsTabProject());

                      selectedTransferStatusesTabProject !== 'All' &&
                          dispatch(resetSelectedTransferStatusesTabProject());
                  }));
};

export const getValuesNotPresent = (
    selectedGlobalFilterValues: string[],
    projects: GetProjectApiResponse | undefined
) => selectedGlobalFilterValues?.filter(shortName => !projects?.some(project => project?.ShortName === shortName));
