import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    projectShortName: localStorage.getItem('selectedHPCApplicationTabProject')
        ? localStorage.getItem('selectedHPCApplicationTabProject')
        : undefined,
};

const selectedHPCApplicationTabProjectSlice = createSlice({
    name: 'selectedHPCApplicationTabProject',
    initialState,
    reducers: {
        setSelectedHPCApplicationTabProject: (state, action) => {
            state.projectShortName = action.payload;
            localStorage.setItem('selectedHPCApplicationTabProject', action.payload);
        },

        resetSelectedHPCApplicationTabProject: state => {
            state.projectShortName = undefined;
            localStorage.removeItem('selectedHPCApplicationTabProject');
        },
    },
});

export const { setSelectedHPCApplicationTabProject, resetSelectedHPCApplicationTabProject } =
    selectedHPCApplicationTabProjectSlice.actions;
export default selectedHPCApplicationTabProjectSlice.reducer;
