import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const { protocol, host, port } = window.location;

export const airflowApi = createApi({
    reducerPath: 'airflowReducer',
    baseQuery: fetchBaseQuery({
        baseUrl: `${protocol}//${host}${port.length > 0 ? '' : `:${port}`}/api/airflow/api/v1`,
    }),
    endpoints: () => ({}),
});

export default airflowApi;
