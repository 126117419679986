import { useCallback } from 'react';
import { useGetApiUserInfoQuery, useGetProjectQuery } from '../store/userOrg/enhancedUserOrgApi';

export default function useCheckPermission() {
    const { data: user } = useGetApiUserInfoQuery();
    const { data: projects } = useGetProjectQuery({});
    const loggedUserRole = user?.SystemRoles?.find(systemRole => systemRole.ProjectShortName === '')?.RoleName;

    return useCallback(
        (permission: string, project: string | null | undefined) => {
            if (loggedUserRole !== 'Platform Admin') {
                if (project === '' && user) {
                    return user?.SystemRoles?.[0]?.SystemPermissionTypes?.includes(permission);
                } else if (user && projects) {
                    const projectData = projects.find(
                        p => p.Name === project || p.ShortName === project || p.Id === project
                    );
                    const permissions = user.SystemRoles?.find(
                        item => item.ProjectShortName === projectData?.ShortName
                    )?.SystemPermissionTypes;

                    return permissions?.includes(permission);
                } else {
                    return false;
                }
            } else if (loggedUserRole === 'Platform Admin') {
                return true;
            } else return false;
        },
        [user, projects]
    );
}
