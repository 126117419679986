import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    ClickAwayListener,
    Divider,
    List,
    Paper,
    Popper,
    Typography,
    useMediaQuery,
    Tooltip,
    CircularProgress,
    MenuItem,
    ListItemIcon,
    Button,
    ButtonGroup,
    Stack,
    Chip,
} from '@mui/material';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import { ThemeMode } from 'types/config';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { GetApiAdministrationProjectApiResponse } from 'store/userOrg/userOrgApi';
import { RootState } from 'store';
import { useGetApiUserPreferencesQuery, usePutApiUserPreferencesMutation } from 'store/userOrg/enhancedUserOrgApi';
import { getValuesNotPresent, setGlobalFilterValue } from './helpers';
import CheckIcon from '@mui/icons-material/Check';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Grid } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem',
};

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',

    transform: 'none',
};

interface GlobalFilterProps {
    projects: GetApiAdministrationProjectApiResponse | undefined;
}

const GlobalFilter = ({ projects }: GlobalFilterProps) => {
    const { data: preferences, isLoading: preferencesIsLoading } = useGetApiUserPreferencesQuery();
    const [putPreferences, { isLoading: putPreferencesIsLoading }] = usePutApiUserPreferencesMutation();
    const preferencesObj = preferences ? JSON.parse(preferences) : {};

    const selectedDashboardTabProject = useSelector(
        (state: RootState) => state.selectedDashboardTabProject.projectShortName
    );
    const selectedContainersTabProject = useSelector(
        (state: RootState) => state.selectedContainersTabProject.projectShortName
    );
    const selectedHPCApplicationTabProject = useSelector(
        (state: RootState) => state.selectedHPCApplicationTabProject.projectShortName
    );
    const selectedDatasetsTabProject = useSelector(
        (state: RootState) => state.selectedDatasetsTabProject.projectShortName
    );
    const selectedWorkflowsTabProject = useSelector(
        (state: RootState) => state.selectedWorkflowsTabProject.projectShortName
    );
    const selectedTransferStatusesTabProject = useSelector(
        (state: RootState) => state.selectedTransferStatusesTabProject.projectShortName
    );

    const selectedGlobalFilterValues = preferencesObj?.globalFilterValues;
    const dispatch = useDispatch();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const anchorRef = useRef<any>(null);

    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const selectedGlobalFilterNames = projects
        ?.filter(project => selectedGlobalFilterValues?.includes(project.ShortName as string))
        .map(project => project?.Name);
    const sortedProjects = _.sortBy(projects, [project => project.ShortName]);
    const firstProjectShortName = sortedProjects[0]?.ShortName;
    const setGlobalFilter = (projectShortName: string) => {
        setGlobalFilterValue({
            dispatch,
            preferencesObj,
            firstProjectShortName,
            projectShortName,
            projects,
            putPreferences,
            selectedDashboardTabProject,
            selectedHPCApplicationTabProject,
            selectedDatasetsTabProject,
            selectedContainersTabProject,
            selectedGlobalFilterValues,
            selectedTransferStatusesTabProject,
            selectedWorkflowsTabProject,
        });
    };
    useEffect(() => {
        (!selectedGlobalFilterValues || selectedGlobalFilterValues?.length === 0) &&
            firstProjectShortName !== undefined &&
            firstProjectShortName &&
            setGlobalFilter(firstProjectShortName);

        !projects && setGlobalFilter('null');

        const valuesNotPresent = getValuesNotPresent(selectedGlobalFilterValues, projects);
        const newSelectedGlobalFiterValues = selectedGlobalFilterValues?.filter(val => !valuesNotPresent.includes(val));

        valuesNotPresent?.length &&
            putPreferences({
                body: {
                    ...preferencesObj,
                    globalFilterValues: newSelectedGlobalFiterValues,
                },
            });
    }, [projects, preferences]);

    const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';
    const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }} id='globalFilterNotifications'>
            <Stack direction='row' spacing={1} alignItems='center'>
                <Tooltip title={'Project filter'}>
                    <IconButton
                        color='secondary'
                        variant='light'
                        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, width: 'auto' }}
                        ref={anchorRef}
                        onClick={handleToggle}>
                        <FilterAltIcon
                            color={
                                !selectedGlobalFilterValues || !(selectedGlobalFilterValues as string[])?.length
                                    ? 'error'
                                    : 'inherit'
                            }
                        />
                        {!isMobile && <>Project Filter</>}
                    </IconButton>
                </Tooltip>

                {!isMobile ? (
                    selectedGlobalFilterValues && selectedGlobalFilterValues?.length ? (
                        selectedGlobalFilterValues?.map(
                            (pr, index) =>
                                index < 5 && (
                                    <Tooltip title={pr} key={index}>
                                        <Chip
                                            size='medium'
                                            label={pr.length > 8 ? `${pr.slice(0, 8)}...` : pr}
                                            onDelete={() => pr && setGlobalFilter(pr)}
                                            disabled={selectedGlobalFilterValues?.length < 2}
                                            color='success'
                                        />
                                    </Tooltip>
                                )
                        )
                    ) : !selectedGlobalFilterValues || !(selectedGlobalFilterValues as string[])?.length ? (
                        projects?.length ? (
                            <Chip label='No selected projects' color='error' />
                        ) : (
                            <Chip label='No projects available' color='error' />
                        )
                    ) : (
                        <></>
                    )
                ) : (
                    <></>
                )}
            </Stack>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-start'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? -5 : 0, 9],
                            },
                        },
                    ],
                }}>
                {({ TransitionProps }) => (
                    <Transitions
                        type='grow'
                        position={matchesXs ? 'top' : 'top-right'}
                        sx={{ overflow: 'hidden' }}
                        in={open}
                        {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: '100%',
                                minWidth: 285,
                                maxWidth: 420,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 285,
                                },
                            }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Paper style={{ maxHeight: 300, overflow: 'auto' }}>
                                    <MainCard elevation={0} border={false} content={false}>
                                        {preferencesIsLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            <List
                                                component='nav'
                                                sx={{
                                                    p: 0,
                                                    '& .MuiListItemButton-root': {
                                                        py: 0.5,
                                                        '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                                                        '& .MuiAvatar-root': avatarSX,
                                                        '& .MuiListItemSecondaryAction-root': {
                                                            ...actionSX,
                                                            position: 'relative',
                                                        },
                                                    },
                                                }}>
                                                <Grid container>
                                                    <Grid
                                                        container
                                                        item
                                                        xs={4}
                                                        justifyContent='center'
                                                        alignItems='center'>
                                                        <Typography fontWeight='bold'>Projects</Typography>
                                                    </Grid>

                                                    <Grid item xs={8} textAlign='center' sx={{ mt: 2, mb: 2 }}>
                                                        <ButtonGroup>
                                                            <Button
                                                                variant='text'
                                                                color='success'
                                                                onClick={() => setGlobalFilter('all')}
                                                                disabled={
                                                                    !sortedProjects?.length ||
                                                                    selectedGlobalFilterValues?.length ===
                                                                        projects?.length
                                                                }>
                                                                <ListItemIcon>
                                                                    <TaskAltIcon color='success' />
                                                                </ListItemIcon>
                                                                Select all
                                                            </Button>
                                                            <Button
                                                                variant='text'
                                                                color='warning'
                                                                onClick={() => setGlobalFilter('null')}
                                                                disabled={
                                                                    !sortedProjects?.length ||
                                                                    selectedGlobalFilterValues?.length === 1
                                                                }>
                                                                <ListItemIcon>
                                                                    <UnpublishedIcon color='warning' />
                                                                </ListItemIcon>
                                                                Unselect all
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Grid>
                                                </Grid>
                                                <Divider />

                                                {sortedProjects?.map(project => (
                                                    <MenuItem
                                                        key={project.ShortName}
                                                        disabled={
                                                            putPreferencesIsLoading ||
                                                            (selectedGlobalFilterNames?.length == 1 &&
                                                                selectedGlobalFilterNames?.includes(project.Name))
                                                        }
                                                        onClick={() =>
                                                            project.ShortName && setGlobalFilter(project.ShortName)
                                                        }>
                                                        <ListItemIcon>
                                                            {selectedGlobalFilterNames?.includes(project.Name) && (
                                                                <CheckIcon color='success' />
                                                            )}
                                                        </ListItemIcon>
                                                        {project.ShortName}
                                                    </MenuItem>
                                                ))}
                                                {(!sortedProjects || sortedProjects.length === 0) && (
                                                    <List
                                                        component='nav'
                                                        sx={{
                                                            p: 0,
                                                            '& .MuiListItemButton-root': {
                                                                py: 0.5,
                                                                '&.Mui-selected': {
                                                                    bgcolor: 'grey.50',
                                                                    color: 'text.primary',
                                                                },
                                                                '& .MuiAvatar-root': avatarSX,
                                                                '& .MuiListItemSecondaryAction-root': {
                                                                    ...actionSX,
                                                                    position: 'relative',
                                                                },
                                                            },
                                                        }}>
                                                        <Typography variant='h6' margin={2}>
                                                            No Projects
                                                        </Typography>
                                                    </List>
                                                )}
                                            </List>
                                        )}
                                    </MainCard>
                                </Paper>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default GlobalFilter;
