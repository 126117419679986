import { userOrgApi as api } from './userOrgSlice';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        getApiAdministrationProject: build.query<
            GetApiAdministrationProjectApiResponse,
            GetApiAdministrationProjectApiArg
        >({
            query: queryArg => ({
                url: `/api/Administration/Project`,
                params: {
                    ProjectShortName: queryArg.projectShortName,
                    DomainId: queryArg.domainId,
                    StartDate: queryArg.startDate,
                    EndDate: queryArg.endDate,
                },
            }),
        }),
        getApiAdministrationProjectTag: build.query<
            GetApiAdministrationProjectTagApiResponse,
            GetApiAdministrationProjectTagApiArg
        >({
            query: () => ({ url: `/api/Administration/ProjectTag` }),
        }),
        getApiAdministrationUser: build.query<GetApiAdministrationUserApiResponse, GetApiAdministrationUserApiArg>({
            query: queryArg => ({
                url: `/api/Administration/User`,
                params: { ProjectShortName: queryArg.projectShortName, UserSid: queryArg.userSid },
            }),
        }),
        getApiAdministrationLocation: build.query<
            GetApiAdministrationLocationApiResponse,
            GetApiAdministrationLocationApiArg
        >({
            query: queryArg => ({
                url: `/api/Administration/Location`,
                params: { ProviderName: queryArg.providerName, LocationTypeId: queryArg.locationTypeId },
            }),
        }),
        getApiAdministrationLocationById: build.query<
            GetApiAdministrationLocationByIdApiResponse,
            GetApiAdministrationLocationByIdApiArg
        >({
            query: queryArg => ({ url: `/api/Administration/Location/${queryArg.id}` }),
        }),
        getApiAdministrationProvider: build.query<
            GetApiAdministrationProviderApiResponse,
            GetApiAdministrationProviderApiArg
        >({
            query: queryArg => ({ url: `/api/Administration/Provider`, params: { CountryCode: queryArg.countryCode } }),
        }),
        getApiAdministrationProviderById: build.query<
            GetApiAdministrationProviderByIdApiResponse,
            GetApiAdministrationProviderByIdApiArg
        >({
            query: queryArg => ({ url: `/api/Administration/Provider/${queryArg.id}` }),
        }),
        putApiAdministrationPromoteToProviderAdmin: build.mutation<
            PutApiAdministrationPromoteToProviderAdminApiResponse,
            PutApiAdministrationPromoteToProviderAdminApiArg
        >({
            query: queryArg => ({
                url: `/api/Administration/PromoteToProviderAdmin`,
                method: 'PUT',
                params: { UserSid: queryArg.userSid, ProviderId: queryArg.providerId },
            }),
        }),
        putApiAdministrationResetToPlatformUser: build.mutation<
            PutApiAdministrationResetToPlatformUserApiResponse,
            PutApiAdministrationResetToPlatformUserApiArg
        >({
            query: queryArg => ({
                url: `/api/Administration/ResetToPlatformUser`,
                method: 'PUT',
                params: { UserSid: queryArg.userSid },
            }),
        }),
        workerData: build.query<WorkerDataApiResponse, WorkerDataApiArg>({
            query: () => ({ url: `/api/DataStaging/WorkerData` }),
        }),
        getApiLocation: build.query<GetApiLocationApiResponse, GetApiLocationApiArg>({
            query: queryArg => ({
                url: `/api/Location`,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
                params: { ProviderName: queryArg.providerName, LocationTypeId: queryArg.locationTypeId },
            }),
        }),
        postApiLocation: build.mutation<PostApiLocationApiResponse, PostApiLocationApiArg>({
            query: queryArg => ({
                url: `/api/Location`,
                method: 'POST',
                body: queryArg.locationCreate,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        putApiLocation: build.mutation<PutApiLocationApiResponse, PutApiLocationApiArg>({
            query: queryArg => ({
                url: `/api/Location`,
                method: 'PUT',
                body: queryArg.locationUpdate,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        getLocation: build.query<GetLocationApiResponse, GetLocationApiArg>({
            query: queryArg => ({
                url: `/api/Location/${queryArg.id}`,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        deleteApiLocationById: build.mutation<DeleteApiLocationByIdApiResponse, DeleteApiLocationByIdApiArg>({
            query: queryArg => ({
                url: `/api/Location/${queryArg.id}`,
                method: 'DELETE',
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        getApiOtherCountry: build.query<GetApiOtherCountryApiResponse, GetApiOtherCountryApiArg>({
            query: queryArg => ({ url: `/api/Other/Country`, params: { CountryCode: queryArg.countryCode } }),
        }),
        getApiOtherStatistics: build.query<GetApiOtherStatisticsApiResponse, GetApiOtherStatisticsApiArg>({
            query: queryArg => ({
                url: `/api/Other/Statistics`,
                params: { fromDate: queryArg.fromDate, toDate: queryArg.toDate },
            }),
        }),
        getApiProjectByProjectShortNameUsers: build.query<
            GetApiProjectByProjectShortNameUsersApiResponse,
            GetApiProjectByProjectShortNameUsersApiArg
        >({
            query: queryArg => ({ url: `/api/Project/${queryArg.projectShortName}/Users` }),
        }),
        getProject: build.query<GetProjectApiResponse, GetProjectApiArg>({
            query: queryArg => ({ url: `/api/Project`, params: { ProjectShortName: queryArg.projectShortName } }),
        }),
        postApiProject: build.mutation<PostApiProjectApiResponse, PostApiProjectApiArg>({
            query: queryArg => ({ url: `/api/Project`, method: 'POST', body: queryArg.projectCreate }),
        }),
        putApiProject: build.mutation<PutApiProjectApiResponse, PutApiProjectApiArg>({
            query: queryArg => ({ url: `/api/Project`, method: 'PUT', body: queryArg.projectUpdate }),
        }),
        putApiProjectByProjectShortNameUserAndUserSidAssignRoleId: build.mutation<
            PutApiProjectByProjectShortNameUserAndUserSidAssignRoleIdApiResponse,
            PutApiProjectByProjectShortNameUserAndUserSidAssignRoleIdApiArg
        >({
            query: queryArg => ({
                url: `/api/Project/${queryArg.projectShortName}/User/${queryArg.userSid}/Assign/${queryArg.roleId}`,
                method: 'PUT',
            }),
        }),
        deleteApiProjectByProjectShortName: build.mutation<
            DeleteApiProjectByProjectShortNameApiResponse,
            DeleteApiProjectByProjectShortNameApiArg
        >({
            query: queryArg => ({ url: `/api/Project/${queryArg.projectShortName}`, method: 'DELETE' }),
        }),
        putApiProjectByProjectShortNameProjectResourceAndProjectResourceName: build.mutation<
            PutApiProjectByProjectShortNameProjectResourceAndProjectResourceNameApiResponse,
            PutApiProjectByProjectShortNameProjectResourceAndProjectResourceNameApiArg
        >({
            query: queryArg => ({
                url: `/api/Project/${queryArg.projectShortName}/ProjectResource/${queryArg.projectResourceName}`,
                method: 'PUT',
            }),
        }),
        deleteApiProjectByProjectShortNameProjectResourceAndProjectResourceName: build.mutation<
            DeleteApiProjectByProjectShortNameProjectResourceAndProjectResourceNameApiResponse,
            DeleteApiProjectByProjectShortNameProjectResourceAndProjectResourceNameApiArg
        >({
            query: queryArg => ({
                url: `/api/Project/${queryArg.projectShortName}/ProjectResource/${queryArg.projectResourceName}`,
                method: 'DELETE',
            }),
        }),
        putApiProjectByProjectShortNameUserAndUserSid: build.mutation<
            PutApiProjectByProjectShortNameUserAndUserSidApiResponse,
            PutApiProjectByProjectShortNameUserAndUserSidApiArg
        >({
            query: queryArg => ({
                url: `/api/Project/${queryArg.projectShortName}/User/${queryArg.userSid}`,
                method: 'PUT',
            }),
        }),
        deleteApiProjectByProjectShortNameUserAndUserSid: build.mutation<
            DeleteApiProjectByProjectShortNameUserAndUserSidApiResponse,
            DeleteApiProjectByProjectShortNameUserAndUserSidApiArg
        >({
            query: queryArg => ({
                url: `/api/Project/${queryArg.projectShortName}/User/${queryArg.userSid}`,
                method: 'DELETE',
            }),
        }),
        putApiProjectByProjectShortNameProjectTagAndProjectTagId: build.mutation<
            PutApiProjectByProjectShortNameProjectTagAndProjectTagIdApiResponse,
            PutApiProjectByProjectShortNameProjectTagAndProjectTagIdApiArg
        >({
            query: queryArg => ({
                url: `/api/Project/${queryArg.projectShortName}/ProjectTag/${queryArg.projectTagId}`,
                method: 'PUT',
            }),
        }),
        deleteApiProjectByProjectShortNameProjectTagAndProjectTagId: build.mutation<
            DeleteApiProjectByProjectShortNameProjectTagAndProjectTagIdApiResponse,
            DeleteApiProjectByProjectShortNameProjectTagAndProjectTagIdApiArg
        >({
            query: queryArg => ({
                url: `/api/Project/${queryArg.projectShortName}/ProjectTag/${queryArg.projectTagId}`,
                method: 'DELETE',
            }),
        }),
        templatesInfo: build.query<TemplatesInfoApiResponse, TemplatesInfoApiArg>({
            query: queryArg => ({ url: `/api/Project/TemplatesInfo/${queryArg.projectShortName}` }),
        }),
        extendedProjectInfo: build.query<ExtendedProjectInfoApiResponse, ExtendedProjectInfoApiArg>({
            query: queryArg => ({
                url: `/api/Project/ExtendedProjectInfo`,
                params: { ProjectShortName: queryArg.projectShortName },
            }),
        }),
        getApiProjectResourceAssignment: build.query<
            GetApiProjectResourceAssignmentApiResponse,
            GetApiProjectResourceAssignmentApiArg
        >({
            query: queryArg => ({
                url: `/api/ProjectResourceAssignment`,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
                params: { ProjectResourceId: queryArg.projectResourceId },
            }),
        }),
        postApiProjectResourceAssignment: build.mutation<
            PostApiProjectResourceAssignmentApiResponse,
            PostApiProjectResourceAssignmentApiArg
        >({
            query: queryArg => ({
                url: `/api/ProjectResourceAssignment`,
                method: 'POST',
                body: queryArg.projectResourceAssignmentCreate,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        putApiProjectResourceAssignment: build.mutation<
            PutApiProjectResourceAssignmentApiResponse,
            PutApiProjectResourceAssignmentApiArg
        >({
            query: queryArg => ({
                url: `/api/ProjectResourceAssignment`,
                method: 'PUT',
                body: queryArg.projectResourceAssignmentUpdate,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        getProjectResourceAssignment: build.query<
            GetProjectResourceAssignmentApiResponse,
            GetProjectResourceAssignmentApiArg
        >({
            query: queryArg => ({
                url: `/api/ProjectResourceAssignment/${queryArg.id}`,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        deleteApiProjectResourceAssignmentById: build.mutation<
            DeleteApiProjectResourceAssignmentByIdApiResponse,
            DeleteApiProjectResourceAssignmentByIdApiArg
        >({
            query: queryArg => ({
                url: `/api/ProjectResourceAssignment/${queryArg.id}`,
                method: 'DELETE',
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        getApiProjectResource: build.query<GetApiProjectResourceApiResponse, GetApiProjectResourceApiArg>({
            query: queryArg => ({
                url: `/api/ProjectResource`,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
                params: {
                    ProjectShortName: queryArg.projectShortName,
                    LocationTypeId: queryArg.locationTypeId,
                    StartDate: queryArg.startDate,
                    EndDate: queryArg.endDate,
                },
            }),
        }),
        postApiProjectResource: build.mutation<PostApiProjectResourceApiResponse, PostApiProjectResourceApiArg>({
            query: queryArg => ({
                url: `/api/ProjectResource`,
                method: 'POST',
                body: queryArg.projectResourceCreate,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        putApiProjectResource: build.mutation<PutApiProjectResourceApiResponse, PutApiProjectResourceApiArg>({
            query: queryArg => ({
                url: `/api/ProjectResource`,
                method: 'PUT',
                body: queryArg.projectResourceUpdate,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        getProjectResource: build.query<GetProjectResourceApiResponse, GetProjectResourceApiArg>({
            query: queryArg => ({
                url: `/api/ProjectResource/${queryArg.id}`,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        deleteApiProjectResourceById: build.mutation<
            DeleteApiProjectResourceByIdApiResponse,
            DeleteApiProjectResourceByIdApiArg
        >({
            query: queryArg => ({
                url: `/api/ProjectResource/${queryArg.id}`,
                method: 'DELETE',
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        getApiProjectTag: build.query<GetApiProjectTagApiResponse, GetApiProjectTagApiArg>({
            query: queryArg => ({ url: `/api/ProjectTag`, params: { TagName: queryArg.tagName } }),
        }),
        postApiProjectTag: build.mutation<PostApiProjectTagApiResponse, PostApiProjectTagApiArg>({
            query: queryArg => ({ url: `/api/ProjectTag`, method: 'POST', body: queryArg.projectTagCreate }),
        }),
        putApiProjectTag: build.mutation<PutApiProjectTagApiResponse, PutApiProjectTagApiArg>({
            query: queryArg => ({ url: `/api/ProjectTag`, method: 'PUT', body: queryArg.projectTagUpdate }),
        }),
        getProjectTag: build.query<GetProjectTagApiResponse, GetProjectTagApiArg>({
            query: queryArg => ({ url: `/api/ProjectTag/${queryArg.id}` }),
        }),
        deleteApiProjectTagById: build.mutation<DeleteApiProjectTagByIdApiResponse, DeleteApiProjectTagByIdApiArg>({
            query: queryArg => ({ url: `/api/ProjectTag/${queryArg.id}`, method: 'DELETE' }),
        }),
        getApiProvider: build.query<GetApiProviderApiResponse, GetApiProviderApiArg>({
            query: queryArg => ({
                url: `/api/Provider`,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
                params: { CountryCode: queryArg.countryCode },
            }),
        }),
        postApiProvider: build.mutation<PostApiProviderApiResponse, PostApiProviderApiArg>({
            query: queryArg => ({ url: `/api/Provider`, method: 'POST', body: queryArg.providerCreate }),
        }),
        putApiProvider: build.mutation<PutApiProviderApiResponse, PutApiProviderApiArg>({
            query: queryArg => ({
                url: `/api/Provider`,
                method: 'PUT',
                body: queryArg.providerUpdate,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        getProvider: build.query<GetProviderApiResponse, GetProviderApiArg>({
            query: queryArg => ({
                url: `/api/Provider/${queryArg.id}`,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        deleteApiProviderById: build.mutation<DeleteApiProviderByIdApiResponse, DeleteApiProviderByIdApiArg>({
            query: queryArg => ({ url: `/api/Provider/${queryArg.id}`, method: 'DELETE' }),
        }),
        postApiPublicHandleByActionLink: build.mutation<
            PostApiPublicHandleByActionLinkApiResponse,
            PostApiPublicHandleByActionLinkApiArg
        >({
            query: queryArg => ({ url: `/api/Public/Handle/${queryArg.actionLink}`, method: 'POST' }),
        }),
        getApiPublicRequestInfoByRequestId: build.query<
            GetApiPublicRequestInfoByRequestIdApiResponse,
            GetApiPublicRequestInfoByRequestIdApiArg
        >({
            query: queryArg => ({ url: `/api/Public/RequestInfo/${queryArg.requestId}` }),
        }),
        getApiRequests: build.query<GetApiRequestsApiResponse, GetApiRequestsApiArg>({
            query: queryArg => ({
                url: `/api/Requests`,
                params: { State: queryArg.state, Type: queryArg['type'], ProjectShortName: queryArg.projectShortName },
            }),
        }),
        postApiRequests: build.mutation<PostApiRequestsApiResponse, PostApiRequestsApiArg>({
            query: queryArg => ({ url: `/api/Requests`, method: 'POST', body: queryArg.requestCreate }),
        }),
        getApiRequestsSentRequests: build.query<
            GetApiRequestsSentRequestsApiResponse,
            GetApiRequestsSentRequestsApiArg
        >({
            query: queryArg => ({
                url: `/api/Requests/SentRequests`,
                params: { State: queryArg.state, Type: queryArg['type'], ProjectShortName: queryArg.projectShortName },
            }),
        }),
        putApiRequestsByRequestIdAccept: build.mutation<
            PutApiRequestsByRequestIdAcceptApiResponse,
            PutApiRequestsByRequestIdAcceptApiArg
        >({
            query: queryArg => ({ url: `/api/Requests/${queryArg.requestId}/Accept`, method: 'PUT' }),
        }),
        putApiRequestsByRequestIdReject: build.mutation<
            PutApiRequestsByRequestIdRejectApiResponse,
            PutApiRequestsByRequestIdRejectApiArg
        >({
            query: queryArg => ({
                url: `/api/Requests/${queryArg.requestId}/Reject`,
                method: 'PUT',
                params: { Reason: queryArg.reason },
            }),
        }),
        getApiRole: build.query<GetApiRoleApiResponse, GetApiRoleApiArg>({
            query: () => ({ url: `/api/Role` }),
        }),
        postApiRole: build.mutation<PostApiRoleApiResponse, PostApiRoleApiArg>({
            query: queryArg => ({ url: `/api/Role`, method: 'POST', body: queryArg.createSystemRoleRequest }),
        }),
        putApiRole: build.mutation<PutApiRoleApiResponse, PutApiRoleApiArg>({
            query: queryArg => ({ url: `/api/Role`, method: 'PUT', body: queryArg.updateSystemRoleRequest }),
        }),
        getApiRoleByProjectShortName: build.query<
            GetApiRoleByProjectShortNameApiResponse,
            GetApiRoleByProjectShortNameApiArg
        >({
            query: queryArg => ({ url: `/api/Role/${queryArg.projectShortName}` }),
        }),
        deleteApiRoleByRoleName: build.mutation<DeleteApiRoleByRoleNameApiResponse, DeleteApiRoleByRoleNameApiArg>({
            query: queryArg => ({ url: `/api/Role/${queryArg.roleName}`, method: 'DELETE' }),
        }),
        getApiRolePermissionTypes: build.query<GetApiRolePermissionTypesApiResponse, GetApiRolePermissionTypesApiArg>({
            query: () => ({ url: `/api/Role/PermissionTypes` }),
        }),
        getRole: build.query<GetRoleApiResponse, GetRoleApiArg>({
            query: queryArg => ({ url: `/api/Role/PermissionTypes/${queryArg.roleName}` }),
        }),
        syncDetails: build.query<SyncDetailsApiResponse, SyncDetailsApiArg>({
            query: queryArg => ({
                url: `/api/Service/SyncDetails`,
                headers: { 'X-Api-ProviderServiceKey': queryArg['X-Api-ProviderServiceKey'] },
            }),
        }),
        projectsInfo: build.query<ProjectsInfoApiResponse, ProjectsInfoApiArg>({
            query: () => ({ url: `/api/Service/ProjectsInfo` }),
        }),
        getApiUserPreferences: build.query<GetApiUserPreferencesApiResponse, GetApiUserPreferencesApiArg>({
            query: () => ({ url: `/api/User/Preferences` }),
        }),
        putApiUserPreferences: build.mutation<PutApiUserPreferencesApiResponse, PutApiUserPreferencesApiArg>({
            query: queryArg => ({ url: `/api/User/Preferences`, method: 'PUT', body: queryArg.body }),
        }),
        getApiUserInfo: build.query<GetApiUserInfoApiResponse, GetApiUserInfoApiArg>({
            query: () => ({ url: `/api/UserInfo` }),
        }),
        getApiUserInfoExtended: build.query<GetApiUserInfoExtendedApiResponse, GetApiUserInfoExtendedApiArg>({
            query: () => ({ url: `/api/UserInfo/Extended` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as userOrg };
export type GetApiAdministrationProjectApiResponse = /** status 200 OK */ ProjectModel[];
export type GetApiAdministrationProjectApiArg = {
    /** LEXIS Project Short-Name */
    projectShortName?: string;
    /** LEXIS Domain Id */
    domainId?: number;
    /** Start Date */
    startDate?: string;
    /** End Date */
    endDate?: string;
};
export type GetApiAdministrationProjectTagApiResponse = /** status 200 OK */ ProjectTagModel[];
export type GetApiAdministrationProjectTagApiArg = void;
export type GetApiAdministrationUserApiResponse = /** status 200 OK */ UserModel[];
export type GetApiAdministrationUserApiArg = {
    /** LEXIS Project Short-Name */
    projectShortName?: string;
    /** LEXIS User Sid */
    userSid?: string;
};
export type GetApiAdministrationLocationApiResponse = /** status 200 OK */ LocationModel[];
export type GetApiAdministrationLocationApiArg = {
    /** LEXIS Provider Name */
    providerName?: string;
    /** LEXIS Location Type Id */
    locationTypeId?: number;
};
export type GetApiAdministrationLocationByIdApiResponse = /** status 200 OK */ ProviderModel;
export type GetApiAdministrationLocationByIdApiArg = {
    /** LEXIS Location Id */
    id: string;
};
export type GetApiAdministrationProviderApiResponse = /** status 200 OK */ ProviderModel[];
export type GetApiAdministrationProviderApiArg = {
    /** CountryCode */
    countryCode?: string;
};
export type GetApiAdministrationProviderByIdApiResponse = /** status 200 OK */ ProviderModel;
export type GetApiAdministrationProviderByIdApiArg = {
    /** LEXIS Provider Id */
    id: string;
};
export type PutApiAdministrationPromoteToProviderAdminApiResponse = unknown;
export type PutApiAdministrationPromoteToProviderAdminApiArg = {
    /** LEXIS User Sid */
    userSid: string;
    /** LEXIS Provider Id */
    providerId: string;
};
export type PutApiAdministrationResetToPlatformUserApiResponse = unknown;
export type PutApiAdministrationResetToPlatformUserApiArg = {
    /** LEXIS User Sid */
    userSid: string;
};
export type WorkerDataApiResponse = /** status 200 OK */ DataStagingDataModel;
export type WorkerDataApiArg = void;
export type GetApiLocationApiResponse = /** status 200 OK */ LocationModel[];
export type GetApiLocationApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    /** LEXIS Provider Name */
    providerName?: string;
    /** LEXIS Location Type Id */
    locationTypeId?: number;
};
export type PostApiLocationApiResponse = unknown;
export type PostApiLocationApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    locationCreate: LocationCreate;
};
export type PutApiLocationApiResponse = unknown;
export type PutApiLocationApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    locationUpdate: LocationUpdate;
};
export type GetLocationApiResponse = /** status 200 OK */ LocationModel;
export type GetLocationApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    /** LEXIS Location Id */
    id: string;
};
export type DeleteApiLocationByIdApiResponse = unknown;
export type DeleteApiLocationByIdApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    /** LEXIS Location Id */
    id: string;
};
export type GetApiOtherCountryApiResponse = /** status 200 OK */ CountryModel[];
export type GetApiOtherCountryApiArg = {
    /** CountryCode */
    countryCode?: string;
};
export type GetApiOtherStatisticsApiResponse = /** status 200 OK */ any;
export type GetApiOtherStatisticsApiArg = {
    /** Specify the start date for the statistics. If not provided, the default is the current month. */
    fromDate?: string;
    toDate?: string;
};
export type GetApiProjectByProjectShortNameUsersApiResponse = /** status 200 OK */ ProjectUserModel[];
export type GetApiProjectByProjectShortNameUsersApiArg = {
    /** LEXIS Project Short-Name */
    projectShortName: string;
};
export type GetProjectApiResponse = /** status 200 OK */ ProjectModel[];
export type GetProjectApiArg = {
    /** LEXIS Project Short-Name */
    projectShortName?: string;
};
export type PostApiProjectApiResponse = unknown;
export type PostApiProjectApiArg = {
    projectCreate: ProjectCreate;
};
export type PutApiProjectApiResponse = unknown;
export type PutApiProjectApiArg = {
    projectUpdate: ProjectUpdate;
};
export type PutApiProjectByProjectShortNameUserAndUserSidAssignRoleIdApiResponse = unknown;
export type PutApiProjectByProjectShortNameUserAndUserSidAssignRoleIdApiArg = {
    projectShortName: string;
    userSid: string;
    roleId: number;
};
export type DeleteApiProjectByProjectShortNameApiResponse = unknown;
export type DeleteApiProjectByProjectShortNameApiArg = {
    /** LEXIS Project Short-Name */
    projectShortName: string;
};
export type PutApiProjectByProjectShortNameProjectResourceAndProjectResourceNameApiResponse = unknown;
export type PutApiProjectByProjectShortNameProjectResourceAndProjectResourceNameApiArg = {
    /** LEXIS Project Short-Name */
    projectShortName: string;
    /** LEXIS Project Resource Id */
    projectResourceName: string;
};
export type DeleteApiProjectByProjectShortNameProjectResourceAndProjectResourceNameApiResponse = unknown;
export type DeleteApiProjectByProjectShortNameProjectResourceAndProjectResourceNameApiArg = {
    /** LEXIS Project Short-Name */
    projectShortName: string;
    /** LEXIS Project Resource name */
    projectResourceName: string;
};
export type PutApiProjectByProjectShortNameUserAndUserSidApiResponse = unknown;
export type PutApiProjectByProjectShortNameUserAndUserSidApiArg = {
    /** LEXIS Project Short-Name */
    projectShortName: string;
    /** LEXIS User Sid */
    userSid: string;
};
export type DeleteApiProjectByProjectShortNameUserAndUserSidApiResponse = unknown;
export type DeleteApiProjectByProjectShortNameUserAndUserSidApiArg = {
    /** LEXIS Project Short-Name */
    projectShortName: string;
    /** LEXIS User Sid */
    userSid: string;
};
export type PutApiProjectByProjectShortNameProjectTagAndProjectTagIdApiResponse = unknown;
export type PutApiProjectByProjectShortNameProjectTagAndProjectTagIdApiArg = {
    /** LEXIS Project Short-Name */
    projectShortName: string;
    /** LEXIS ProjectTag Id */
    projectTagId: string;
};
export type DeleteApiProjectByProjectShortNameProjectTagAndProjectTagIdApiResponse = unknown;
export type DeleteApiProjectByProjectShortNameProjectTagAndProjectTagIdApiArg = {
    /** LEXIS Project Short-Name */
    projectShortName: string;
    /** LEXIS ProjectTag Id */
    projectTagId: string;
};
export type TemplatesInfoApiResponse = /** status 200 OK */ TemplateInfoResponse[];
export type TemplatesInfoApiArg = {
    projectShortName: string;
};
export type ExtendedProjectInfoApiResponse = /** status 200 OK */ ExtendedProjectModel[];
export type ExtendedProjectInfoApiArg = {
    /** LEXIS Project Short-Name */
    projectShortName: string;
};
export type GetApiProjectResourceAssignmentApiResponse = /** status 200 OK */ ProjectResourceAssignmentModel[];
export type GetApiProjectResourceAssignmentApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    /** LEXIS Project Resource Id */
    projectResourceId?: string;
};
export type PostApiProjectResourceAssignmentApiResponse = unknown;
export type PostApiProjectResourceAssignmentApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    projectResourceAssignmentCreate: ProjectResourceAssignmentCreate;
};
export type PutApiProjectResourceAssignmentApiResponse = unknown;
export type PutApiProjectResourceAssignmentApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    projectResourceAssignmentUpdate: ProjectResourceAssignmentUpdate;
};
export type GetProjectResourceAssignmentApiResponse = /** status 200 OK */ ProjectResourceAssignmentModel;
export type GetProjectResourceAssignmentApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    /** LEXIS Project Resource Assignment Id */
    id: string;
};
export type DeleteApiProjectResourceAssignmentByIdApiResponse = unknown;
export type DeleteApiProjectResourceAssignmentByIdApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    /** LEXIS Project Resource Assignment Id */
    id: string;
};
export type GetApiProjectResourceApiResponse = /** status 200 OK */ ProjectResourceModel[];
export type GetApiProjectResourceApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    /** LEXIS Project Short Name */
    projectShortName?: string;
    /** LEXIS Status Id */
    locationTypeId?: number;
    /** LEXIS Location Type Id */
    startDate?: string;
    /** LEXIS Project Resource StartDate */
    endDate?: string;
};
export type PostApiProjectResourceApiResponse = unknown;
export type PostApiProjectResourceApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    projectResourceCreate: ProjectResourceCreate;
};
export type PutApiProjectResourceApiResponse = unknown;
export type PutApiProjectResourceApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    projectResourceUpdate: ProjectResourceUpdate;
};
export type GetProjectResourceApiResponse = /** status 200 OK */ ProjectResourceModel;
export type GetProjectResourceApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    /** LEXIS Project Resource Id */
    id: string;
};
export type DeleteApiProjectResourceByIdApiResponse = unknown;
export type DeleteApiProjectResourceByIdApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    /** LEXIS Project Resource Id */
    id: string;
};
export type GetApiProjectTagApiResponse = /** status 200 OK */ ProjectTagModel[];
export type GetApiProjectTagApiArg = {
    /** LEXIS Project Tag Name */
    tagName?: string;
};
export type PostApiProjectTagApiResponse = unknown;
export type PostApiProjectTagApiArg = {
    projectTagCreate: ProjectTagCreate;
};
export type PutApiProjectTagApiResponse = unknown;
export type PutApiProjectTagApiArg = {
    projectTagUpdate: ProjectTagUpdate;
};
export type GetProjectTagApiResponse = /** status 200 OK */ ProjectTagModel;
export type GetProjectTagApiArg = {
    /** LEXIS Project Tag */
    id: string;
};
export type DeleteApiProjectTagByIdApiResponse = unknown;
export type DeleteApiProjectTagByIdApiArg = {
    /** LEXIS Project Tag */
    id: string;
};
export type GetApiProviderApiResponse = /** status 200 OK */ ProviderModel[];
export type GetApiProviderApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    /** CountryCode */
    countryCode?: string;
};
export type PostApiProviderApiResponse = unknown;
export type PostApiProviderApiArg = {
    providerCreate: ProviderCreate;
};
export type PutApiProviderApiResponse = unknown;
export type PutApiProviderApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    providerUpdate: ProviderUpdate;
};
export type GetProviderApiResponse = /** status 200 OK */ ProviderModel;
export type GetProviderApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey'?: string;
    /** LEXIS Provider Id */
    id: string;
};
export type DeleteApiProviderByIdApiResponse = unknown;
export type DeleteApiProviderByIdApiArg = {
    /** LEXIS Provider Id */
    id: string;
};
export type PostApiPublicHandleByActionLinkApiResponse = unknown;
export type PostApiPublicHandleByActionLinkApiArg = {
    actionLink: string;
};
export type GetApiPublicRequestInfoByRequestIdApiResponse = /** status 200 Success */ PublicRequestModel;
export type GetApiPublicRequestInfoByRequestIdApiArg = {
    requestId: string;
};
export type GetApiRequestsApiResponse = /** status 200 OK */ RequestModel[] | /** status 204 No Content */ Blob;
export type GetApiRequestsApiArg = {
    /** Request State: {Pending -1, Accept - 2, Reject - 3} */
    state?: ERequestState;
    /** Request Type: { SelfOnboarding - 1, ProjectResourceAssignment - 2, HEAppECreation - 3} */
    type?: ERequestType;
    /** LEXIS Project Short-Name */
    projectShortName?: string;
};
export type PostApiRequestsApiResponse = unknown;
export type PostApiRequestsApiArg = {
    requestCreate: RequestCreate;
};
export type GetApiRequestsSentRequestsApiResponse =
    /** status 200 OK */
    RequestModel[] | /** status 204 No Content */ Blob;
export type GetApiRequestsSentRequestsApiArg = {
    /** Request State: {Pending -1, Accept - 2, Reject - 3} */
    state?: ERequestState;
    /** Request Type: { SelfOnboarding - 1, ProjectResourceAssignment - 2, HEAppECreation - 3} */
    type?: ERequestType;
    /** LEXIS Project Short-Name */
    projectShortName?: string;
};
export type PutApiRequestsByRequestIdAcceptApiResponse = unknown;
export type PutApiRequestsByRequestIdAcceptApiArg = {
    /** OnBoarding Request Id */
    requestId: string;
};
export type PutApiRequestsByRequestIdRejectApiResponse = unknown;
export type PutApiRequestsByRequestIdRejectApiArg = {
    /** OnBoarding Request Id */
    requestId: string;
    /** Reason */
    reason?: string;
};
export type GetApiRoleApiResponse = /** status 200 OK */ SystemRole[];
export type GetApiRoleApiArg = void;
export type PostApiRoleApiResponse = unknown;
export type PostApiRoleApiArg = {
    createSystemRoleRequest: CreateSystemRoleRequest;
};
export type PutApiRoleApiResponse = unknown;
export type PutApiRoleApiArg = {
    updateSystemRoleRequest: UpdateSystemRoleRequest;
};
export type GetApiRoleByProjectShortNameApiResponse = /** status 200 OK */ SystemRole[];
export type GetApiRoleByProjectShortNameApiArg = {
    /** LEXIS Short-Name */
    projectShortName: string;
};
export type DeleteApiRoleByRoleNameApiResponse = unknown;
export type DeleteApiRoleByRoleNameApiArg = {
    /** LEXIS Role Name */
    roleName: string;
};
export type GetApiRolePermissionTypesApiResponse = /** status 200 OK */ UserPermission[];
export type GetApiRolePermissionTypesApiArg = void;
export type GetRoleApiResponse = /** status 200 OK */ UserPermission[];
export type GetRoleApiArg = {
    /** LEXIS Role Name */
    roleName: string;
};
export type SyncDetailsApiResponse = /** status 200 OK */ UserInfoExtendedModel[];
export type SyncDetailsApiArg = {
    /** Provider Service Key */
    'X-Api-ProviderServiceKey': string;
};
export type ProjectsInfoApiResponse = /** status 200 OK */ ProjectInfoModel[];
export type ProjectsInfoApiArg = void;
export type GetApiUserPreferencesApiResponse = /** status 200 OK */ string;
export type GetApiUserPreferencesApiArg = void;
export type PutApiUserPreferencesApiResponse = /** status 200 OK */ string;
export type PutApiUserPreferencesApiArg = {
    body: any;
};
export type GetApiUserInfoApiResponse = /** status 200 OK */ UserInfoModel;
export type GetApiUserInfoApiArg = void;
export type GetApiUserInfoExtendedApiResponse = /** status 200 OK */ UserInfoExtendedModel;
export type GetApiUserInfoExtendedApiArg = void;
export type ProjectModel = {
    Id?: string | null;
    Name?: string | null;
    ShortName?: string | null;
    Description?: string | null;
    ContactEmail?: string | null;
    StartDate?: string | null;
    EndDate?: string | null;
    DomainId?: number | null;
    AssignedProjectResourceIds?: string[] | null;
    AssignedTags?: string[] | null;
};
export type ProjectTagModel = {
    Id?: string | null;
    Name?: string | null;
    Description?: string | null;
};
export type UserModel = {
    UserSid?: string;
    Email?: string | null;
    UserName?: string | null;
    InProjects?: string[] | null;
};
export type ProviderModel = {
    Id?: string | null;
    Name?: string | null;
    Description?: string | null;
    Country?: string | null;
    SupportEmail?: string | null;
};
export type LocationModel = {
    Id?: string | null;
    Name?: string | null;
    Description?: string | null;
    Endpoint?: string | null;
    Provider?: ProviderModel;
    LocationTypeId?: number | null;
    IsShared?: boolean;
};
export type DataStagingPart = {
    Name?: string | null;
    JsonData?: string | null;
};
export type DataStagingDataModel = {
    Name?: string | null;
    PublicData?: DataStagingPart;
    PrivateData?: DataStagingPart;
};
export type LocationCreate = {
    Name?: string | null;
    Description?: string | null;
    IsPrivate?: boolean;
    Endpoint?: string | null;
    ProviderId?: string | null;
    LocationTypeId?: number | null;
    IsShared?: boolean;
};
export type LocationUpdate = {
    Id?: string | null;
    Name?: string | null;
    Description?: string | null;
    IsPrivate?: boolean;
    Endpoint?: string | null;
    ProviderId?: string | null;
    LocationTypeId?: number | null;
    IsShared?: boolean;
};
export type CountryModel = {
    Code?: string | null;
    Name?: string | null;
};
export type ProjectUserModel = {
    UserSid?: string;
    Email?: string | null;
    RoleName?: string | null;
};
export type ProjectCreate = {
    Name?: string | null;
    ShortName?: string | null;
    Description?: string | null;
    ContactEmail?: string | null;
    StartDate?: string;
    EndDate?: string;
    DomainId?: number | null;
};
export type ProjectUpdate = {
    Name?: string | null;
    ShortName?: string;
    Description?: string | null;
    ContactEmail?: string | null;
    StartDate?: string;
    EndDate?: string;
    DomainId?: number | null;
};
export type TemplateParameterModel = {
    Identifier?: string | null;
    Description?: string | null;
};
export type CommandTemplate = {
    Id?: number;
    Name?: string | null;
    IsGeneric?: boolean;
    Parameters?: TemplateParameterModel[] | null;
};
export type NodeAllocation = {
    LexisResourceName?: string | null;
    CommandTemplates?: CommandTemplate[] | null;
};
export type NodeInfo = {
    NodeId?: number;
    NodeType?: string | null;
    NumberOfNodes?: number;
    ClusterPerNode?: number;
    MaxWallTime?: number;
    FileTransferMethodId?: number;
    Allocations?: NodeAllocation[] | null;
};
export type TemplateInfoResponse = {
    IsAvailable?: boolean;
    ClusterName?: string | null;
    LocationId?: string | null;
    ClusterId?: number | null;
    Nodes?: NodeInfo[] | null;
};
export type StringStringKeyValuePair = {
    Key?: string | null;
    Value?: string | null;
};
export type ProjectResourceAssignmentExtendedModel = {
    ProjectResourceName?: string | null;
    LocationName?: string | null;
    AllocationAmount?: number | null;
    AllocationUnitTypeName?: string | null;
    Specifications?: StringStringKeyValuePair[] | null;
};
export type ExtendedProjectModel = {
    Name?: string | null;
    ShortName?: string | null;
    Description?: string | null;
    ContactEmail?: string | null;
    StartDate?: string | null;
    EndDate?: string | null;
    DomainId?: number | null;
    AssignedProjectResources?: ProjectResourceAssignmentExtendedModel[] | null;
    AssignedTags?: string[] | null;
};
export type ProjectResourceAssignmentModel = {
    Id?: string | null;
    ProjectResourceId?: string | null;
    LocationId?: string | null;
    IsLocationShared?: boolean;
    LocationName?: string | null;
    AllocationAmount?: number | null;
    AllocationUnitTypeId?: number | null;
    Specifications?: StringStringKeyValuePair[] | null;
};
export type ProjectResourceAssignmentCreate = {
    ProjectResourceId?: string | null;
    LocationId?: string | null;
    AllocationAmount?: number | null;
    AllocationUnitTypeId?: number | null;
    Specifications?: StringStringKeyValuePair[] | null;
};
export type ProjectResourceAssignmentUpdate = {
    Id?: string | null;
    AllocationAmount?: number | null;
    AllocationUnitTypeId?: number | null;
    Specifications?: StringStringKeyValuePair[] | null;
    Description?: string | null;
};
export type ProjectResourceModel = {
    Id?: string | null;
    Name?: string | null;
    Description?: string | null;
    PrimaryInvestigatorEmail?: string | null;
    StartDate?: string | null;
    EndDate?: string | null;
    Assignments?: ProjectResourceAssignmentModel[] | null;
};
export type ProjectResourceCreate = {
    Name?: string | null;
    Description?: string | null;
    PrimaryInvestigatorEmail?: string | null;
    StartDate?: string | null;
    EndDate?: string | null;
};
export type ProjectResourceUpdate = {
    Id?: string | null;
    Name?: string | null;
    Description?: string | null;
    PrimaryInvestigatorEmail?: string | null;
    StartDate?: string | null;
    EndDate?: string | null;
};
export type ProjectTagCreate = {
    Name?: string | null;
    Description?: string | null;
};
export type ProjectTagUpdate = {
    Id?: string | null;
    Name?: string | null;
    Description?: string | null;
};
export type ProviderCreate = {
    Name?: string | null;
    Description?: string | null;
    IsPrivate?: boolean;
    CountryCode?: string | null;
    SupportEmail?: string | null;
};
export type ProviderUpdate = {
    Id?: string | null;
    Name?: string | null;
    Description?: string | null;
    IsPrivate?: boolean;
    CountryCode?: string | null;
    SupportEmail?: string | null;
};
export type PublicRequestModel = {
    RequestId?: string | null;
    RequestorUserName?: string | null;
    RequestorEmail?: string | null;
    ProjectShortName?: string;
    ProjectResourceName?: string | null;
    ProviderName?: string | null;
    RequestState?: number;
    ValidTo?: string;
    RequestType?: number;
    AcceptActionLink?: string | null;
    RejectActionLink?: string | null;
};
export type RequestModel = {
    RequestId?: string | null;
    UserSid?: string | null;
    UserName?: string | null;
    Email?: string | null;
    ProjectShortName?: string | null;
    State?: number | null;
    Type?: number;
    LastModification?: string;
};
export type ERequestState = 1 | 2 | 3 | 4 | 5;
export type ERequestType = 0 | 1 | 2 | 3;
export type RequestCreate = {
    ProjectShortName?: string;
    RequestType?: ERequestType;
    ProjectResourceName?: string | null;
    ProviderId?: string | null;
    ContactEmail?: string | null;
};
export type SystemPermissionType = {
    Id?: number;
    Name?: string | null;
    Description?: string | null;
    SystemRoles?: SystemRole[] | null;
};
export type SystemRole = {
    Id?: number;
    Name?: string | null;
    Description?: string | null;
    CreatedAt?: string;
    ModifiedAt?: string | null;
    IsDeleted?: boolean;
    BaseSystemRole?: SystemRole;
    BaseSystemRoleId?: number | null;
    IsReserved?: boolean;
    SystemPermissionTypes?: SystemPermissionType[] | null;
};
export type CreateSystemRoleRequest = {
    SystemRoleName?: string | null;
    BaseSystemRoleName?: string | null;
    Description?: string | null;
    SystemPermissionTypes?: string[] | null;
};
export type UpdateSystemRoleRequest = {
    SystemRoleName?: string | null;
    Description?: string | null;
    NewSystemPermissionTypes?: string[] | null;
};
export type AssignedUserToProject = {
    CreatedAt?: string;
    ModifiedAt?: string | null;
    IsDeleted?: boolean;
    Project?: Project;
    ProjectId?: string;
    User?: User;
    AssignedByUser?: User;
};
export type Country = {
    Id?: number;
    Name?: string | null;
    Description?: string | null;
};
export type LocationType = {
    Id?: number;
    Name?: string | null;
    Description?: string | null;
};
export type AssignedProjectResourceToProject = {
    Project?: Project;
    ProjectId?: string;
    ProjectResource?: ProjectResource;
    CreatedAt?: string;
    ModifiedAt?: string | null;
    IsDeleted?: boolean;
};
export type ProjectResource = {
    Id?: string;
    CreatedAt?: string;
    ModifiedAt?: string | null;
    IsDeleted?: boolean;
    Name?: string | null;
    Description?: string | null;
    PrimaryInvestigatorEmail?: string | null;
    StartDate?: string | null;
    EndDate?: string | null;
    AssignedProjects?: AssignedProjectResourceToProject[] | null;
    AssignedLocations?: AssignedLocation[] | null;
};
export type AllocationUnitType = {
    Id?: number;
    Name?: string | null;
    Description?: string | null;
};
export type AssignedLocationValue = {
    Id?: string;
    Name?: string | null;
    Value?: string | null;
    AssignedLocation?: AssignedLocation;
};
export type AssignedLocation = {
    Id?: string;
    CreatedAt?: string;
    ModifiedAt?: string | null;
    IsDeleted?: boolean;
    ProjectResource?: ProjectResource;
    Location?: Location;
    AllocationUnitCount?: number | null;
    AllocationUnitType?: AllocationUnitType;
    AssignedLocationsValues?: AssignedLocationValue[] | null;
    Description?: string | null;
};
export type Location = {
    Id?: string;
    CreatedAt?: string;
    ModifiedAt?: string | null;
    IsDeleted?: boolean;
    Name?: string | null;
    Description?: string | null;
    Endpoint?: string | null;
    IsPrivate?: boolean;
    Provider?: Provider;
    LocationType?: LocationType;
    AssignedLocations?: AssignedLocation[] | null;
    IsShared?: boolean;
};
export type Provider = {
    Id?: string;
    CreatedAt?: string;
    ModifiedAt?: string | null;
    IsDeleted?: boolean;
    Name?: string | null;
    Description?: string | null;
    IsPrivate?: boolean;
    SupportEmail?: string | null;
    Country?: Country;
    Locations?: Location[] | null;
    ResponsibleUsers?: User[] | null;
};
export type User = {
    Id?: string;
    CreatedAt?: string;
    ModifiedAt?: string | null;
    IsDeleted?: boolean;
    Sid?: string;
    Email?: string | null;
    UserName?: string | null;
    AssignedToProjects?: AssignedUserToProject[] | null;
    AssignedProvider?: Provider;
};
export type ProjectTag = {
    Id?: string;
    CreatedAt?: string;
    ModifiedAt?: string | null;
    IsDeleted?: boolean;
    Name?: string | null;
    Description?: string | null;
    Projects?: Project[] | null;
    CreatedByUser?: User;
};
export type ProjectDomainType = {
    Id?: number;
    Name?: string | null;
    Description?: string | null;
};
export type Project = {
    Id?: string;
    CreatedAt?: string;
    ModifiedAt?: string | null;
    IsDeleted?: boolean;
    Name?: string | null;
    ShortName?: string;
    Description?: string | null;
    Email?: string | null;
    StartDate?: string;
    EndDate?: string;
    ProjectTags?: ProjectTag[] | null;
    ProjectDomainType?: ProjectDomainType;
    CreatedByUser?: User;
    AssignedProjectResources?: AssignedProjectResourceToProject[] | null;
    AssignedUsers?: AssignedUserToProject[] | null;
    UserPermissions?: UserPermission[] | null;
};
export type UserPermission = {
    Id?: string;
    CreatedAt?: string;
    ModifiedAt?: string | null;
    IsDeleted?: boolean;
    Project?: Project;
    User?: User;
    SystemRole?: SystemRole;
};
export type LocationDetailModel = {
    Name?: string | null;
    Descritpion?: string | null;
    Endpoint?: string | null;
    IsPrivate?: boolean;
    Type?: string | null;
    AllocationUnitCount?: number | null;
    AllocationUnitType?: string | null;
    Values?: StringStringKeyValuePair[] | null;
};
export type ProjectResourceDetailModel = {
    Name?: string | null;
    Description?: string | null;
    StartDate?: string | null;
    EndDate?: string | null;
    Locations?: LocationDetailModel[] | null;
};
export type UserSystemRoleExtendedModel = {
    ProjectShortName?: string;
    UserEmail?: string | null;
    RoleName?: string | null;
    ProjectResources?: ProjectResourceDetailModel[] | null;
    SystemPermissionTypes?: string[] | null;
};
export type UserInfoExtendedModel = {
    Id?: string;
    KeycloakSid?: string | null;
    Name?: string | null;
    UserName?: string | null;
    Email?: string | null;
    SystemRoles?: UserSystemRoleExtendedModel[] | null;
};
export type AssociatedProjectModel = {
    Id?: string;
    ShortName?: string;
    CollectorName?: string | null;
};
export type ProjectResourceAssignmentServiceModel = {
    LocationName?: string | null;
    LocationDescription?: string | null;
    AllocationAmount?: number | null;
    AllocationUnitTypeName?: string | null;
    Specifications?: StringStringKeyValuePair[] | null;
};
export type ProjectResourceServiceModel = {
    Id?: string | null;
    Name?: string | null;
    Description?: string | null;
    StartDate?: string | null;
    EndDate?: string | null;
    AssociatedProjects?: AssociatedProjectModel[] | null;
    Assignments?: ProjectResourceAssignmentServiceModel[] | null;
};
export type ProjectInfoModel = {
    ProviderId?: string | null;
    ProviderName?: string | null;
    ProviderDescription?: string | null;
    Resources?: ProjectResourceServiceModel[] | null;
};
export type UserSystemRoleModel = {
    UserSid?: string;
    UserEmail?: string | null;
    RoleName?: string | null;
    ProjectShortName?: string;
    SystemPermissionTypes?: string[] | null;
};
export type UserInfoModel = {
    Id?: string;
    KeycloakSid?: string | null;
    Name?: string | null;
    UserName?: string | null;
    Email?: string | null;
    SystemRoles?: UserSystemRoleModel[] | null;
};
export const {
    useGetApiAdministrationProjectQuery,
    useGetApiAdministrationProjectTagQuery,
    useGetApiAdministrationUserQuery,
    useGetApiAdministrationLocationQuery,
    useGetApiAdministrationLocationByIdQuery,
    useGetApiAdministrationProviderQuery,
    useGetApiAdministrationProviderByIdQuery,
    usePutApiAdministrationPromoteToProviderAdminMutation,
    usePutApiAdministrationResetToPlatformUserMutation,
    useWorkerDataQuery,
    useGetApiLocationQuery,
    usePostApiLocationMutation,
    usePutApiLocationMutation,
    useGetLocationQuery,
    useDeleteApiLocationByIdMutation,
    useGetApiOtherCountryQuery,
    useGetApiOtherStatisticsQuery,
    useGetApiProjectByProjectShortNameUsersQuery,
    useGetProjectQuery,
    usePostApiProjectMutation,
    usePutApiProjectMutation,
    usePutApiProjectByProjectShortNameUserAndUserSidAssignRoleIdMutation,
    useDeleteApiProjectByProjectShortNameMutation,
    usePutApiProjectByProjectShortNameProjectResourceAndProjectResourceNameMutation,
    useDeleteApiProjectByProjectShortNameProjectResourceAndProjectResourceNameMutation,
    usePutApiProjectByProjectShortNameUserAndUserSidMutation,
    useDeleteApiProjectByProjectShortNameUserAndUserSidMutation,
    usePutApiProjectByProjectShortNameProjectTagAndProjectTagIdMutation,
    useDeleteApiProjectByProjectShortNameProjectTagAndProjectTagIdMutation,
    useTemplatesInfoQuery,
    useExtendedProjectInfoQuery,
    useGetApiProjectResourceAssignmentQuery,
    usePostApiProjectResourceAssignmentMutation,
    usePutApiProjectResourceAssignmentMutation,
    useGetProjectResourceAssignmentQuery,
    useDeleteApiProjectResourceAssignmentByIdMutation,
    useGetApiProjectResourceQuery,
    usePostApiProjectResourceMutation,
    usePutApiProjectResourceMutation,
    useGetProjectResourceQuery,
    useDeleteApiProjectResourceByIdMutation,
    useGetApiProjectTagQuery,
    usePostApiProjectTagMutation,
    usePutApiProjectTagMutation,
    useGetProjectTagQuery,
    useDeleteApiProjectTagByIdMutation,
    useGetApiProviderQuery,
    usePostApiProviderMutation,
    usePutApiProviderMutation,
    useGetProviderQuery,
    useDeleteApiProviderByIdMutation,
    usePostApiPublicHandleByActionLinkMutation,
    useGetApiPublicRequestInfoByRequestIdQuery,
    useGetApiRequestsQuery,
    usePostApiRequestsMutation,
    useGetApiRequestsSentRequestsQuery,
    usePutApiRequestsByRequestIdAcceptMutation,
    usePutApiRequestsByRequestIdRejectMutation,
    useGetApiRoleQuery,
    usePostApiRoleMutation,
    usePutApiRoleMutation,
    useGetApiRoleByProjectShortNameQuery,
    useDeleteApiRoleByRoleNameMutation,
    useGetApiRolePermissionTypesQuery,
    useGetRoleQuery,
    useSyncDetailsQuery,
    useProjectsInfoQuery,
    useGetApiUserPreferencesQuery,
    usePutApiUserPreferencesMutation,
    useGetApiUserInfoQuery,
    useGetApiUserInfoExtendedQuery,
} = injectedRtkApi;
