import { createSlice } from '@reduxjs/toolkit';

interface DatasetOptionsType {
    searchText?: string | undefined;
    pagination?: {
        pageIndex: number;
        pageSize: number;
    };
    selectedRows?: any[];
}

interface OptionsType {
    searchText?: string | undefined;
    pagination?: {
        pageIndex: number;
        pageSize: number;
    };
}
interface initialStateType {
    projects: OptionsType;
    workflows: OptionsType;
    uploadDatasets: DatasetOptionsType;
    workflowDatasets: DatasetOptionsType;
    publicDatasets: DatasetOptionsType;
    jobscripts: DatasetOptionsType;
    locations: OptionsType;
    providers: OptionsType;
    resourceManagement: OptionsType;
    uploadTransferStatuses: OptionsType;
    downloadTransferStatuses: OptionsType;
    customHPCJobs: OptionsType;
    customHPCScriptWorkflows: OptionsType;
}

const projectsTableOptions = JSON.parse(localStorage.getItem('tableOptions') ?? '{}')?.projects;
const workflowsTableOptions = JSON.parse(localStorage.getItem('tableOptions') ?? '{}')?.workflows;
const uploadDatasetsTableOptions = JSON.parse(localStorage.getItem('tableOptions') ?? '{}')?.uploadDatasets;
const workflowDatasetsTableOptions = JSON.parse(localStorage.getItem('tableOptions') ?? '{}')?.workflowDatasets;
const publicDatasetsTableOptions = JSON.parse(localStorage.getItem('tableOptions') ?? '{}')?.publicDatasets;
const jobscriptsTableOptions = JSON.parse(localStorage.getItem('tableOptions') ?? '{}')?.jobscripts;
const locationsTableOptions = JSON.parse(localStorage.getItem('tableOptions') ?? '{}')?.locations;
const providersTableOptions = JSON.parse(localStorage.getItem('tableOptions') ?? '{}')?.providers;
const resourceManagementTableOptions = JSON.parse(localStorage.getItem('tableOptions') ?? '{}')?.resourceManagement;
const customHPCJobsTableOptions = JSON.parse(localStorage.getItem('tableOptions') ?? '{}')?.customHPCJobs;
const customHPCScriptWorkflowsTableOptions = JSON.parse(
    localStorage.getItem('tableOptions') ?? '{}'
)?.customHPCScriptWorkflows;

const uploadTransferStatusesTableOptions = JSON.parse(
    localStorage.getItem('tableOptions') ?? '{}'
)?.uploadTransferStatuses;
const downloadTransferStatusesTableOptions = JSON.parse(
    localStorage.getItem('tableOptions') ?? '{}'
)?.downloadTransferStatuses;

const initialState: initialStateType = {
    projects: {
        searchText: projectsTableOptions?.searchText ? projectsTableOptions?.searchText : undefined,
        pagination: projectsTableOptions?.pagination
            ? projectsTableOptions?.pagination
            : { pageIndex: 0, pageSize: 10 },
    },
    customHPCScriptWorkflows: {
        searchText: customHPCScriptWorkflowsTableOptions?.searchText
            ? customHPCScriptWorkflowsTableOptions?.searchText
            : undefined,
        pagination: customHPCScriptWorkflowsTableOptions?.pagination
            ? customHPCScriptWorkflowsTableOptions?.pagination
            : { pageIndex: 0, pageSize: 5 },
    },
    workflows: {
        searchText: workflowsTableOptions?.searchText ? workflowsTableOptions?.searchText : undefined,
        pagination: workflowsTableOptions?.pagination
            ? workflowsTableOptions?.pagination
            : { pageIndex: 0, pageSize: 10 },
    },
    uploadDatasets: {
        searchText: uploadDatasetsTableOptions?.searchText ? uploadDatasetsTableOptions?.searchText : undefined,
        pagination: uploadDatasetsTableOptions?.pagination
            ? uploadDatasetsTableOptions?.pagination
            : { pageIndex: 0, pageSize: 10 },
        selectedRows: [],
    },
    workflowDatasets: {
        searchText: workflowDatasetsTableOptions?.searchText ? workflowDatasetsTableOptions?.searchText : undefined,
        pagination: workflowDatasetsTableOptions?.pagination
            ? workflowDatasetsTableOptions?.pagination
            : { pageIndex: 0, pageSize: 10 },
        selectedRows: [],
    },
    publicDatasets: {
        searchText: publicDatasetsTableOptions?.searchText ? publicDatasetsTableOptions?.searchText : undefined,
        pagination: publicDatasetsTableOptions?.pagination
            ? publicDatasetsTableOptions?.pagination
            : { pageIndex: 0, pageSize: 10 },
        selectedRows: [],
    },
    jobscripts: {
        searchText: jobscriptsTableOptions?.searchText ? jobscriptsTableOptions?.searchText : undefined,
        pagination: jobscriptsTableOptions?.pagination
            ? jobscriptsTableOptions?.pagination
            : { pageIndex: 0, pageSize: 5 },
        selectedRows: [],
    },
    locations: {
        searchText: locationsTableOptions?.searchText ? locationsTableOptions?.searchText : undefined,
        pagination: locationsTableOptions?.pagination
            ? locationsTableOptions?.pagination
            : { pageIndex: 0, pageSize: 10 },
    },
    providers: {
        searchText: providersTableOptions?.searchText ? providersTableOptions?.searchText : undefined,
        pagination: providersTableOptions?.pagination
            ? providersTableOptions?.pagination
            : { pageIndex: 0, pageSize: 10 },
    },
    resourceManagement: {
        searchText: resourceManagementTableOptions?.searchText ? resourceManagementTableOptions?.searchText : undefined,
        pagination: resourceManagementTableOptions?.pagination
            ? resourceManagementTableOptions?.pagination
            : { pageIndex: 0, pageSize: 10 },
    },
    uploadTransferStatuses: {
        searchText: uploadTransferStatusesTableOptions?.searchText
            ? uploadTransferStatusesTableOptions?.searchText
            : undefined,
        pagination: uploadTransferStatusesTableOptions?.pagination
            ? uploadTransferStatusesTableOptions?.pagination
            : { pageIndex: 0, pageSize: 10 },
    },
    downloadTransferStatuses: {
        searchText: downloadTransferStatusesTableOptions?.searchText
            ? downloadTransferStatusesTableOptions?.searchText
            : undefined,
        pagination: downloadTransferStatusesTableOptions?.pagination
            ? downloadTransferStatusesTableOptions?.pagination
            : { pageIndex: 0, pageSize: 10 },
    },
    customHPCJobs: {
        searchText: customHPCJobsTableOptions?.searchText ? customHPCJobsTableOptions?.searchText : undefined,
        pagination: customHPCJobsTableOptions?.pagination
            ? customHPCJobsTableOptions?.pagination
            : { pageIndex: 0, pageSize: 10 },
    },
};

const tableOptionsSlice = createSlice({
    name: 'tableOptions',
    initialState,
    reducers: {
        setProjectsSearchText: (state, action) => {
            state.projects.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({ ...state, projects: { ...state.projects, searchText: action.payload } })
            );
        },
        setProjectsPagination: (state, action) => {
            state.projects.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    projects: { ...state.projects, pagination: action.payload },
                })
            );
        },
        setWorkflowsSearchText: (state, action) => {
            state.workflows.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({ ...state, workflows: { ...state.workflows, searchText: action.payload } })
            );
        },
        setWorkflowsPagination: (state, action) => {
            state.workflows.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    workflows: { ...state.workflows, pagination: action.payload },
                })
            );
        },
        setUploadDatasetsSearchText: (state, action) => {
            state.uploadDatasets.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    uploadDatasets: { ...state.uploadDatasets, searchText: action.payload },
                })
            );
        },
        setJobscriptsSearchText: (state, action) => {
            state.jobscripts.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    jobscripts: { ...state.jobscripts, searchText: action.payload },
                })
            );
        },
        setUploadDatasetsSelectedRows: (state, action) => {
            state.uploadDatasets.selectedRows = action.payload;
        },
        setWorkflowDatasetsSelectedRows: (state, action) => {
            state.workflowDatasets.selectedRows = action.payload;
        },
        setPublicDatasetsSelectedRows: (state, action) => {
            state.publicDatasets.selectedRows = action.payload;
        },

        setJobscriptsSelectedRows: (state, action) => {
            state.jobscripts.selectedRows = action.payload;
        },

        setUploadDatasetsPagination: (state, action) => {
            state.uploadDatasets.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    uploadDatasets: { ...state.uploadDatasets, pagination: action.payload },
                })
            );
        },
        setJobscriptsPagination: (state, action) => {
            state.jobscripts.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    jobscripts: { ...state.jobscripts, pagination: action.payload },
                })
            );
        },
        setWorkflowDatasetsSearchText: (state, action) => {
            state.workflowDatasets.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    workflowDatasets: { ...state.workflowDatasets, searchText: action.payload },
                })
            );
        },
        setWorkflowDatasetsPagination: (state, action) => {
            state.workflowDatasets.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    workflowDatasets: { ...state.workflowDatasets, pagination: action.payload },
                })
            );
        },
        setPublicDatasetsSearchText: (state, action) => {
            state.publicDatasets.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    publicDatasets: { ...state.publicDatasets, searchText: action.payload },
                })
            );
        },
        setPublicDatasetsPagination: (state, action) => {
            state.publicDatasets.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    publicDatasets: { ...state.publicDatasets, pagination: action.payload },
                })
            );
        },
        setLocationsSearchText: (state, action) => {
            state.locations.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({ ...state, locations: { ...state.locations, searchText: action.payload } })
            );
        },
        setLocationsPagination: (state, action) => {
            state.locations.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    locations: { ...state.locations, pagination: action.payload },
                })
            );
        },
        setProvidersSearchText: (state, action) => {
            state.providers.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({ ...state, providers: { ...state.providers, searchText: action.payload } })
            );
        },
        setProvidersPagination: (state, action) => {
            state.providers.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    providers: { ...state.providers, pagination: action.payload },
                })
            );
        },
        setResourceManagementSearchText: (state, action) => {
            state.resourceManagement.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    resourceManagement: { ...state.resourceManagement, searchText: action.payload },
                })
            );
        },
        setResourceManagementPagination: (state, action) => {
            state.resourceManagement.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    resourceManagement: { ...state.resourceManagement, pagination: action.payload },
                })
            );
        },
        setUploadTransferStatusesSearchText: (state, action) => {
            state.uploadTransferStatuses.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    uploadTransferStatuses: { ...state.uploadTransferStatuses, searchText: action.payload },
                })
            );
        },
        setUploadTransferStatusesPagination: (state, action) => {
            state.uploadTransferStatuses.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    uploadTransferStatuses: { ...state.uploadTransferStatuses, pagination: action.payload },
                })
            );
        },
        setcustomHPCScriptWorkflowsSearchText: (state, action) => {
            state.customHPCScriptWorkflows.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    customHPCScriptWorkflows: { ...state.customHPCScriptWorkflows, searchText: action.payload },
                })
            );
        },
        setcustomHPCScriptWorkflowsPagination: (state, action) => {
            state.customHPCScriptWorkflows.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    customHPCScriptWorkflows: { ...state.customHPCScriptWorkflows, pagination: action.payload },
                })
            );
        },
        setCustomHPCJobsSearchText: (state, action) => {
            state.customHPCJobs.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    customHPCJobs: { ...state.customHPCJobs, searchText: action.payload },
                })
            );
        },
        setCustomHPCJobsPagination: (state, action) => {
            state.customHPCJobs.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    customHPCJobs: { ...state.customHPCJobs, pagination: action.payload },
                })
            );
        },
        setDownloadTransferStatusesSearchText: (state, action) => {
            state.downloadTransferStatuses.searchText = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    downloadTransferStatuses: { ...state.downloadTransferStatuses, searchText: action.payload },
                })
            );
        },
        setDownloadTransferStatusesPagination: (state, action) => {
            state.downloadTransferStatuses.pagination = action.payload;
            localStorage.setItem(
                'tableOptions',
                JSON.stringify({
                    ...state,
                    downloadTransferStatuses: { ...state.downloadTransferStatuses, pagination: action.payload },
                })
            );
        },
    },
});

export const {
    setProjectsSearchText,
    setProjectsPagination,
    setUploadDatasetsPagination,
    setWorkflowsSearchText,
    setUploadDatasetsSearchText,
    setWorkflowDatasetsSearchText,
    setPublicDatasetsSearchText,
    setLocationsSearchText,
    setResourceManagementSearchText,
    setDownloadTransferStatusesSearchText,
    setUploadTransferStatusesSearchText,
    setDownloadTransferStatusesPagination,
    setLocationsPagination,
    setProvidersSearchText,
    setProvidersPagination,
    setPublicDatasetsPagination,
    setResourceManagementPagination,
    setUploadTransferStatusesPagination,
    setWorkflowDatasetsPagination,
    setWorkflowsPagination,
    setUploadDatasetsSelectedRows,
    setPublicDatasetsSelectedRows,
    setWorkflowDatasetsSelectedRows,
    setCustomHPCJobsSearchText,
    setCustomHPCJobsPagination,
    setJobscriptsSelectedRows,
    setJobscriptsPagination,
    setJobscriptsSearchText,
    setcustomHPCScriptWorkflowsPagination,
    setcustomHPCScriptWorkflowsSearchText,
} = tableOptionsSlice.actions;
export default tableOptionsSlice.reducer;
