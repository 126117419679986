import { createSlice } from '@reduxjs/toolkit';
import { AccessMode } from 'store/iRODS/iRODSApi';

const initialState = {
    upload: {
        file: null,
        title: '',
        project: '',
        access: 'project' as AccessMode,
        location: '',
        zone: '',
        enc: false,
        push_method: false,
        expand: false,
    },
    containerParameters: '',
    icon: { icon: null, base64Url: null },
    resourceType: 'Container',
    resourceTypeGeneral: 'Software',
    sizeError: '',
};

const containerUploadSlice = createSlice({
    name: 'containerUploadValues',
    initialState,
    reducers: {
        resetUpload: state => {
            state.upload.file = null;
            state.upload.access = 'project' as AccessMode;
            state.upload.title = '';
            state.upload.enc = false;
            state.upload.project = '';
            state.upload.zone = '';
            state.upload.expand = false;
            state.upload.push_method = false;
            state.containerParameters = '';
            state.upload.location = '';
            state.icon = { icon: null, base64Url: null };
        },
        setFile: (state, action) => {
            state.upload.file = action.payload;
        },
        resetFile: state => {
            state.upload.file = null;
        },
        setLocation: (state, action) => {
            state.upload.location = action.payload;
        },
        resetLocation: state => {
            state.upload.location = '';
        },
        setIcon: (state, action) => {
            state.icon = action.payload;
        },
        setSizeError: (state, action) => {
            state.sizeError = action.payload;
        },
        resetIcon: state => {
            state.icon = { icon: null, base64Url: null };
        },
        setTitle: (state, action) => {
            state.upload.title = action.payload;
        },
        setProject: (state, action) => {
            state.upload.project = action.payload;
        },
        setAccess: (state, action) => {
            state.upload.access = action.payload;
        },
        setZone: (state, action) => {
            state.upload.zone = action.payload;
        },
        setEnc: (state, action) => {
            state.upload.enc = action.payload;
        },
        setPushMethod: (state, action) => {
            state.upload.push_method = action.payload;
        },
        setExpand: (state, action) => {
            state.upload.expand = action.payload;
        },
        setResourceType: (state, action) => {
            state.resourceType = action.payload;
        },
        setResourceTypeGeneral: (state, action) => {
            state.resourceTypeGeneral = action.payload;
        },

        setContainerParameters: (state, action) => {
            state.containerParameters = action.payload;
        },
    },
});

export const {
    setFile,
    setTitle,
    setProject,
    setAccess,
    setZone,
    setEnc,
    setIcon,
    setPushMethod,
    setExpand,
    setSizeError,
    resetUpload,
    resetIcon,
    resetFile,
    setResourceType,
    setResourceTypeGeneral,
    setContainerParameters,
    resetLocation,
    setLocation,
} = containerUploadSlice.actions;
export default containerUploadSlice.reducer;
