import { createSlice } from '@reduxjs/toolkit';
import { DatasetContent } from 'store/iRODS/iRODSApi';

interface initialStateType {
    openFolders: DatasetContent[];
    requests: { node: DatasetContent; requestId: string; open: boolean; isLoading: boolean }[];
}
const initialState: initialStateType = {
    openFolders: [],
    requests: [],
};

const filelistSlice = createSlice({
    name: 'filelist',
    initialState,
    reducers: {
        setOpenFolders: (state, action) => {
            state.openFolders = action.payload;
        },

        resetOpenFolders: state => {
            state.openFolders = [];
        },
        addRequest: (state, action) => {
            state.requests = [...state.requests, action.payload];
        },
        setRequests: (state, action) => {
            state.requests = action.payload;
        },

        resetRequests: state => {
            state.requests = [];
        },
        setRequestOpen: (state, action) => {
            const { requestId, open } = action.payload;
            const requestToUpdate = state.requests.find(req => req.requestId === requestId);
            if (requestToUpdate) {
                requestToUpdate.open = open;
            }
        },
        deleteRequest: (state, action) => {
            const requestIdToDelete = action.payload;
            state.requests = state.requests.filter(req => req.requestId !== requestIdToDelete);
        },
    },
});

export const {
    setOpenFolders,
    resetOpenFolders,
    addRequest,
    setRequests,
    resetRequests,
    setRequestOpen,
    deleteRequest,
} = filelistSlice.actions;
export default filelistSlice.reducer;
