import { Theme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import Profile from './Profile';
import DownloadResultsNotifications from './downloadResultsNotifications/downloadResultsNotifications';
import GlobalFilter from './globalFilter/globalFilter';
import { LinearProgress } from '@mui/material';
import WorkflowExecutionNotifications from './wonkflowExecutionNotifications/wonkflowExecutionNotifications';
import { useGetProjectQuery } from 'store/userOrg/enhancedUserOrgApi';
import AccessRequestNotifications from './accessRequests/accessRequestNotifications';

const HeaderContent = () => {
    const { data: projects, isLoading: projectsIsLoading } = useGetProjectQuery({});
    const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const justifyContentString = downLG ? 'space-between' : 'flex-end';

    return (
        <>
            <Box marginLeft={10}> {projectsIsLoading ? <LinearProgress /> : <GlobalFilter projects={projects} />}</Box>
            <Box sx={{ display: 'flex', justifyContent: justifyContentString, width: '100%' }}>
                <Box sx={{ display: 'inline-flex' }}>
                    <DownloadResultsNotifications />
                    <WorkflowExecutionNotifications />
                    <AccessRequestNotifications />
                </Box>
                <Profile />
            </Box>
        </>
    );
};

export default HeaderContent;
