import { Link, useLocation, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import MainCard from 'components/MainCard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, styled } from '@mui/system';
import { workflowsDashboardPath } from 'routes/paths';
import { FormattedMessage } from 'react-intl';
import useTranslationKeyExist from 'hooks/useTranslationKeyExist';
import { useGetMetadataQuery } from 'store/iRODS/injectedIRODSApi';
import { useGetApiProjectResourceQuery } from 'store/userOrg/enhancedUserOrgApi';
import { useEffect, useState } from 'react';
import useCheckPermission from 'hooks/useCheckPermission';
import { permissions } from 'helpers/data/permissions';

const StyledNoUnderlineLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.primary.main,
}));

const Breadcrumbs = ({}) => {
    const checkPerms = useCheckPermission();
    const { dataSetId, resourceId } = useParams();
    const [skipFetchResources, setSkipFetchResources] = useState(true);

    useEffect(() => {
        checkPerms(permissions.resList, '') && setSkipFetchResources(false);
    }, []);

    const { data: metadata } = useGetMetadataQuery({ metadataQuery: {} });
    const { data: resources } = useGetApiProjectResourceQuery({}, { skip: skipFetchResources });

    const resourceName = resources?.find(resource => resource.Id === resourceId)?.Name;
    const datasetTitle =
        dataSetId && metadata?.find(item => item.location.internalID === dataSetId)?.metadata?.title?.[0];

    const theme = useTheme();
    const location = useLocation();
    const keyExists = useTranslationKeyExist();
    const iconSX = {
        marginRight: theme.spacing(0.75),
        marginTop: `-${theme.spacing(0.25)}`,
        width: '0.5rem',
        height: '0.5rem',
        color: theme.palette.secondary.main,
    };

    let items = location.pathname.split('/');
    items = [...items].filter(e => String(e).trim());
    const params = Object.values(useParams());

    const getAllBefore = (current: string) => {
        let i = [...items].indexOf(current);
        return i > -1 ? [...items].slice(0, i) : [];
    };

    return (
        <MainCard sx={{ mb: 0 }} shadow='none'>
            <Grid container direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'} spacing={1}>
                <Grid item>
                    <MuiBreadcrumbs aria-label='breadcrumb' maxItems={8} separator={false}>
                        {items[items.length - 1] !== workflowsDashboardPath() ? (
                            <StyledNoUnderlineLink to={workflowsDashboardPath()}>
                                <Typography variant='h6' fontWeight='bold'>
                                    Home
                                </Typography>
                            </StyledNoUnderlineLink>
                        ) : (
                            <Typography variant='h6'>Home</Typography>
                        )}
                        {items.map(item => (
                            <Box key={item}>
                                {(item !== '' && params.includes(decodeURI(item))) ||
                                items[items.length - 1] === item ? (
                                    dataSetId === item || resourceId === item ? (
                                        <Typography variant='h6'>
                                            <ArrowForwardIosIcon sx={iconSX} />
                                            {datasetTitle ?? resourceName ?? item}
                                        </Typography>
                                    ) : (
                                        <Typography variant='h6'>
                                            <ArrowForwardIosIcon sx={iconSX} />
                                            {keyExists(item) ? <FormattedMessage id={item} /> : item}
                                        </Typography>
                                    )
                                ) : (
                                    <StyledNoUnderlineLink to={`${getAllBefore(item).join('/')}/${item}`}>
                                        <Typography variant='h6' fontWeight='bold'>
                                            <ArrowForwardIosIcon sx={iconSX} />
                                            {keyExists(item) ? <FormattedMessage id={item} /> : item}
                                        </Typography>
                                    </StyledNoUnderlineLink>
                                )}
                            </Box>
                        ))}
                    </MuiBreadcrumbs>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default Breadcrumbs;
