import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    containerFilterValue: string | undefined;
}

const initialState: initialStateProps = {
    containerFilterValue: undefined,
};

const ContainersFilterSlice = createSlice({
    name: 'containersFilter',
    initialState,
    reducers: {
        setContainersFilterValue: (state, action) => {
            state.containerFilterValue = action.payload;
        },

        resetContainersFilterValue: state => {
            state.containerFilterValue = undefined;
        },
    },
});

export const { setContainersFilterValue, resetContainersFilterValue } = ContainersFilterSlice.actions;
export default ContainersFilterSlice.reducer;
