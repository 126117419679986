import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface initialStateType {
    requestId: string | undefined;
    datasetId: string | undefined;
}

const initialState: initialStateType = {
    requestId: undefined,
    datasetId: undefined,
};

const jobscriptEditSlice = createSlice({
    name: 'jobscriptEdit',
    initialState,
    reducers: {
        setJobscriptEdit: (state, action: PayloadAction<{ requestId: string; datasetId: string }>) => {
            const { requestId, datasetId } = action.payload;
            state.datasetId = datasetId;
            state.requestId = requestId;
        },
        resetJobscriptEdit: state => {
            state.requestId = undefined;
            state.datasetId = undefined;
        },
    },
});

export const { setJobscriptEdit, resetJobscriptEdit } = jobscriptEditSlice.actions;
export default jobscriptEditSlice.reducer;
