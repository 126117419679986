import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// types
import { GuardProps } from 'types/auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // we got 401, then we show the page for not logged in users
        if (!isLoggedIn) {
            navigate('login', {
                state: {
                    from: location.pathname,
                },
                replace: true,
            });
        }
    }, [isLoggedIn, navigate, location]);

    return children;
};

export default AuthGuard;
