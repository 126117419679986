import { createSlice } from '@reduxjs/toolkit';
import { CommandTemplate } from 'store/userOrg/userOrgApi';

interface initialStateProps {
    filterCommandTemplateName: string;
    filteredTemplates: CommandTemplate[] | undefined;
}

const initialState: initialStateProps = {
    filterCommandTemplateName: '',
    filteredTemplates: undefined,
};

const HPCApplicationsFilterSlice = createSlice({
    name: 'HPCApplicationsFilter',
    initialState,
    reducers: {
        setFilteredTemplates: (state, action) => {
            state.filteredTemplates = action.payload;
        },
        resetFilteredTemplates: state => {
            state.filteredTemplates = undefined;
        },
        setFilterCommandTemplateName: (state, action) => {
            state.filterCommandTemplateName = action.payload;
        },
        resetFilterCommandTemplateName: state => {
            state.filterCommandTemplateName = '';
        },
    },
});

export const {
    resetFilterCommandTemplateName,
    setFilterCommandTemplateName,
    setFilteredTemplates,
    resetFilteredTemplates,
} = HPCApplicationsFilterSlice.actions;
export default HPCApplicationsFilterSlice.reducer;
