import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import DrawerHeaderStyled from './DrawerFooterStyled';
import FooterLogo from 'components/logo/footerLogo';
import useConfig from 'hooks/useConfig';
import { MenuOrientation } from 'types/config';

interface Props {
    open: boolean;
}

const DrawerFooter = ({ open }: Props) => {
    const theme = useTheme();
    const downLG = useMediaQuery(theme.breakpoints.down('lg'));

    const { menuOrientation } = useConfig();
    const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

    return (
        <DrawerHeaderStyled
            theme={theme}
            open={open}
            sx={{
                minHeight: isHorizontal ? 'unset' : '60px',
                width: isHorizontal ? { xs: '100%', lg: '424px' } : 'inherit',
                paddingTop: isHorizontal ? { xs: '10px', lg: '0' } : '8px',
                paddingBottom: isHorizontal ? { xs: '18px', lg: '0' } : '8px',
                paddingLeft: isHorizontal ? { xs: '24px', lg: '0' } : open ? '24px' : 0,
            }}>
            <FooterLogo isIcon={!open} />
        </DrawerHeaderStyled>
    );
};

export default DrawerFooter;
