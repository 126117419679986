import { ReactNode } from 'react';
import { Box, Grid } from '@mui/material';
import IntroCard from './IntroCard';
import AuthBackground from 'assets/images/intro/IntroBackground';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import HeaderLogo from 'components/logo/headerLogo';

interface Props {
    children: ReactNode;
}

const IntroWrapper = ({ children }: Props) => (
    <Box sx={{ minHeight: '100vh' }}>
        <AuthBackground />
        <Grid
            container
            direction='column'
            justifyContent='flex-end'
            sx={{
                minHeight: '100vh',
            }}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} sx={{ ml: 3 }}>
                        <HeaderLogo disabled />
                    </Grid>
                    <Grid item xs={6} sx={{ ml: -3, mt: 2 }}>
                        <Box display='flex' justifyContent='flex-end' sx={{ mr: 3 }}>
                            <Link
                                reloadDocument={true}
                                to={`${window.location.protocol}//${
                                    window.location.host
                                }/oauth2/start?rd=${encodeURIComponent(window.location.href)}`}>
                                <Button variant='outlined'>Login</Button>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        minHeight: { xs: 'calc(100vh - 210px)', sm: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' },
                    }}>
                    <Grid item>
                        <IntroCard>{children}</IntroCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Box>
);

export default IntroWrapper;
