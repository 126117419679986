import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { DRAWER_WIDTH } from 'config';
import { dispatch, useSelector } from 'store';
import { openDrawer } from 'store/reducers/menu';
import DrawerFooter from './DrawerFooter';

interface Props {
    window?: () => Window;
}

const MainDrawer = ({ window }: Props) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    const menu = useSelector(state => state.menu);
    const { drawerOpen } = menu;
    const container = window !== undefined ? () => window().document.body : undefined;

    const drawerContent = useMemo(() => <DrawerContent />, []);
    const drawerHeader = useMemo(() => <DrawerHeader open={drawerOpen} />, [drawerOpen]);
    const drawerFooter = useMemo(() => <DrawerFooter open={drawerOpen} />, [drawerOpen]);

    return (
        <Box component='nav' sx={{ flexShrink: { md: 0 }, zIndex: 1200 }} aria-label='mailbox folders'>
            {!matchDownMD ? (
                <MiniDrawerStyled variant='permanent' open={drawerOpen}>
                    {drawerHeader}
                    {drawerContent}
                    {drawerFooter}
                </MiniDrawerStyled>
            ) : (
                <Drawer
                    container={container}
                    variant='temporary'
                    open={drawerOpen}
                    onClose={() => dispatch(openDrawer(!drawerOpen))}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: DRAWER_WIDTH,
                            borderRight: `1px solid ${theme.palette.divider}`,
                            backgroundImage: 'none',
                            boxShadow: 'inherit',
                        },
                    }}>
                    {drawerHeader}
                    {drawerContent}
                    {drawerFooter}
                </Drawer>
            )}
        </Box>
    );
};

export default MainDrawer;
