import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';

import useConfig from 'hooks/useConfig';
import { useGetApiUserPreferencesQuery, usePutApiUserPreferencesMutation } from 'store/userOrg/enhancedUserOrgApi';

const ThemeSelection = () => {
    const { data: preferences } = useGetApiUserPreferencesQuery();
    const [putPreferences] = usePutApiUserPreferencesMutation();
    const { presetColor } = useConfig();

    const preferencesObj = preferences ? JSON.parse(preferences) : {};

    const handleRadioChange = event => {
        putPreferences({
            body: {
                ...preferencesObj,
                presetColor: event.target.value,
            },
        });
    };

    return (
        <FormControl>
            <FormLabel>Theme</FormLabel>
            <RadioGroup value={presetColor} onChange={handleRadioChange}>
                <Grid container>
                    <Grid item xs={6}>
                        <FormControlLabel value='default' control={<Radio />} label='Default' />
                        <FormControlLabel value='theme1' control={<Radio />} label='Theme1' />
                        <FormControlLabel value='theme2' control={<Radio />} label='Theme2' />
                        <FormControlLabel value='theme3' control={<Radio />} label='Theme3' />
                        <FormControlLabel value='theme4' control={<Radio />} label='Theme4' />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel value='theme5' control={<Radio />} label='Theme5' />
                        <FormControlLabel value='theme6' control={<Radio />} label='Theme6' />
                        <FormControlLabel value='theme7' control={<Radio />} label='Theme7' />
                        <FormControlLabel value='theme8' control={<Radio />} label='Theme8' />
                    </Grid>
                </Grid>
            </RadioGroup>
        </FormControl>
    );
};

export default ThemeSelection;
