import { userOrg } from './userOrgApi';

export const enhancedUserOrgApi = userOrg.enhanceEndpoints({
    addTagTypes: [
        'GetProject',
        'GetApiProjectTag',
        'GetApiRequests',
        'GetApiProjectResource',
        'GetApiProjectByProjectShortNameUsers',
        'GetApiUserInfo',
        'GetApiAdministrationProvider',
        'GetApiAdministrationLocation',
        'GetApiProjectResourceAssignment',
        'GetProvider',
        'GetApiUserPreferences',
        'GetApiOtherStatistics',
    ],
    endpoints: {
        getApiOtherStatistics: {
            providesTags: ['GetApiOtherStatistics'],
        },
        getProject: {
            providesTags: ['GetProject'],
        },
        getApiProjectTag: {
            providesTags: ['GetApiProjectTag'],
        },
        getApiRequests: {
            providesTags: ['GetApiRequests'],
        },
        getApiProjectResource: {
            providesTags: ['GetApiProjectResource'],
        },

        getApiUserInfo: {
            providesTags: ['GetApiUserInfo'],
        },
        getApiProjectByProjectShortNameUsers: {
            providesTags: ['GetApiProjectByProjectShortNameUsers'],
        },
        getApiAdministrationProvider: {
            providesTags: ['GetApiAdministrationProvider'],
        },
        getProvider: { providesTags: ['GetProvider'] },
        getApiAdministrationLocation: {
            providesTags: ['GetApiAdministrationLocation'],
        },
        getApiProjectResourceAssignment: {
            providesTags: ['GetApiProjectResourceAssignment'],
        },
        getApiUserPreferences: {
            providesTags: ['GetApiUserPreferences'],
        },
        postApiProject: {
            invalidatesTags: ['GetProject', 'GetApiUserInfo', 'GetApiOtherStatistics'],
        },
        putApiProject: {
            invalidatesTags: ['GetProject', 'GetApiOtherStatistics'],
        },
        deleteApiProjectByProjectShortName: {
            invalidatesTags: ['GetProject', 'GetApiOtherStatistics'],
        },
        putApiProjectByProjectShortNameProjectResourceAndProjectResourceName: {
            invalidatesTags: ['GetProject', 'GetApiProjectResource', 'GetApiOtherStatistics'],
        },
        deleteApiProjectByProjectShortNameProjectResourceAndProjectResourceName: {
            invalidatesTags: ['GetProject', 'GetApiProjectResource', 'GetApiOtherStatistics'],
        },
        deleteApiProjectTagById: {
            invalidatesTags: ['GetProject', 'GetApiProjectTag', 'GetApiOtherStatistics'],
        },
        putApiProjectByProjectShortNameProjectTagAndProjectTagId: {
            invalidatesTags: ['GetProject', 'GetApiProjectTag'],
        },
        postApiProjectTag: {
            invalidatesTags: ['GetProject', 'GetApiProjectTag'],
        },
        putApiProjectTag: {
            invalidatesTags: ['GetProject', 'GetApiProjectTag'],
        },
        putApiRequestsByRequestIdReject: {
            invalidatesTags: ['GetProject', 'GetApiProjectByProjectShortNameUsers', 'GetApiRequests'],
        },
        putApiRequestsByRequestIdAccept: {
            invalidatesTags: ['GetProject', 'GetApiProjectByProjectShortNameUsers', 'GetApiRequests'],
        },

        postApiProjectResource: {
            invalidatesTags: ['GetProject', 'GetApiProjectResource', 'GetApiOtherStatistics'],
        },
        deleteApiProjectByProjectShortNameUserAndUserSid: {
            invalidatesTags: ['GetProject', 'GetApiProjectByProjectShortNameUsers', 'GetApiOtherStatistics'],
        },
        putApiProjectByProjectShortNameUserAndUserSidAssignRoleId: {
            invalidatesTags: ['GetApiProjectByProjectShortNameUsers'],
        },
        deleteApiProjectByProjectShortNameProjectTagAndProjectTagId: {
            invalidatesTags: ['GetProject', 'GetApiProjectTag'],
        },
        postApiLocation: {
            invalidatesTags: ['GetApiAdministrationLocation'],
        },
        putApiLocation: {
            invalidatesTags: ['GetApiAdministrationLocation'],
        },
        deleteApiLocationById: {
            invalidatesTags: ['GetApiAdministrationLocation'],
        },
        postApiRequests: {
            invalidatesTags: ['GetApiProjectTag'],
        },
        postApiProjectResourceAssignment: {
            invalidatesTags: ['GetApiProjectResourceAssignment'],
        },
        putApiProjectResourceAssignment: {
            invalidatesTags: ['GetApiProjectResourceAssignment'],
        },
        deleteApiProjectResourceAssignmentById: {
            invalidatesTags: ['GetApiProjectResourceAssignment'],
        },
        putApiProjectResource: {
            invalidatesTags: ['GetApiProjectResource', 'GetApiOtherStatistics'],
        },
        deleteApiProjectResourceById: {
            invalidatesTags: ['GetApiProjectResource', 'GetApiOtherStatistics'],
        },
        putApiProvider: {
            invalidatesTags: ['GetApiAdministrationProvider', 'GetApiAdministrationLocation', 'GetProvider'],
        },
        postApiProvider: {
            invalidatesTags: ['GetApiAdministrationProvider', 'GetApiAdministrationLocation', 'GetProvider'],
        },
        deleteApiProviderById: {
            invalidatesTags: ['GetApiAdministrationProvider', 'GetApiAdministrationLocation', 'GetProvider'],
        },
        putApiUserPreferences: {
            invalidatesTags: ['GetApiUserPreferences'],
        },
    },
});

export const {
    useGetApiAdministrationProjectQuery,
    useGetApiAdministrationProjectTagQuery,
    useGetApiAdministrationUserQuery,
    useGetApiAdministrationLocationQuery,
    useGetApiAdministrationLocationByIdQuery,
    useGetApiAdministrationProviderQuery,
    useGetApiAdministrationProviderByIdQuery,
    usePutApiAdministrationPromoteToProviderAdminMutation,
    usePutApiAdministrationResetToPlatformUserMutation,
    useGetApiLocationQuery,
    usePostApiLocationMutation,
    usePutApiLocationMutation,
    useGetLocationQuery,
    useDeleteApiLocationByIdMutation,
    useGetApiOtherCountryQuery,
    useGetApiProjectByProjectShortNameUsersQuery,
    useGetProjectQuery,
    usePostApiProjectMutation,
    usePutApiProjectMutation,
    usePutApiProjectByProjectShortNameUserAndUserSidAssignRoleIdMutation,
    useDeleteApiProjectByProjectShortNameMutation,
    usePutApiProjectByProjectShortNameProjectResourceAndProjectResourceNameMutation,
    useDeleteApiProjectByProjectShortNameProjectResourceAndProjectResourceNameMutation,
    usePutApiProjectByProjectShortNameUserAndUserSidMutation,
    useDeleteApiProjectByProjectShortNameUserAndUserSidMutation,
    usePutApiProjectByProjectShortNameProjectTagAndProjectTagIdMutation,
    useDeleteApiProjectByProjectShortNameProjectTagAndProjectTagIdMutation,
    useTemplatesInfoQuery,
    useExtendedProjectInfoQuery,
    useGetApiProjectResourceAssignmentQuery,
    usePostApiProjectResourceAssignmentMutation,
    usePutApiProjectResourceAssignmentMutation,
    useGetProjectResourceAssignmentQuery,
    useDeleteApiProjectResourceAssignmentByIdMutation,
    useGetApiProjectResourceQuery,
    usePostApiProjectResourceMutation,
    usePutApiProjectResourceMutation,
    useGetProjectResourceQuery,
    useDeleteApiProjectResourceByIdMutation,
    useGetApiProjectTagQuery,
    usePostApiProjectTagMutation,
    usePutApiProjectTagMutation,
    useGetProjectTagQuery,
    useDeleteApiProjectTagByIdMutation,
    useGetApiOtherStatisticsQuery,
    useGetApiProviderQuery,
    usePostApiProviderMutation,
    usePutApiProviderMutation,
    useGetProviderQuery,
    useDeleteApiProviderByIdMutation,
    useGetApiRequestsQuery,
    usePostApiRequestsMutation,
    usePutApiRequestsByRequestIdAcceptMutation,
    usePutApiRequestsByRequestIdRejectMutation,
    useGetApiRoleQuery,
    usePostApiRoleMutation,
    usePutApiRoleMutation,
    useGetApiRoleByProjectShortNameQuery,
    useDeleteApiRoleByRoleNameMutation,
    useGetApiRolePermissionTypesQuery,
    useGetRoleQuery,
    useSyncDetailsQuery,
    useProjectsInfoQuery,
    useGetApiUserPreferencesQuery,
    usePutApiUserPreferencesMutation,
    useGetApiUserInfoQuery,
    useGetApiUserInfoExtendedQuery,
    useGetApiPublicRequestInfoByRequestIdQuery,
    usePostApiPublicHandleByActionLinkMutation,
} = enhancedUserOrgApi;
