import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import MainCard from 'components/MainCard';

const TextBox = ({ label }: { label: string }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <MainCard content={false} sx={{ width: { xs: 70, sm: 80 } }}>
            <Stack justifyContent='center' alignItems='center'>
                <Box sx={{ py: 1.75 }}>
                    <Typography color='primary.main' variant={matchDownSM ? 'h4' : 'h2'}>
                        {label}
                    </Typography>
                </Box>
            </Stack>
        </MainCard>
    );
};

const text = ['C', 'O', 'M', 'I', 'N', 'G', ' ', 'S', 'O', 'O', 'N'];

function ComingSoon() {
    return (
        <MainCard>
            <Grid
                container
                spacing={4}
                direction='column'
                alignItems='center'
                justifyContent='center'
                sx={{ minHeight: '50vh', py: 2 }}>
                <Grid item xs={12} sx={{ width: { xs: '95%', md: '40%' } }}>
                    <Stack direction='row' alignItems='center' justifyContent='center' spacing={{ xs: 1, sm: 2 }}>
                        {text.map((item, index) =>
                            item === ' ' ? <Box sx={{ pl: 1, pr: 1 }} /> : <TextBox label={item} key={index} />
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={1} justifyContent='center' alignItems='center' sx={{ mt: -2 }}>
                        <Typography align='center' variant='h1' color='textSecondary'>
                            Something new is on its way
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </MainCard>
    );
}

export default ComingSoon;
