import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    projectShortName: localStorage.getItem('selectedContainersTabProject')
        ? localStorage.getItem('selectedContainersTabProject')
        : undefined,
};

const selectedContainersTabProjectSlice = createSlice({
    name: 'selectedContainersTabProject',
    initialState,
    reducers: {
        setSelectedContainersTabProject: (state, action) => {
            state.projectShortName = action.payload;
            localStorage.setItem('selectedContainersTabProject', action.payload);
        },

        resetSelectedContainersTabProject: state => {
            state.projectShortName = undefined;
            localStorage.removeItem('selectedContainersTabProject');
        },
    },
});

export const { resetSelectedContainersTabProject, setSelectedContainersTabProject } =
    selectedContainersTabProjectSlice.actions;
export default selectedContainersTabProjectSlice.reducer;
