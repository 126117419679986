import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { userOrgApi } from 'store/userOrg/userOrgSlice';

const showError = (errMessage: string, id: string) => {
    toast.error(errMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: id,
        autoClose: 3000,
        closeOnClick: true,
    });
};

export const userOrgApiQueryErrorLogger: Middleware = () => next => action => {
    if (action.type.split('/')[0] === userOrgApi.reducerPath && isRejectedWithValue(action)) {
        const firstErrorKey = action?.payload?.data?.Errors && Object.keys(action?.payload?.data?.Errors)?.[0];

        const errorMessage = (errorCode: number) =>
            firstErrorKey
                ? action.payload.data.Errors[firstErrorKey]
                : action?.payload?.data?.Title
                ? action.payload.data.Title
                : action?.payload?.data?.errors
                ? action.payload.data.errors
                : `Error code: ${errorCode}`;

        switch (action?.payload?.originalStatus || action?.payload?.status) {
            case 400:
                console.warn(`Type: ${action?.type}`);
                console.warn(`We got a rejected action in API! (ERROR CODE 400) - ${action?.meta?.arg?.endpointName}`);
                console.warn(action?.payload);
                showError(errorMessage(400), `${action?.type}-400-${action?.meta?.requestId}`);
                break;

            case 401:
                console.warn(`Type: ${action.type}`);
                console.warn(`We got a rejected action in API! (ERROR CODE 401) - ${action.meta.arg.endpointName}`);
                console.warn(action.payload);
                showError(errorMessage(401), `${action.type}-401-${action.meta.requestId}`);
                location.replace(
                    `${window.location.protocol}//${window.location.host}/oauth2/start?rd=${encodeURIComponent(
                        window.location.href
                    )}`
                );
                break;

            case 403:
                console.warn(`Type: ${action.type}`);
                console.warn(`We got a rejected action in API! (ERROR CODE 403) - ${action.meta.arg.endpointName}`);
                console.warn(action.payload);
                break;

            case 404:
                console.warn(`Type: ${action.type}`);
                console.warn(`We got a rejected action in API! (ERROR CODE 404) - ${action.meta.arg.endpointName}`);
                console.warn(action.payload);
                showError(errorMessage(404), `${action.type}-404-${action.meta.requestId}`);
                break;

            case 409:
                console.warn(`Type: ${action.type}`);
                console.warn(`We got a rejected action in API! (ERROR CODE 409) - ${action.meta.arg.endpointName}`);
                console.warn(action.payload);
                showError(errorMessage(409), `${action.type}-409-${action.meta.requestId}`);
                break;

            default:
                console.warn(`Type: ${action.type}`);
                console.warn(`Internal API ERROR! (500) - ${action.meta.arg.endpointName}`);
                console.warn(action.payload);
                showError(`${errorMessage(500)}`, `${action.type}-500-${action.meta.requestId}`);
                break;
        }
    }

    return next(action);
};
