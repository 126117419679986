import lexisLogo from './../../../assets/images/intro/LEXIS_The_Platform_RGB_transparent.png';
import euroHPCLogo from './../../../assets/images/intro/euroHPC_logo.png';

const logo = process.env.REACT_APP_ENV === 'EUROFPDEMO' ? euroHPCLogo : lexisLogo;

const HeaderLogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
    return <img src={logo} alt='Lexis' width='200' />;
};

export default HeaderLogoMain;
