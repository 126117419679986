import { useRef, useState, ReactNode, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Grid,
    Paper,
    Popper,
    Stack,
    Tab,
    Tabs,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';

import ProfileTab from './ProfileTab';
import Avatar from 'components/@extended/Avatar';
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import IconButton from 'components/@extended/IconButton';
import useAuth from 'hooks/useAuth';
import avatar1 from 'assets/images/users/avatar-1.png';
import { ThemeMode } from 'types/config';
import { useGetApiUserInfoQuery } from 'store/userOrg/enhancedUserOrgApi';
import ThemeSettings from './themeSettings/themeSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

interface TabPanelProps {
    children?: ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`profile-tabpanel-${index}`}
            aria-labelledby={`profile-tab-${index}`}
            {...other}>
            {value === index && children}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `profile-tab-${index}`,
        'aria-controls': `profile-tabpanel-${index}`,
    };
}

const Profile = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { logout } = useAuth();
    const handleLogout = async () => {
        try {
            await logout();
            navigate(`/login`, {
                state: {
                    from: '',
                },
            });
        } catch (err) {
            console.error(err);
        }
    };

    const anchorRef = useRef<any>(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const { data: user } = useGetApiUserInfoQuery();

    const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }} id='userSettings'>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1,
                    '&:hover': {
                        bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter',
                    },
                    '&:focus-visible': {
                        outline: `2px solid ${theme.palette.secondary.dark}`,
                        outlineOffset: 2,
                    },
                }}
                aria-label='open profile'
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}>
                <Stack direction='row' spacing={2} alignItems='center' sx={{ p: 0.5 }}>
                    <Avatar alt='profile user' src={avatar1} size='xs' />
                    {!isMobile && <Typography variant='subtitle1'>{user?.Name}</Typography>}
                </Stack>
            </ButtonBase>
            <Popper
                placement='bottom-end'
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9],
                            },
                        },
                    ],
                }}>
                {({ TransitionProps }) => (
                    <Transitions type='grow' position='top-right' in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: 290,
                                minWidth: 240,
                                maxWidth: 290,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 250,
                                },
                            }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard elevation={0} border={false} content={false}>
                                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                                        <Grid container justifyContent='space-between' alignItems='center'>
                                            <Grid item>
                                                <Stack direction='row' spacing={1.25} alignItems='center'>
                                                    <Avatar
                                                        alt='profile user'
                                                        src={avatar1}
                                                        sx={{ width: 32, height: 32 }}
                                                    />
                                                    <Stack>
                                                        <Typography variant='h6'>{user?.Name}</Typography>
                                                        <Typography variant='body2' color='textSecondary'>
                                                            {user?.Email}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title='Logout'>
                                                    <IconButton
                                                        size='large'
                                                        sx={{ color: 'text.primary' }}
                                                        onClick={handleLogout}>
                                                        <LogoutIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Box
                                        textAlign='center'
                                        overflow='auto'
                                        maxHeight='60vh'
                                        sx={{
                                            '&::-webkit-scrollbar': {
                                                width: '12px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                background: 'transparent',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                background: '#888',
                                                borderRadius: '6px',
                                            },
                                        }}>
                                        <ThemeSettings />
                                    </Box>

                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs
                                            variant='fullWidth'
                                            value={value}
                                            onChange={handleChange}
                                            aria-label='profile tabs'>
                                            <Tab
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    textTransform: 'capitalize',
                                                }}
                                                icon={<PersonIcon style={{ marginBottom: 0, marginRight: '10px' }} />}
                                                label='Profile'
                                                {...a11yProps(0)}
                                            />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0} dir={theme.direction}>
                                        <ProfileTab setOpen={setOpen} handleLogout={handleLogout} />
                                    </TabPanel>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Profile;
