import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types/menu';
import { locationsPath, providersPath, resourceManagementPath, usersManagementPath } from 'routes/paths';
import { permissions } from 'helpers/data/permissions';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const icons = {
    GpsFixedIcon,
    AccountBalanceIcon,
    ManageAccountsIcon,
    BorderColorIcon,
};

const administrationManagement: NavItemType = {
    id: 'administrationManagement',
    title: <FormattedMessage id='administrationManagement' />,
    type: 'group',
    permissions: [permissions.locList, permissions.resList],

    children: [
        {
            id: 'usersManagement',
            title: <FormattedMessage id='usersManagement' />,
            type: 'item',
            url: usersManagementPath(),
            icon: icons.ManageAccountsIcon,
            permissions: [permissions.roleList],
        },
        {
            id: 'providers',
            title: <FormattedMessage id='providers' />,
            type: 'item',
            url: providersPath(),
            icon: icons.AccountBalanceIcon,
            permissions: [permissions.providerList],
        },
        {
            id: 'locations',
            title: <FormattedMessage id='locations' />,
            type: 'item',
            url: locationsPath(),
            icon: icons.GpsFixedIcon,
            permissions: [permissions.locList],
        },
        {
            id: 'resourceManagement',
            title: <FormattedMessage id='resourceManagement' />,
            type: 'item',
            url: resourceManagementPath(),
            icon: icons.BorderColorIcon,
            permissions: [permissions.resList],
        },
    ],
};

export default administrationManagement;
