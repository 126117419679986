import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    currentPage: number;
}

const initialState: initialStateProps = {
    currentPage: 1,
};

const ContainersPaginationSlice = createSlice({
    name: 'containersPagination',
    initialState,
    reducers: {
        setContainersCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },

        resetContainersCurrentPage: state => {
            state.currentPage = 1;
        },
    },
});

export const { setContainersCurrentPage, resetContainersCurrentPage } = ContainersPaginationSlice.actions;
export default ContainersPaginationSlice.reducer;
