import lexisLogo from './../../../assets/images/intro/LEXIS2_logo.png';
import euroHPCLogo from './../../../assets/images/intro/euroHPC_miniLogo.png';

const logo = process.env.REACT_APP_ENV === 'EUROFPDEMO' ? euroHPCLogo : lexisLogo;

const HeaderLogoIcon = () => {
    return <img src={logo} alt='Lexis' width='40' />;
};

export default HeaderLogoIcon;
