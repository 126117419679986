import { forwardRef, useEffect, ForwardRefExoticComponent, RefAttributes } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import Dot from 'components/@extended/Dot';
import useConfig from 'hooks/useConfig';
import { dispatch, useSelector } from 'store';
import { activeItem, openDrawer } from 'store/reducers/menu';
import { LinkTarget, NavItemType } from 'types/menu';
import { MenuOrientation, ThemeMode } from 'types/config';

interface Props {
    item: NavItemType;
    level: number;
}

const NavItem = ({ item, level }: Props) => {
    const theme = useTheme();
    const menu = useSelector(state => state.menu);
    const matchDownLg = useMediaQuery(theme.breakpoints.down('lg'));
    const { drawerOpen, openItem } = menu;

    const downLG = useMediaQuery(theme.breakpoints.down('lg'));

    const { menuOrientation } = useConfig();
    let itemTarget: LinkTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = { component: forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} ref={ref} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const Icon = item.icon!;
    const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

    const isSelected = openItem.findIndex(id => id === item.id) > -1;

    const { pathname } = useLocation();
    let items = location.pathname.split('/');
    items = [...items].filter(e => String(e).trim());

    useEffect(() => {
        if (items[0] === item.id) {
            dispatch(activeItem({ openItem: [item.id] }));
        }
    }, [pathname]);

    const textColor = theme.palette.mode === ThemeMode.DARK ? 'grey.400' : 'text.primary';
    const iconSelectedColor = theme.palette.mode === ThemeMode.DARK && drawerOpen ? 'text.primary' : 'primary.main';

    return (
        <>
            {menuOrientation === MenuOrientation.VERTICAL || downLG ? (
                <ListItemButton
                    id={item.id}
                    {...listItemProps}
                    disabled={item.disabled}
                    selected={isSelected}
                    sx={{
                        zIndex: 1201,
                        pl: drawerOpen ? `${level * 28}px` : 1.5,
                        py: !drawerOpen && level === 1 ? 1.25 : 1,
                        ...(drawerOpen && {
                            '&:hover': {
                                bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
                            },
                            '&.Mui-selected': {
                                bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
                                borderRight: `2px solid ${theme.palette.primary.main}`,
                                color: iconSelectedColor,
                                '&:hover': {
                                    color: iconSelectedColor,
                                    bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
                                },
                            },
                        }),
                        ...(!drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent',
                            },
                            '&.Mui-selected': {
                                '&:hover': {
                                    bgcolor: 'transparent',
                                },
                                bgcolor: 'transparent',
                            },
                        }),
                    }}
                    {...(matchDownLg && {
                        onClick: () => dispatch(openDrawer(false)),
                    })}>
                    {itemIcon && (
                        <ListItemIcon
                            sx={{
                                minWidth: 28,
                                color: isSelected ? iconSelectedColor : textColor,
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    width: 36,
                                    height: 36,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:hover': {
                                        bgcolor:
                                            theme.palette.mode === ThemeMode.DARK
                                                ? 'secondary.light'
                                                : 'secondary.lighter',
                                    },
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor:
                                            theme.palette.mode === ThemeMode.DARK ? 'primary.900' : 'primary.lighter',
                                        '&:hover': {
                                            bgcolor:
                                                theme.palette.mode === ThemeMode.DARK
                                                    ? 'primary.darker'
                                                    : 'primary.lighter',
                                        },
                                    }),
                            }}>
                            {itemIcon}
                        </ListItemIcon>
                    )}
                    {(drawerOpen || (!drawerOpen && level !== 1)) && (
                        <ListItemText
                            primary={
                                <Typography variant='h6' sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                                    {item.title}
                                </Typography>
                            }
                        />
                    )}
                    {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            ) : (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    selected={isSelected}
                    sx={{
                        zIndex: 1201,
                        ...(drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent',
                            },
                            '&.Mui-selected': {
                                bgcolor: 'transparent',
                                color: iconSelectedColor,
                                '&:hover': {
                                    color: iconSelectedColor,
                                    bgcolor: 'transparent',
                                },
                            },
                        }),
                        ...(!drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent',
                            },
                            '&.Mui-selected': {
                                '&:hover': {
                                    bgcolor: 'transparent',
                                },
                                bgcolor: 'transparent',
                            },
                        }),
                    }}>
                    {itemIcon && (
                        <ListItemIcon
                            sx={{
                                minWidth: 36,
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    width: 36,
                                    height: 36,
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    '&:hover': {
                                        bgcolor: 'transparent',
                                    },
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor: 'transparent',
                                        '&:hover': {
                                            bgcolor: 'transparent',
                                        },
                                    }),
                            }}>
                            {itemIcon}
                        </ListItemIcon>
                    )}

                    {!itemIcon && (
                        <ListItemIcon
                            sx={{
                                color: isSelected ? 'primary.main' : 'secondary.main',
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    '&:hover': {
                                        bgcolor: 'transparent',
                                    },
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor: 'transparent',
                                        '&:hover': {
                                            bgcolor: 'transparent',
                                        },
                                    }),
                            }}>
                            <Dot size={4} color={isSelected ? 'primary' : 'secondary'} />
                        </ListItemIcon>
                    )}
                    <ListItemText
                        primary={
                            <Typography variant='h6' color='inherit'>
                                {item.title}
                            </Typography>
                        }
                    />
                    {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            )}
        </>
    );
};

export default NavItem;
