import { airflowApi as api } from './airflowSlice';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        getConnections: build.query<GetConnectionsApiResponse, GetConnectionsApiArg>({
            query: queryArg => ({
                url: `/connections`,
                params: { limit: queryArg.limit, offset: queryArg.offset, order_by: queryArg.orderBy },
            }),
        }),
        postConnection: build.mutation<PostConnectionApiResponse, PostConnectionApiArg>({
            query: queryArg => ({ url: `/connections`, method: 'POST', body: queryArg.connection }),
        }),
        getConnection: build.query<GetConnectionApiResponse, GetConnectionApiArg>({
            query: queryArg => ({ url: `/connections/${queryArg.connectionId}` }),
        }),
        patchConnection: build.mutation<PatchConnectionApiResponse, PatchConnectionApiArg>({
            query: queryArg => ({
                url: `/connections/${queryArg.connectionId}`,
                method: 'PATCH',
                body: queryArg.connection,
                params: { update_mask: queryArg.updateMask },
            }),
        }),
        deleteConnection: build.mutation<DeleteConnectionApiResponse, DeleteConnectionApiArg>({
            query: queryArg => ({ url: `/connections/${queryArg.connectionId}`, method: 'DELETE' }),
        }),
        testConnection: build.mutation<TestConnectionApiResponse, TestConnectionApiArg>({
            query: queryArg => ({ url: `/connections/test`, method: 'POST', body: queryArg.connection }),
        }),
        getDags: build.query<GetDagsApiResponse, GetDagsApiArg>({
            query: queryArg => ({
                url: `/dags`,
                params: {
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    order_by: queryArg.orderBy,
                    tags: queryArg.tags,
                    only_active: queryArg.onlyActive,
                    paused: queryArg.paused,
                    dag_id_pattern: queryArg.dagIdPattern,
                },
            }),
        }),
        patchDags: build.mutation<PatchDagsApiResponse, PatchDagsApiArg>({
            query: queryArg => ({
                url: `/dags`,
                method: 'PATCH',
                body: queryArg.dag,
                params: {
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    tags: queryArg.tags,
                    update_mask: queryArg.updateMask,
                    only_active: queryArg.onlyActive,
                    dag_id_pattern: queryArg.dagIdPattern,
                },
            }),
        }),
        getDag: build.query<GetDagApiResponse, GetDagApiArg>({
            query: queryArg => ({ url: `/dags/${queryArg.dagId}` }),
        }),
        patchDag: build.mutation<PatchDagApiResponse, PatchDagApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}`,
                method: 'PATCH',
                body: queryArg.dag,
                params: { update_mask: queryArg.updateMask },
            }),
        }),
        deleteDag: build.mutation<DeleteDagApiResponse, DeleteDagApiArg>({
            query: queryArg => ({ url: `/dags/${queryArg.dagId}`, method: 'DELETE' }),
        }),
        postClearTaskInstances: build.mutation<PostClearTaskInstancesApiResponse, PostClearTaskInstancesApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/clearTaskInstances`,
                method: 'POST',
                body: queryArg.clearTaskInstances,
            }),
        }),
        setTaskInstanceNote: build.mutation<SetTaskInstanceNoteApiResponse, SetTaskInstanceNoteApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/taskInstances/${queryArg.taskId}/setNote`,
                method: 'PATCH',
                body: queryArg.setTaskInstanceNote,
            }),
        }),
        setMappedTaskInstanceNote: build.mutation<
            SetMappedTaskInstanceNoteApiResponse,
            SetMappedTaskInstanceNoteApiArg
        >({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/taskInstances/${queryArg.taskId}/${queryArg.mapIndex}/setNote`,
                method: 'PATCH',
                body: queryArg.setTaskInstanceNote,
            }),
        }),
        postSetTaskInstancesState: build.mutation<
            PostSetTaskInstancesStateApiResponse,
            PostSetTaskInstancesStateApiArg
        >({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/updateTaskInstancesState`,
                method: 'POST',
                body: queryArg.updateTaskInstancesState,
            }),
        }),
        getDagRuns: build.query<GetDagRunsApiResponse, GetDagRunsApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns`,
                params: {
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    execution_date_gte: queryArg.executionDateGte,
                    execution_date_lte: queryArg.executionDateLte,
                    start_date_gte: queryArg.startDateGte,
                    start_date_lte: queryArg.startDateLte,
                    end_date_gte: queryArg.endDateGte,
                    end_date_lte: queryArg.endDateLte,
                    updated_at_gte: queryArg.updatedAtGte,
                    updated_at_lte: queryArg.updatedAtLte,
                    state: queryArg.state,
                    order_by: queryArg.orderBy,
                },
            }),
        }),
        postDagRun: build.mutation<PostDagRunApiResponse, PostDagRunApiArg>({
            query: queryArg => ({ url: `/dags/${queryArg.dagId}/dagRuns`, method: 'POST', body: queryArg.dagRun }),
        }),
        getDagRunsBatch: build.mutation<GetDagRunsBatchApiResponse, GetDagRunsBatchApiArg>({
            query: queryArg => ({ url: `/dags/~/dagRuns/list`, method: 'POST', body: queryArg.listDagRunsForm }),
        }),
        getDagRun: build.query<GetDagRunApiResponse, GetDagRunApiArg>({
            query: queryArg => ({ url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}` }),
        }),
        deleteDagRun: build.mutation<DeleteDagRunApiResponse, DeleteDagRunApiArg>({
            query: queryArg => ({ url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}`, method: 'DELETE' }),
        }),
        updateDagRunState: build.mutation<UpdateDagRunStateApiResponse, UpdateDagRunStateApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}`,
                method: 'PATCH',
                body: queryArg.updateDagRunState,
            }),
        }),
        clearDagRun: build.mutation<ClearDagRunApiResponse, ClearDagRunApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/clear`,
                method: 'POST',
                body: queryArg.clearDagRun,
            }),
        }),
        getUpstreamDatasetEvents: build.query<GetUpstreamDatasetEventsApiResponse, GetUpstreamDatasetEventsApiArg>({
            query: queryArg => ({ url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/upstreamDatasetEvents` }),
        }),
        setDagRunNote: build.mutation<SetDagRunNoteApiResponse, SetDagRunNoteApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/setNote`,
                method: 'PATCH',
                body: queryArg.setDagRunNote,
            }),
        }),
        getEventLogs: build.query<GetEventLogsApiResponse, GetEventLogsApiArg>({
            query: queryArg => ({
                url: `/eventLogs`,
                params: { limit: queryArg.limit, offset: queryArg.offset, order_by: queryArg.orderBy },
            }),
        }),
        getEventLog: build.query<GetEventLogApiResponse, GetEventLogApiArg>({
            query: queryArg => ({ url: `/eventLogs/${queryArg.eventLogId}` }),
        }),
        getImportErrors: build.query<GetImportErrorsApiResponse, GetImportErrorsApiArg>({
            query: queryArg => ({
                url: `/importErrors`,
                params: { limit: queryArg.limit, offset: queryArg.offset, order_by: queryArg.orderBy },
            }),
        }),
        getImportError: build.query<GetImportErrorApiResponse, GetImportErrorApiArg>({
            query: queryArg => ({ url: `/importErrors/${queryArg.importErrorId}` }),
        }),
        getPools: build.query<GetPoolsApiResponse, GetPoolsApiArg>({
            query: queryArg => ({
                url: `/pools`,
                params: { limit: queryArg.limit, offset: queryArg.offset, order_by: queryArg.orderBy },
            }),
        }),
        postPool: build.mutation<PostPoolApiResponse, PostPoolApiArg>({
            query: queryArg => ({ url: `/pools`, method: 'POST', body: queryArg.pool }),
        }),
        getPool: build.query<GetPoolApiResponse, GetPoolApiArg>({
            query: queryArg => ({ url: `/pools/${queryArg.poolName}` }),
        }),
        patchPool: build.mutation<PatchPoolApiResponse, PatchPoolApiArg>({
            query: queryArg => ({
                url: `/pools/${queryArg.poolName}`,
                method: 'PATCH',
                body: queryArg.pool,
                params: { update_mask: queryArg.updateMask },
            }),
        }),
        deletePool: build.mutation<DeletePoolApiResponse, DeletePoolApiArg>({
            query: queryArg => ({ url: `/pools/${queryArg.poolName}`, method: 'DELETE' }),
        }),
        getProviders: build.query<GetProvidersApiResponse, GetProvidersApiArg>({
            query: () => ({ url: `/providers` }),
        }),
        getTaskInstances: build.query<GetTaskInstancesApiResponse, GetTaskInstancesApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/taskInstances`,
                params: {
                    execution_date_gte: queryArg.executionDateGte,
                    execution_date_lte: queryArg.executionDateLte,
                    start_date_gte: queryArg.startDateGte,
                    start_date_lte: queryArg.startDateLte,
                    end_date_gte: queryArg.endDateGte,
                    end_date_lte: queryArg.endDateLte,
                    updated_at_gte: queryArg.updatedAtGte,
                    updated_at_lte: queryArg.updatedAtLte,
                    duration_gte: queryArg.durationGte,
                    duration_lte: queryArg.durationLte,
                    state: queryArg.state,
                    pool: queryArg.pool,
                    queue: queryArg.queue,
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                },
            }),
        }),
        getTaskInstance: build.query<GetTaskInstanceApiResponse, GetTaskInstanceApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/taskInstances/${queryArg.taskId}`,
            }),
        }),
        patchTaskInstance: build.mutation<PatchTaskInstanceApiResponse, PatchTaskInstanceApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/taskInstances/${queryArg.taskId}`,
                method: 'PATCH',
                body: queryArg.updateTaskInstance,
            }),
        }),
        getMappedTaskInstance: build.query<GetMappedTaskInstanceApiResponse, GetMappedTaskInstanceApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/taskInstances/${queryArg.taskId}/${queryArg.mapIndex}`,
            }),
        }),
        patchMappedTaskInstance: build.mutation<PatchMappedTaskInstanceApiResponse, PatchMappedTaskInstanceApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/taskInstances/${queryArg.taskId}/${queryArg.mapIndex}`,
                method: 'PATCH',
                body: queryArg.updateTaskInstance,
            }),
        }),
        getMappedTaskInstances: build.query<GetMappedTaskInstancesApiResponse, GetMappedTaskInstancesApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/taskInstances/${queryArg.taskId}/listMapped`,
                params: {
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    execution_date_gte: queryArg.executionDateGte,
                    execution_date_lte: queryArg.executionDateLte,
                    start_date_gte: queryArg.startDateGte,
                    start_date_lte: queryArg.startDateLte,
                    end_date_gte: queryArg.endDateGte,
                    end_date_lte: queryArg.endDateLte,
                    updated_at_gte: queryArg.updatedAtGte,
                    updated_at_lte: queryArg.updatedAtLte,
                    duration_gte: queryArg.durationGte,
                    duration_lte: queryArg.durationLte,
                    state: queryArg.state,
                    pool: queryArg.pool,
                    queue: queryArg.queue,
                    order_by: queryArg.orderBy,
                },
            }),
        }),
        getTaskInstancesBatch: build.mutation<GetTaskInstancesBatchApiResponse, GetTaskInstancesBatchApiArg>({
            query: queryArg => ({
                url: `/dags/~/dagRuns/~/taskInstances/list`,
                method: 'POST',
                body: queryArg.listTaskInstanceForm,
            }),
        }),
        getVariables: build.query<GetVariablesApiResponse, GetVariablesApiArg>({
            query: queryArg => ({
                url: `/variables`,
                params: { limit: queryArg.limit, offset: queryArg.offset, order_by: queryArg.orderBy },
            }),
        }),
        postVariables: build.mutation<PostVariablesApiResponse, PostVariablesApiArg>({
            query: queryArg => ({ url: `/variables`, method: 'POST', body: queryArg.variable }),
        }),
        getVariable: build.query<GetVariableApiResponse, GetVariableApiArg>({
            query: queryArg => ({ url: `/variables/${queryArg.variableKey}` }),
        }),
        patchVariable: build.mutation<PatchVariableApiResponse, PatchVariableApiArg>({
            query: queryArg => ({
                url: `/variables/${queryArg.variableKey}`,
                method: 'PATCH',
                body: queryArg.variable,
                params: { update_mask: queryArg.updateMask },
            }),
        }),
        deleteVariable: build.mutation<DeleteVariableApiResponse, DeleteVariableApiArg>({
            query: queryArg => ({ url: `/variables/${queryArg.variableKey}`, method: 'DELETE' }),
        }),
        getXcomEntries: build.query<GetXcomEntriesApiResponse, GetXcomEntriesApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/taskInstances/${queryArg.taskId}/xcomEntries`,
                params: { limit: queryArg.limit, offset: queryArg.offset },
            }),
        }),
        getXcomEntry: build.query<GetXcomEntryApiResponse, GetXcomEntryApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/taskInstances/${queryArg.taskId}/xcomEntries/${queryArg.xcomKey}`,
                params: { deserialize: queryArg.deserialize },
            }),
        }),
        getExtraLinks: build.query<GetExtraLinksApiResponse, GetExtraLinksApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/taskInstances/${queryArg.taskId}/links`,
            }),
        }),
        getLog: build.query<GetLogApiResponse, GetLogApiArg>({
            query: queryArg => ({
                url: `/dags/${queryArg.dagId}/dagRuns/${queryArg.dagRunId}/taskInstances/${queryArg.taskId}/logs/${queryArg.taskTryNumber}`,
                params: { full_content: queryArg.fullContent, map_index: queryArg.mapIndex, token: queryArg.token },
                responseHandler: response => response.text(),
            }),
        }),
        getDagDetails: build.query<GetDagDetailsApiResponse, GetDagDetailsApiArg>({
            query: queryArg => ({ url: `/dags/${queryArg.dagId}/details` }),
        }),
        getTasks: build.query<GetTasksApiResponse, GetTasksApiArg>({
            query: queryArg => ({ url: `/dags/${queryArg.dagId}/tasks`, params: { order_by: queryArg.orderBy } }),
        }),
        getTask: build.query<GetTaskApiResponse, GetTaskApiArg>({
            query: queryArg => ({ url: `/dags/${queryArg.dagId}/tasks/${queryArg.taskId}` }),
        }),
        getDagSource: build.query<GetDagSourceApiResponse, GetDagSourceApiArg>({
            query: queryArg => ({ url: `/dagSources/${queryArg.fileToken}` }),
        }),
        getDagWarnings: build.query<GetDagWarningsApiResponse, GetDagWarningsApiArg>({
            query: queryArg => ({
                url: `/dagWarnings`,
                params: {
                    dag_id: queryArg.dagId,
                    warning_type: queryArg.warningType,
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    order_by: queryArg.orderBy,
                },
            }),
        }),
        getDatasets: build.query<GetDatasetsApiResponse, GetDatasetsApiArg>({
            query: queryArg => ({
                url: `/datasets`,
                params: {
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    order_by: queryArg.orderBy,
                    uri_pattern: queryArg.uriPattern,
                },
            }),
        }),
        getDataset: build.query<GetDatasetApiResponse, GetDatasetApiArg>({
            query: queryArg => ({ url: `/datasets/${queryArg.uri}` }),
        }),
        getDatasetEvents: build.query<GetDatasetEventsApiResponse, GetDatasetEventsApiArg>({
            query: queryArg => ({
                url: `/datasets/events`,
                params: {
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    order_by: queryArg.orderBy,
                    dataset_id: queryArg.datasetId,
                    source_dag_id: queryArg.sourceDagId,
                    source_task_id: queryArg.sourceTaskId,
                    source_run_id: queryArg.sourceRunId,
                    source_map_index: queryArg.sourceMapIndex,
                },
            }),
        }),
        getConfig: build.query<GetConfigApiResponse, GetConfigApiArg>({
            query: () => ({ url: `/config` }),
        }),
        getHealth: build.query<GetHealthApiResponse, GetHealthApiArg>({
            query: () => ({ url: `/health` }),
        }),
        getVersion: build.query<GetVersionApiResponse, GetVersionApiArg>({
            query: () => ({ url: `/version` }),
        }),
        getPlugins: build.query<GetPluginsApiResponse, GetPluginsApiArg>({
            query: queryArg => ({ url: `/plugins`, params: { limit: queryArg.limit, offset: queryArg.offset } }),
        }),
        getRoles: build.query<GetRolesApiResponse, GetRolesApiArg>({
            query: queryArg => ({
                url: `/roles`,
                params: { limit: queryArg.limit, offset: queryArg.offset, order_by: queryArg.orderBy },
            }),
        }),
        postRole: build.mutation<PostRoleApiResponse, PostRoleApiArg>({
            query: queryArg => ({ url: `/roles`, method: 'POST', body: queryArg.role }),
        }),
        getRole: build.query<GetRoleApiResponse, GetRoleApiArg>({
            query: queryArg => ({ url: `/roles/${queryArg.roleName}` }),
        }),
        patchRole: build.mutation<PatchRoleApiResponse, PatchRoleApiArg>({
            query: queryArg => ({
                url: `/roles/${queryArg.roleName}`,
                method: 'PATCH',
                body: queryArg.role,
                params: { update_mask: queryArg.updateMask },
            }),
        }),
        deleteRole: build.mutation<DeleteRoleApiResponse, DeleteRoleApiArg>({
            query: queryArg => ({ url: `/roles/${queryArg.roleName}`, method: 'DELETE' }),
        }),
        getPermissions: build.query<GetPermissionsApiResponse, GetPermissionsApiArg>({
            query: queryArg => ({ url: `/permissions`, params: { limit: queryArg.limit, offset: queryArg.offset } }),
        }),
        getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
            query: queryArg => ({
                url: `/users`,
                params: { limit: queryArg.limit, offset: queryArg.offset, order_by: queryArg.orderBy },
            }),
        }),
        postUser: build.mutation<PostUserApiResponse, PostUserApiArg>({
            query: queryArg => ({ url: `/users`, method: 'POST', body: queryArg.user }),
        }),
        getUser: build.query<GetUserApiResponse, GetUserApiArg>({
            query: queryArg => ({ url: `/users/${queryArg.username}` }),
        }),
        patchUser: build.mutation<PatchUserApiResponse, PatchUserApiArg>({
            query: queryArg => ({
                url: `/users/${queryArg.username}`,
                method: 'PATCH',
                body: queryArg.user,
                params: { update_mask: queryArg.updateMask },
            }),
        }),
        deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
            query: queryArg => ({ url: `/users/${queryArg.username}`, method: 'DELETE' }),
        }),
    }),
    overrideExisting: true,
});
export { injectedRtkApi as petApi };
export type GetConnectionsApiResponse = /** status 200 Success. */ ConnectionCollection;
export type GetConnectionsApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
};
export type PostConnectionApiResponse = /** status 200 Success. */ Connection;
export type PostConnectionApiArg = {
    connection: Connection;
};
export type GetConnectionApiResponse = /** status 200 Success. */ Connection;
export type GetConnectionApiArg = {
    /** The connection ID. */
    connectionId: string;
};
export type PatchConnectionApiResponse = /** status 200 Success. */ Connection;
export type PatchConnectionApiArg = {
    /** The connection ID. */
    connectionId: string;
    /** The fields to update on the resource. If absent or empty, all modifiable fields are updated.
    A comma-separated list of fully qualified names of fields.
     */
    updateMask?: string[];
    connection: Connection;
};
export type DeleteConnectionApiResponse = /** status 204 Success. */ undefined;
export type DeleteConnectionApiArg = {
    /** The connection ID. */
    connectionId: string;
};
export type TestConnectionApiResponse = /** status 200 Success. */ ConnectionTest;
export type TestConnectionApiArg = {
    connection: Connection;
};
export type GetDagsApiResponse = /** status 200 Success. */ DagCollection;
export type GetDagsApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
    /** List of tags to filter results.
    
    *New in version 2.2.0*
     */
    tags?: string[];
    /** Only filter active DAGs.
    
    *New in version 2.1.1*
     */
    onlyActive?: boolean;
    /** Only filter paused/unpaused DAGs. If absent or null, it returns paused and unpaused DAGs.
    
    *New in version 2.6.0*
     */
    paused?: boolean;
    /** If set, only return DAGs with dag_ids matching this pattern.
     */
    dagIdPattern?: string;
};
export type PatchDagsApiResponse = /** status 200 Success. */ DagCollection;
export type PatchDagsApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** List of tags to filter results.
    
    *New in version 2.2.0*
     */
    tags?: string[];
    /** The fields to update on the resource. If absent or empty, all modifiable fields are updated.
    A comma-separated list of fully qualified names of fields.
     */
    updateMask?: string[];
    /** Only filter active DAGs.
    
    *New in version 2.1.1*
     */
    onlyActive?: boolean;
    /** If set, only update DAGs with dag_ids matching this pattern.
     */
    dagIdPattern: string;
    dag: Dag;
};
export type GetDagApiResponse = /** status 200 Success. */ Dag;
export type GetDagApiArg = {
    /** The DAG ID. */
    dagId: string;
};
export type PatchDagApiResponse = /** status 200 Success. */ Dag;
export type PatchDagApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The fields to update on the resource. If absent or empty, all modifiable fields are updated.
    A comma-separated list of fully qualified names of fields.
     */
    updateMask?: string[];
    dag: Dag;
};
export type DeleteDagApiResponse = /** status 204 Success. */ undefined;
export type DeleteDagApiArg = {
    /** The DAG ID. */
    dagId: string;
};
export type PostClearTaskInstancesApiResponse = /** status 200 Success. */ TaskInstanceReferenceCollection;
export type PostClearTaskInstancesApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** Parameters of action */
    clearTaskInstances: ClearTaskInstances;
};
export type SetTaskInstanceNoteApiResponse = /** status 200 Success. */ TaskInstance;
export type SetTaskInstanceNoteApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** The task ID. */
    taskId: string;
    /** Parameters of set Task Instance note. */
    setTaskInstanceNote: SetTaskInstanceNote;
};
export type SetMappedTaskInstanceNoteApiResponse = /** status 200 Success. */ TaskInstance;
export type SetMappedTaskInstanceNoteApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** The task ID. */
    taskId: string;
    /** The map index. */
    mapIndex: number;
    /** Parameters of set Task Instance note. */
    setTaskInstanceNote: SetTaskInstanceNote;
};
export type PostSetTaskInstancesStateApiResponse = /** status 200 Success. */ TaskInstanceReferenceCollection;
export type PostSetTaskInstancesStateApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** Parameters of action */
    updateTaskInstancesState: UpdateTaskInstancesState;
};
export type GetDagRunsApiResponse = /** status 200 List of DAG runs. */ DagRunCollection;
export type GetDagRunsApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** Returns objects greater or equal to the specified date.
    
    This can be combined with execution_date_lte parameter to receive only the selected period.
     */
    executionDateGte?: string;
    /** Returns objects less than or equal to the specified date.
    
    This can be combined with execution_date_gte parameter to receive only the selected period.
     */
    executionDateLte?: string;
    /** Returns objects greater or equal the specified date.
    
    This can be combined with start_date_lte parameter to receive only the selected period.
     */
    startDateGte?: string;
    /** Returns objects less or equal the specified date.
    
    This can be combined with start_date_gte parameter to receive only the selected period.
     */
    startDateLte?: string;
    /** Returns objects greater or equal the specified date.
    
    This can be combined with start_date_lte parameter to receive only the selected period.
     */
    endDateGte?: string;
    /** Returns objects less than or equal to the specified date.
    
    This can be combined with start_date_gte parameter to receive only the selected period.
     */
    endDateLte?: string;
    /** Returns objects greater or equal the specified date.
    
    This can be combined with updated_at_lte parameter to receive only the selected period.
    
    *New in version 2.6.0*
     */
    updatedAtGte?: string;
    /** Returns objects less or equal the specified date.
    
    This can be combined with updated_at_gte parameter to receive only the selected period.
    
    *New in version 2.6.0*
     */
    updatedAtLte?: string;
    /** The value can be repeated to retrieve multiple matching values (OR condition). */
    state?: string[];
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
};
export type PostDagRunApiResponse = /** status 200 Success. */ DagRun;
export type PostDagRunApiArg = {
    /** The DAG ID. */
    dagId: string;
    dagRun: DagRun;
};
export type GetDagRunsBatchApiResponse = /** status 200 Success. */ DagRunCollection;
export type GetDagRunsBatchApiArg = {
    listDagRunsForm: ListDagRunsForm;
};
export type GetDagRunApiResponse = /** status 200 Success. */ DagRun;
export type GetDagRunApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
};
export type DeleteDagRunApiResponse = /** status 204 Success. */ undefined;
export type DeleteDagRunApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
};
export type UpdateDagRunStateApiResponse = /** status 200 Success. */ DagRun;
export type UpdateDagRunStateApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    updateDagRunState: UpdateDagRunState;
};
export type ClearDagRunApiResponse = /** status 200 Success. */ DagRun | TaskInstanceCollection;
export type ClearDagRunApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    clearDagRun: ClearDagRun;
};
export type GetUpstreamDatasetEventsApiResponse = /** status 200 Success. */ DatasetEventCollection;
export type GetUpstreamDatasetEventsApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
};
export type SetDagRunNoteApiResponse = /** status 200 Success. */ DagRun;
export type SetDagRunNoteApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** Parameters of set DagRun note. */
    setDagRunNote: SetDagRunNote;
};
export type GetEventLogsApiResponse = /** status 200 Success. */ EventLogCollection;
export type GetEventLogsApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
};
export type GetEventLogApiResponse = /** status 200 Success. */ EventLog;
export type GetEventLogApiArg = {
    /** The event log ID. */
    eventLogId: number;
};
export type GetImportErrorsApiResponse = /** status 200 Success. */ ImportErrorCollection;
export type GetImportErrorsApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
};
export type GetImportErrorApiResponse = /** status 200 Success. */ ImportError;
export type GetImportErrorApiArg = {
    /** The import error ID. */
    importErrorId: number;
};
export type GetPoolsApiResponse = /** status 200 List of pools. */ PoolCollection;
export type GetPoolsApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
};
export type PostPoolApiResponse = /** status 200 Success. */ Pool;
export type PostPoolApiArg = {
    pool: Pool;
};
export type GetPoolApiResponse = /** status 200 Success. */ Pool;
export type GetPoolApiArg = {
    /** The pool name. */
    poolName: string;
};
export type PatchPoolApiResponse = /** status 200 Success. */ Pool;
export type PatchPoolApiArg = {
    /** The pool name. */
    poolName: string;
    /** The fields to update on the resource. If absent or empty, all modifiable fields are updated.
    A comma-separated list of fully qualified names of fields.
     */
    updateMask?: string[];
    pool: Pool;
};
export type DeletePoolApiResponse = /** status 204 Success. */ undefined;
export type DeletePoolApiArg = {
    /** The pool name. */
    poolName: string;
};
export type GetProvidersApiResponse = /** status 200 List of providers. */ ProviderCollection & CollectionInfo;
export type GetProvidersApiArg = void;
export type GetTaskInstancesApiResponse = /** status 200 Success. */ TaskInstanceCollection;
export type GetTaskInstancesApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** Returns objects greater or equal to the specified date.
    
    This can be combined with execution_date_lte parameter to receive only the selected period.
     */
    executionDateGte?: string;
    /** Returns objects less than or equal to the specified date.
    
    This can be combined with execution_date_gte parameter to receive only the selected period.
     */
    executionDateLte?: string;
    /** Returns objects greater or equal the specified date.
    
    This can be combined with start_date_lte parameter to receive only the selected period.
     */
    startDateGte?: string;
    /** Returns objects less or equal the specified date.
    
    This can be combined with start_date_gte parameter to receive only the selected period.
     */
    startDateLte?: string;
    /** Returns objects greater or equal the specified date.
    
    This can be combined with start_date_lte parameter to receive only the selected period.
     */
    endDateGte?: string;
    /** Returns objects less than or equal to the specified date.
    
    This can be combined with start_date_gte parameter to receive only the selected period.
     */
    endDateLte?: string;
    /** Returns objects greater or equal the specified date.
    
    This can be combined with updated_at_lte parameter to receive only the selected period.
    
    *New in version 2.6.0*
     */
    updatedAtGte?: string;
    /** Returns objects less or equal the specified date.
    
    This can be combined with updated_at_gte parameter to receive only the selected period.
    
    *New in version 2.6.0*
     */
    updatedAtLte?: string;
    /** Returns objects greater than or equal to the specified values.
    
    This can be combined with duration_lte parameter to receive only the selected period.
     */
    durationGte?: number;
    /** Returns objects less than or equal to the specified values.
    
    This can be combined with duration_gte parameter to receive only the selected range.
     */
    durationLte?: number;
    /** The value can be repeated to retrieve multiple matching values (OR condition). */
    state?: string[];
    /** The value can be repeated to retrieve multiple matching values (OR condition). */
    pool?: string[];
    /** The value can be repeated to retrieve multiple matching values (OR condition). */
    queue?: string[];
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
};
export type GetTaskInstanceApiResponse = /** status 200 Success. */ TaskInstance;
export type GetTaskInstanceApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** The task ID. */
    taskId: string;
};
export type PatchTaskInstanceApiResponse = /** status 200 Success. */ TaskInstanceReference;
export type PatchTaskInstanceApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** The task ID. */
    taskId: string;
    /** Parameters of action */
    updateTaskInstance: UpdateTaskInstance;
};
export type GetMappedTaskInstanceApiResponse = /** status 200 Success. */ TaskInstance;
export type GetMappedTaskInstanceApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** The task ID. */
    taskId: string;
    /** The map index. */
    mapIndex: number;
};
export type PatchMappedTaskInstanceApiResponse = /** status 200 Success. */ TaskInstanceReference;
export type PatchMappedTaskInstanceApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** The task ID. */
    taskId: string;
    /** The map index. */
    mapIndex: number;
    /** Parameters of action */
    updateTaskInstance: UpdateTaskInstance;
};
export type GetMappedTaskInstancesApiResponse = /** status 200 Success. */ TaskInstanceCollection;
export type GetMappedTaskInstancesApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** The task ID. */
    taskId: string;
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** Returns objects greater or equal to the specified date.
    
    This can be combined with execution_date_lte parameter to receive only the selected period.
     */
    executionDateGte?: string;
    /** Returns objects less than or equal to the specified date.
    
    This can be combined with execution_date_gte parameter to receive only the selected period.
     */
    executionDateLte?: string;
    /** Returns objects greater or equal the specified date.
    
    This can be combined with start_date_lte parameter to receive only the selected period.
     */
    startDateGte?: string;
    /** Returns objects less or equal the specified date.
    
    This can be combined with start_date_gte parameter to receive only the selected period.
     */
    startDateLte?: string;
    /** Returns objects greater or equal the specified date.
    
    This can be combined with start_date_lte parameter to receive only the selected period.
     */
    endDateGte?: string;
    /** Returns objects less than or equal to the specified date.
    
    This can be combined with start_date_gte parameter to receive only the selected period.
     */
    endDateLte?: string;
    /** Returns objects greater or equal the specified date.
    
    This can be combined with updated_at_lte parameter to receive only the selected period.
    
    *New in version 2.6.0*
     */
    updatedAtGte?: string;
    /** Returns objects less or equal the specified date.
    
    This can be combined with updated_at_gte parameter to receive only the selected period.
    
    *New in version 2.6.0*
     */
    updatedAtLte?: string;
    /** Returns objects greater than or equal to the specified values.
    
    This can be combined with duration_lte parameter to receive only the selected period.
     */
    durationGte?: number;
    /** Returns objects less than or equal to the specified values.
    
    This can be combined with duration_gte parameter to receive only the selected range.
     */
    durationLte?: number;
    /** The value can be repeated to retrieve multiple matching values (OR condition). */
    state?: string[];
    /** The value can be repeated to retrieve multiple matching values (OR condition). */
    pool?: string[];
    /** The value can be repeated to retrieve multiple matching values (OR condition). */
    queue?: string[];
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
};
export type GetTaskInstancesBatchApiResponse = /** status 200 Success. */ TaskInstanceCollection;
export type GetTaskInstancesBatchApiArg = {
    listTaskInstanceForm: ListTaskInstanceForm;
};
export type GetVariablesApiResponse = /** status 200 Success. */ VariableCollection;
export type GetVariablesApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
};
export type PostVariablesApiResponse = /** status 200 Success. */ Variable;
export type PostVariablesApiArg = {
    variable: Variable;
};
export type GetVariableApiResponse = /** status 200 Success. */ Variable;
export type GetVariableApiArg = {
    /** The variable Key. */
    variableKey: string;
};
export type PatchVariableApiResponse = /** status 200 Success. */ Variable;
export type PatchVariableApiArg = {
    /** The variable Key. */
    variableKey: string;
    /** The fields to update on the resource. If absent or empty, all modifiable fields are updated.
    A comma-separated list of fully qualified names of fields.
     */
    updateMask?: string[];
    variable: Variable;
};
export type DeleteVariableApiResponse = /** status 204 Success. */ undefined;
export type DeleteVariableApiArg = {
    /** The variable Key. */
    variableKey: string;
};
export type GetXcomEntriesApiResponse = /** status 200 Success. */ XComCollection;
export type GetXcomEntriesApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** The task ID. */
    taskId: string;
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
};
export type GetXcomEntryApiResponse = /** status 200 Success. */ XCom;
export type GetXcomEntryApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** The task ID. */
    taskId: string;
    /** The XCom key. */
    xcomKey: string;
    /** Whether to deserialize an XCom value when using a custom XCom backend.
    
    The XCom API endpoint calls `orm_deserialize_value` by default since an XCom may contain value
    that is potentially expensive to deserialize in the web server. Setting this to true overrides
    the consideration, and calls `deserialize_value` instead.
    
    This parameter is not meaningful when using the default XCom backend.
    
    *New in version 2.4.0*
     */
    deserialize?: boolean;
};
export type GetExtraLinksApiResponse = /** status 200 Success. */ ExtraLinkCollection;
export type GetExtraLinksApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** The task ID. */
    taskId: string;
};
export type GetLogApiResponse = /** status 200 Success. */ {
    continuation_token?: string;
    content?: string;
};
export type GetLogApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The DAG run ID. */
    dagRunId: string;
    /** The task ID. */
    taskId: string;
    /** The task try number. */
    taskTryNumber: number;
    /** A full content will be returned.
    By default, only the first fragment will be returned.
     */
    fullContent?: boolean;
    /** Filter on map index for mapped task. */
    mapIndex?: number;
    /** A token that allows you to continue fetching logs.
    If passed, it will specify the location from which the download should be continued.
     */
    token?: string;
};
export type GetDagDetailsApiResponse = /** status 200 Success. */ DagDetail;
export type GetDagDetailsApiArg = {
    /** The DAG ID. */
    dagId: string;
};
export type GetTasksApiResponse = /** status 200 Success. */ TaskCollection;
export type GetTasksApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
};
export type GetTaskApiResponse = /** status 200 Success. */ Task;
export type GetTaskApiArg = {
    /** The DAG ID. */
    dagId: string;
    /** The task ID. */
    taskId: string;
};
export type GetDagSourceApiResponse = /** status 200 Success. */ {
    content?: string;
};
export type GetDagSourceApiArg = {
    /** The key containing the encrypted path to the file. Encryption and decryption take place only on
    the server. This prevents the client from reading an non-DAG file. This also ensures API
    extensibility, because the format of encrypted data may change.
     */
    fileToken: string;
};
export type GetDagWarningsApiResponse = /** status 200 Success. */ DagWarningCollection;
export type GetDagWarningsApiArg = {
    /** If set, only return DAG warnings with this dag_id. */
    dagId?: string;
    /** If set, only return DAG warnings with this type. */
    warningType?: string;
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
};
export type GetDatasetsApiResponse = /** status 200 Success. */ DatasetCollection;
export type GetDatasetsApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
    /** If set, only return datasets with uris matching this pattern.
     */
    uriPattern?: string;
};
export type GetDatasetApiResponse = /** status 200 Success. */ Dataset;
export type GetDatasetApiArg = {
    /** The encoded Dataset URI */
    uri: string;
};
export type GetDatasetEventsApiResponse = /** status 200 Success. */ DatasetEventCollection;
export type GetDatasetEventsApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
    /** The Dataset ID that updated the dataset. */
    datasetId?: number;
    /** The DAG ID that updated the dataset. */
    sourceDagId?: string;
    /** The task ID that updated the dataset. */
    sourceTaskId?: string;
    /** The DAG run ID that updated the dataset. */
    sourceRunId?: string;
    /** The map index that updated the dataset. */
    sourceMapIndex?: number;
};
export type GetConfigApiResponse = /** status 200 Success. */ Config;
export type GetConfigApiArg = void;
export type GetHealthApiResponse = /** status 200 Success. */ HealthInfo;
export type GetHealthApiArg = void;
export type GetVersionApiResponse = /** status 200 Success. */ VersionInfo;
export type GetVersionApiArg = void;
export type GetPluginsApiResponse = /** status 200 Success */ PluginCollection;
export type GetPluginsApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
};
export type GetRolesApiResponse = /** status 200 Success. */ RoleCollection;
export type GetRolesApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
};
export type PostRoleApiResponse = /** status 200 Success. */ Role;
export type PostRoleApiArg = {
    role: Role;
};
export type GetRoleApiResponse = /** status 200 Success. */ Role;
export type GetRoleApiArg = {
    /** The role name */
    roleName: string;
};
export type PatchRoleApiResponse = /** status 200 Success. */ Role;
export type PatchRoleApiArg = {
    /** The role name */
    roleName: string;
    /** The fields to update on the resource. If absent or empty, all modifiable fields are updated.
    A comma-separated list of fully qualified names of fields.
     */
    updateMask?: string[];
    role: Role;
};
export type DeleteRoleApiResponse = /** status 204 Success. */ undefined;
export type DeleteRoleApiArg = {
    /** The role name */
    roleName: string;
};
export type GetPermissionsApiResponse = /** status 200 Success. */ ActionCollection;
export type GetPermissionsApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
};
export type GetUsersApiResponse = /** status 200 Success. */ UserCollection;
export type GetUsersApiArg = {
    /** The numbers of items to return. */
    limit?: number;
    /** The number of items to skip before starting to collect the result set. */
    offset?: number;
    /** The name of the field to order the results by.
    Prefix a field name with `-` to reverse the sort order.
    
    *New in version 2.1.0*
     */
    orderBy?: string;
};
export type PostUserApiResponse = /** status 200 Success. */ User;
export type PostUserApiArg = {
    user: User;
};
export type GetUserApiResponse = /** status 200 Success. */ UserCollectionItem;
export type GetUserApiArg = {
    /** The username of the user.
    
    *New in version 2.1.0*
     */
    username: string;
};
export type PatchUserApiResponse = /** status 200 Success. */ UserCollectionItem;
export type PatchUserApiArg = {
    /** The username of the user.
    
    *New in version 2.1.0*
     */
    username: string;
    /** The fields to update on the resource. If absent or empty, all modifiable fields are updated.
    A comma-separated list of fully qualified names of fields.
     */
    updateMask?: string[];
    user: User;
};
export type DeleteUserApiResponse = /** status 204 Success. */ undefined;
export type DeleteUserApiArg = {
    /** The username of the user.
    
    *New in version 2.1.0*
     */
    username: string;
};
export type ConnectionCollectionItem = {
    connection_id?: string;
    conn_type?: string;
    description?: string | null;
    host?: string | null;
    login?: string | null;
    schema?: string | null;
    port?: number | null;
};
export type CollectionInfo = {
    total_entries?: number;
};
export type ConnectionCollection = {
    connections?: ConnectionCollectionItem[];
} & CollectionInfo;
export type Error = {
    type: string;
    title: string;
    status: number;
    detail?: string;
    instance?: string;
};
export type Connection = ConnectionCollectionItem & {
    password?: string;
    extra?: string | null;
};
export type ConnectionTest = {
    status?: boolean;
    message?: string;
};
export type TimeDelta = {
    __type: string;
    days: number;
    seconds: number;
    microseconds: number;
};
export type RelativeDelta = {
    __type: string;
    years: number;
    months: number;
    days: number;
    leapdays: number;
    hours: number;
    minutes: number;
    seconds: number;
    microseconds: number;
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    second: number;
    microsecond: number;
};
export type CronExpression = {
    __type: string;
    value: string;
} | null;
export type ScheduleInterval = (TimeDelta | RelativeDelta | CronExpression) | null;
export type Tag = {
    name?: string;
};
export type Dag = {
    dag_id?: string;
    root_dag_id?: string | null;
    is_paused?: boolean | null;
    is_active?: boolean | null;
    is_subdag?: boolean;
    last_parsed_time?: string | null;
    last_pickled?: string | null;
    last_expired?: string | null;
    scheduler_lock?: boolean | null;
    pickle_id?: string | null;
    default_view?: string | null;
    fileloc?: string;
    file_token?: string;
    owners?: string[];
    description?: string | null;
    schedule_interval?: ScheduleInterval;
    timetable_description?: string | null;
    tags?: Tag[] | null;
    max_active_tasks?: number | null;
    max_active_runs?: number | null;
    has_task_concurrency_limits?: boolean | null;
    has_import_errors?: boolean | null;
    next_dagrun?: string | null;
    next_dagrun_data_interval_start?: string | null;
    next_dagrun_data_interval_end?: string | null;
    next_dagrun_create_after?: string | null;
};
export type DagCollection = {
    dags?: Dag[];
} & CollectionInfo;
export type TaskInstanceReference = {
    task_id?: string;
    dag_id?: string;
    execution_date?: string;
    dag_run_id?: string;
};
export type TaskInstanceReferenceCollection = {
    task_instances?: TaskInstanceReference[];
};
export type ClearTaskInstances = {
    dry_run?: boolean;
    task_ids?: string[];
    start_date?: string;
    end_date?: string;
    only_failed?: boolean;
    only_running?: boolean;
    include_subdags?: boolean;
    include_parentdag?: boolean;
    reset_dag_runs?: boolean;
    dag_run_id?: string | null;
    include_upstream?: boolean;
    include_downstream?: boolean;
    include_future?: boolean;
    include_past?: boolean;
};
export type TaskState =
    | 'success'
    | 'running'
    | 'failed'
    | 'upstream_failed'
    | 'skipped'
    | 'up_for_retry'
    | 'up_for_reschedule'
    | 'queued'
    | 'none'
    | 'scheduled'
    | 'deferred'
    | 'removed'
    | 'restarting';
export type SlaMiss = {
    task_id?: string;
    dag_id?: string;
    execution_date?: string;
    email_sent?: boolean;
    timestamp?: string;
    description?: string | null;
    notification_sent?: boolean;
} | null;
export type Trigger = {
    id?: number;
    classpath?: string;
    kwargs?: string;
    created_date?: string;
    triggerer_id?: number | null;
};
export type Job = {
    id?: number;
    dag_id?: string | null;
    state?: string | null;
    job_type?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    latest_heartbeat?: string | null;
    executor_class?: string | null;
    hostname?: string | null;
    unixname?: string | null;
};
export type TaskInstance = {
    task_id?: string;
    dag_id?: string;
    dag_run_id?: string;
    execution_date?: string;
    start_date?: string | null;
    end_date?: string | null;
    duration?: number | null;
    state?: TaskState | null;
    try_number?: number;
    map_index?: number;
    max_tries?: number;
    hostname?: string;
    unixname?: string;
    pool?: string;
    pool_slots?: number;
    queue?: string | null;
    priority_weight?: number | null;
    operator?: string | null;
    queued_when?: string | null;
    pid?: number | null;
    executor_config?: string;
    sla_miss?: SlaMiss | null;
    rendered_fields?: object;
    trigger?: Trigger | null;
    triggerer_job?: Job | null;
    note?: string | null;
};
export type SetTaskInstanceNote = {
    note: string;
};
export type UpdateTaskInstancesState = {
    dry_run?: boolean;
    task_id?: string;
    execution_date?: string;
    dag_run_id?: string;
    include_upstream?: boolean;
    include_downstream?: boolean;
    include_future?: boolean;
    include_past?: boolean;
    new_state?: 'success' | 'failed';
};
export type DagState = 'queued' | 'running' | 'success' | 'failed';
export type DagRun = {
    dag_run_id?: string | null;
    dag_id?: string;
    logical_date?: string | null;
    execution_date?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    data_interval_start?: string | null;
    data_interval_end?: string | null;
    last_scheduling_decision?: string | null;
    run_type?: 'backfill' | 'manual' | 'scheduled' | 'dataset_triggered';
    state?: DagState;
    external_trigger?: boolean;
    conf?: object;
    note?: string | null;
};
export type DagRunCollection = {
    dag_runs?: DagRun[];
} & CollectionInfo;
export type ListDagRunsForm = {
    order_by?: string;
    page_offset?: number;
    page_limit?: number;
    dag_ids?: string[];
    states?: string[];
    execution_date_gte?: string;
    execution_date_lte?: string;
    start_date_gte?: string;
    start_date_lte?: string;
    end_date_gte?: string;
    end_date_lte?: string;
};
export type UpdateDagRunState = {
    state?: 'success' | 'failed' | 'queued';
};
export type TaskInstanceCollection = {
    task_instances?: TaskInstance[];
} & CollectionInfo;
export type ClearDagRun = {
    dry_run?: boolean;
};
export type BasicDagRun = {
    run_id?: string;
    dag_id?: string;
    logical_date?: string;
    start_date?: string | null;
    end_date?: string | null;
    data_interval_start?: string | null;
    data_interval_end?: string | null;
    state?: DagState;
};
export type DatasetEvent = {
    dataset_id?: number;
    dataset_uri?: string;
    extra?: object | null;
    source_dag_id?: string | null;
    source_task_id?: string | null;
    source_run_id?: string | null;
    source_map_index?: number | null;
    created_dagruns?: BasicDagRun[];
    timestamp?: string;
};
export type DatasetEventCollection = {
    dataset_events?: DatasetEvent[];
} & CollectionInfo;
export type SetDagRunNote = {
    note?: string;
};
export type EventLog = {
    event_log_id?: number;
    when?: string;
    dag_id?: string | null;
    task_id?: string | null;
    event?: string;
    execution_date?: string | null;
    owner?: string;
    extra?: string | null;
};
export type EventLogCollection = {
    event_logs?: EventLog[];
} & CollectionInfo;
export type ImportError = {
    import_error_id?: number;
    timestamp?: string;
    filename?: string;
    stack_trace?: string;
};
export type ImportErrorCollection = {
    import_errors?: ImportError[];
} & CollectionInfo;
export type Pool = {
    name?: string;
    slots?: number;
    occupied_slots?: number;
    running_slots?: number;
    queued_slots?: number;
    open_slots?: number;
    scheduled_slots?: number;
    description?: string | null;
};
export type PoolCollection = {
    pools?: Pool[];
} & CollectionInfo;
export type Provider = {
    package_name?: string;
    description?: string;
    version?: string;
};
export type ProviderCollection = {
    providers?: Provider[];
};
export type UpdateTaskInstance = {
    dry_run?: boolean;
    new_state?: 'success' | 'failed';
};
export type ListTaskInstanceForm = {
    dag_ids?: string[];
    execution_date_gte?: string;
    execution_date_lte?: string;
    start_date_gte?: string;
    start_date_lte?: string;
    end_date_gte?: string;
    end_date_lte?: string;
    duration_gte?: number;
    duration_lte?: number;
    state?: TaskState[];
    pool?: string[];
    queue?: string[];
};
export type VariableCollectionItem = {
    key?: string;
    description?: string | null;
};
export type VariableCollection = {
    variables?: VariableCollectionItem[];
} & CollectionInfo;
export type Variable = VariableCollectionItem & {
    value?: string;
};
export type XComCollectionItem = {
    key?: string;
    timestamp?: string;
    execution_date?: string;
    task_id?: string;
    dag_id?: string;
};
export type XComCollection = {
    xcom_entries?: XComCollectionItem[];
} & CollectionInfo;
export type XCom = XComCollectionItem & {
    value?: string;
};
export type ClassReference = {
    module_path?: string;
    class_name?: string;
};
export type ExtraLink = {
    class_ref?: ClassReference;
    name?: string;
    href?: string;
};
export type ExtraLinkCollection = {
    extra_links?: ExtraLink[];
};
export type Timezone = string;
export type DagDetail = Dag & {
    timezone?: Timezone;
    catchup?: boolean;
    orientation?: string;
    concurrency?: number;
    start_date?: string | null;
    dag_run_timeout?: TimeDelta | null;
    doc_md?: string | null;
    default_view?: string;
    params?: object;
    end_date?: string | null;
    is_paused_upon_creation?: boolean | null;
    last_parsed?: string | null;
    template_search_path?: string[] | null;
    render_template_as_native_obj?: boolean | null;
};
export type TriggerRule =
    | 'all_success'
    | 'all_failed'
    | 'all_done'
    | 'one_success'
    | 'one_failed'
    | 'none_failed'
    | 'none_skipped'
    | 'none_failed_or_skipped'
    | 'none_failed_min_one_success'
    | 'dummy';
export type WeightRule = 'downstream' | 'upstream' | 'absolute';
export type Color = string;
export type Task = {
    class_ref?: ClassReference;
    task_id?: string;
    owner?: string;
    start_date?: string;
    end_date?: string | null;
    trigger_rule?: TriggerRule;
    extra_links?: {
        class_ref?: ClassReference;
    }[];
    depends_on_past?: boolean;
    is_mapped?: boolean;
    wait_for_downstream?: boolean;
    retries?: number;
    queue?: string | null;
    pool?: string;
    pool_slots?: number;
    execution_timeout?: TimeDelta | null;
    retry_delay?: TimeDelta | null;
    retry_exponential_backoff?: boolean;
    priority_weight?: number;
    weight_rule?: WeightRule;
    ui_color?: Color;
    ui_fgcolor?: Color;
    template_fields?: string[];
    sub_dag?: Dag;
    downstream_task_ids?: string[];
};
export type TaskCollection = {
    tasks?: Task[];
};
export type DagWarning = {
    dag_id?: string;
    warning_type?: string;
    message?: string;
    timestamp?: string;
};
export type DagWarningCollection = {
    import_errors?: DagWarning[];
} & CollectionInfo;
export type DagScheduleDatasetReference = {
    dag_id?: string | null;
    created_at?: string;
    updated_at?: string;
};
export type TaskOutletDatasetReference = {
    dag_id?: string | null;
    task_id?: string | null;
    created_at?: string;
    updated_at?: string;
};
export type Dataset = {
    id?: number;
    uri?: string;
    extra?: object | null;
    created_at?: string;
    updated_at?: string;
    consuming_dags?: DagScheduleDatasetReference[];
    producing_tasks?: TaskOutletDatasetReference[];
};
export type DatasetCollection = {
    datasets?: Dataset[];
} & CollectionInfo;
export type ConfigOption = {
    key?: string;
    value?: string;
};
export type ConfigSection = {
    name?: string;
    options?: ConfigOption[];
};
export type Config = {
    sections?: ConfigSection[];
};
export type HealthStatus = 'healthy' | 'unhealthy';
export type MetadatabaseStatus = {
    status?: HealthStatus;
};
export type SchedulerStatus = {
    status?: HealthStatus;
    latest_scheduler_heartbeat?: string | null;
};
export type HealthInfo = {
    metadatabase?: MetadatabaseStatus;
    scheduler?: SchedulerStatus;
};
export type VersionInfo = {
    version?: string;
    git_version?: string | null;
};
export type PluginCollectionItem = {
    name?: string;
    hooks?: (string | null)[];
    executors?: (string | null)[];
    macros?: (object | null)[];
    flask_blueprints?: (object | null)[];
    appbuilder_views?: (object | null)[];
    appbuilder_menu_items?: (object | null)[];
    global_operator_extra_links?: (object | null)[];
    operator_extra_links?: (object | null)[];
    source?: string | null;
};
export type PluginCollection = {
    plugins?: PluginCollectionItem[];
} & CollectionInfo;
export type Action = {
    name?: string;
};
export type Resource = {
    name?: string;
};
export type ActionResource = {
    action?: Action;
    resource?: Resource;
};
export type Role = {
    name?: string;
    actions?: ActionResource[];
};
export type RoleCollection = {
    roles?: Role[];
} & CollectionInfo;
export type ActionCollection = {
    actions?: Action[];
} & CollectionInfo;
export type UserCollectionItem = {
    first_name?: string;
    last_name?: string;
    username?: string;
    email?: string;
    active?: boolean | null;
    last_login?: string | null;
    login_count?: number | null;
    failed_login_count?: number | null;
    roles?: ({
        name?: string;
    } | null)[];
    created_on?: string | null;
    changed_on?: string | null;
};
export type UserCollection = {
    users?: UserCollectionItem[];
} & CollectionInfo;
export type User = UserCollectionItem & {
    password?: string;
};
export const {
    useGetConnectionsQuery,
    usePostConnectionMutation,
    useGetConnectionQuery,
    usePatchConnectionMutation,
    useDeleteConnectionMutation,
    useTestConnectionMutation,
    useGetDagsQuery,
    usePatchDagsMutation,
    useGetDagQuery,
    usePatchDagMutation,
    useDeleteDagMutation,
    usePostClearTaskInstancesMutation,
    useSetTaskInstanceNoteMutation,
    useSetMappedTaskInstanceNoteMutation,
    usePostSetTaskInstancesStateMutation,
    useGetDagRunsQuery,
    usePostDagRunMutation,
    useGetDagRunsBatchMutation,
    useGetDagRunQuery,
    useDeleteDagRunMutation,
    useUpdateDagRunStateMutation,
    useClearDagRunMutation,
    useGetUpstreamDatasetEventsQuery,
    useSetDagRunNoteMutation,
    useGetEventLogsQuery,
    useGetEventLogQuery,
    useGetImportErrorsQuery,
    useGetImportErrorQuery,
    useGetPoolsQuery,
    usePostPoolMutation,
    useGetPoolQuery,
    usePatchPoolMutation,
    useDeletePoolMutation,
    useGetProvidersQuery,
    useGetTaskInstancesQuery,
    useGetTaskInstanceQuery,
    usePatchTaskInstanceMutation,
    useGetMappedTaskInstanceQuery,
    usePatchMappedTaskInstanceMutation,
    useGetMappedTaskInstancesQuery,
    useGetTaskInstancesBatchMutation,
    useGetVariablesQuery,
    usePostVariablesMutation,
    useGetVariableQuery,
    usePatchVariableMutation,
    useDeleteVariableMutation,
    useGetXcomEntriesQuery,
    useGetXcomEntryQuery,
    useGetExtraLinksQuery,
    useGetLogQuery,
    useGetDagDetailsQuery,
    useGetTasksQuery,
    useGetTaskQuery,
    useGetDagSourceQuery,
    useGetDagWarningsQuery,
    useGetDatasetsQuery,
    useGetDatasetQuery,
    useGetDatasetEventsQuery,
    useGetConfigQuery,
    useGetHealthQuery,
    useGetVersionQuery,
    useGetPluginsQuery,
    useGetRolesQuery,
    usePostRoleMutation,
    useGetRoleQuery,
    usePatchRoleMutation,
    useDeleteRoleMutation,
    useGetPermissionsQuery,
    useGetUsersQuery,
    usePostUserMutation,
    useGetUserQuery,
    usePatchUserMutation,
    useDeleteUserMutation,
} = injectedRtkApi;
