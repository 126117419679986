import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const { protocol, host, port } = window.location;

export const workflowCatalogV1Api = createApi({
    reducerPath: 'workflowCatalogV1Reducer',
    baseQuery: fetchBaseQuery({
        baseUrl: `${protocol}//${host}${port.length > 0 ? '' : `:${port}`}/api/airflow/api/lexis/catalog`,
    }),
    endpoints: () => ({}),
});
