import { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Badge, Box, ClickAwayListener, Paper, Popper, Tooltip, useMediaQuery, List } from '@mui/material';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import { ThemeMode } from 'types/config';
import { useGetDagsQuery } from 'store/airflow/enhancedAirflowApi';
import WorkflowExecutionsList from './workflowExecutionsList';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem',
};

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',

    transform: 'none',
};

const WorkflowExecutionNotifications = () => {
    const [limit, setLimit] = useState(8);

    const { data: gagsData, isFetching: dagsDataIsFetching } = useGetDagsQuery({ limit });
    const dags = gagsData?.dags;
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const anchorRef = useRef<any>(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleShowMore = () => {
        setLimit(prev => prev + 8);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';
    const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }} id='workflowExecutionNotifications'>
            <Tooltip title={'Workflow Executions'}>
                <IconButton
                    color='secondary'
                    variant='light'
                    sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
                    aria-label='open profile'
                    ref={anchorRef}
                    aria-controls={open ? 'profile-grow' : undefined}
                    aria-haspopup='true'
                    onClick={handleToggle}>
                    <Badge color='primary'>
                        <AccountTreeIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? -5 : 0, 9],
                            },
                        },
                    ],
                }}>
                {({ TransitionProps }) => (
                    <Transitions
                        type='grow'
                        position={matchesXs ? 'top' : 'top-right'}
                        sx={{ overflow: 'hidden' }}
                        in={open}
                        {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: '100%',
                                minWidth: 285,
                                maxWidth: 1000,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 285,
                                },
                                maxHeight: 400,
                                overflow: 'auto',
                            }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard title='Workflows' elevation={0} border={false} content={false}>
                                    {dags?.map(dag => (
                                        <WorkflowExecutionsList dag={dag} key={dag?.dag_id} />
                                    ))}
                                    {(!dags || dags?.length === 0) && (
                                        <List
                                            component='nav'
                                            sx={{
                                                p: 0,
                                                '& .MuiListItemButton-root': {
                                                    py: 0.5,
                                                    '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                                                    '& .MuiAvatar-root': avatarSX,
                                                    '& .MuiListItemSecondaryAction-root': {
                                                        ...actionSX,
                                                        position: 'relative',
                                                    },
                                                },
                                            }}>
                                            <Typography variant='h6' margin={2}>
                                                No Workflow Executions
                                            </Typography>
                                        </List>
                                    )}
                                    <Box textAlign='center'>
                                        <LoadingButton
                                            variant='contained'
                                            loading={dagsDataIsFetching}
                                            disabled={gagsData?.total_entries! <= limit}
                                            fullWidth
                                            onClick={() => handleShowMore()}>
                                            Show more
                                        </LoadingButton>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default WorkflowExecutionNotifications;
