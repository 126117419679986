import { combineReducers } from 'redux';
import menu from './menu';
import snackbar from './snackbar';
import { airflowApi } from 'store/airflow/airflowSlice';
import { stagingApi } from 'store/staging/stagingSlice';
import { iRODSApi } from 'store/iRODS/iRODSSlice';
import selectedDatasetsTabProjectReducer from '../additional/datasets/selectedDatasetsTabProject';
import selectedDashboardTabProjectReducer from '../additional/dashboard/selectedDashboardTabProject';
import selectedWorkflowsTabProjectReducer from '../additional/workflows/selectedWorkflowsTabProject';
import selectedTransferStatusesTabProjectReducer from '../additional/transferStatuses/selectedTransferStatusesTabProject';
import selectedContainersTabProjectReducer from '../additional/applications/containers/selectedContainersTabProject';
import selectedHPCApplicationTabProjectReducer from '../additional/applications/HPCApplications/selectedHPCApplicationsTabProject';
import specificationsReducer from '../additional/specifications/specifications';
import workflowsTimerSliceReducer from '../additional/workflows/timer';
import jobscriptEditSliceReducer from '../additional/applications/customHPCJobs/jobScriptEdit';
import containerUploadReducer from '../additional/applications/containers/containerUpload';
import HPCApplicationsFilterReducer from '../additional/applications/HPCApplications/HPCApplicationsFilter';
import ContainersFilterReducer from '../additional/applications/containers/containersFilter';
import containersPaginationReducer from '../additional/applications/containers/containersPagination';
import tagColorsReducer from '../additional/projects/tagColors';
import selectedWfExTabReducer from '../additional/workflows/selectedWfExTab';
import tableOptionsReducer from '../additional/tableOptions/tableOptions';
import { transferApi } from 'store/transfer/transferSlice';
import { userOrgApi } from 'store/userOrg/userOrgSlice';
import { workflowCatalogV1Api } from 'store/workflowCatalogV1/workflowCatalogV1Slice';
import { workflowContainersApi } from 'store/workflowContainers/workflowContainersSlice';
import filelistReducer from 'store/additional/datasets/filelist';

const reducers = combineReducers({
    menu,
    snackbar,
    [airflowApi.reducerPath]: airflowApi.reducer,
    [stagingApi.reducerPath]: stagingApi.reducer,
    [iRODSApi.reducerPath]: iRODSApi.reducer,
    [transferApi.reducerPath]: transferApi.reducer,
    [userOrgApi.reducerPath]: userOrgApi.reducer,
    [workflowCatalogV1Api.reducerPath]: workflowCatalogV1Api.reducer,
    [workflowContainersApi.reducerPath]: workflowContainersApi.reducer,
    workflowsTimer: workflowsTimerSliceReducer,
    jobscriptEdit: jobscriptEditSliceReducer,
    selectedTransferStatusesTabProject: selectedTransferStatusesTabProjectReducer,
    selectedWorkflowsTabProject: selectedWorkflowsTabProjectReducer,
    selectedDatasetsTabProject: selectedDatasetsTabProjectReducer,
    selectedDashboardTabProject: selectedDashboardTabProjectReducer,
    selectedContainersTabProject: selectedContainersTabProjectReducer,
    selectedHPCApplicationTabProject: selectedHPCApplicationTabProjectReducer,
    tableOptions: tableOptionsReducer,
    specifications: specificationsReducer,
    containerUpload: containerUploadReducer,
    containersFilter: ContainersFilterReducer,
    containersPagination: containersPaginationReducer,
    HPCApplicationsFilter: HPCApplicationsFilterReducer,
    filelist: filelistReducer,
    tagColors: tagColorsReducer,
    selectedWfExTab: selectedWfExTabReducer,
});

export default reducers;
