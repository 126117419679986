import { Button, Card, CardHeader, Grid, Typography } from '@mui/material';
import useConfig from 'hooks/useConfig';
import AuthWrapper from 'sections/intro/IntroWrapper';
import { ThemeMode } from 'types/config';
import DarkDiagram from 'assets/images/intro/executionProgressDark.png';
import LightDiagram from 'assets/images/intro/executionProgressLight.png';
import { Box, Container, styled } from '@mui/system';
import { footers, technologies, usedByProjects } from '../../helpers/data/introData';
import MailIcon from '@mui/icons-material/Mail';
import exa4mindLogo from 'assets/images/intro/EXA4MIND_Logo.png';

const SuccessLink = styled('a')(({ theme }) => ({
    color: theme.palette.success.main,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'none',
        color: theme.palette.success.dark,
    },
}));

const LearnMoreButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '25px',
    padding: '25px 40px',
    fontWeight: 'bold',
    boxShadow: `0 3px 5px ${theme.palette.primary.main}`,
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.light,
    },
}));

const IntroPage = () => {
    const { mode } = useConfig();
    const lexisLinkStyle = {
        color: '#fa7818',
        textDecoration: 'none',
    };

    const exa4mindLinkStyle = {
        color: mode === ThemeMode.LIGHT ? '#0f1762' : '#9d1e7a',
        textDecoration: 'none',
    };

    return (
        <AuthWrapper>
            <Container maxWidth='xl' component='main'>
                <Grid container spacing={5} alignItems='center'>
                    <Grid item md={6}>
                        {process.env.REACT_APP_ENV === 'EUROFPDEMO' ? (
                            <>
                                <Typography variant='h1'>Welcome to the EuroFP World of complex computing</Typography>
                            </>
                        ) : (
                            <Typography variant='h1'>
                                Welcome to the{' '}
                                <a
                                    href='https://lexis-project.eu/web/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    style={lexisLinkStyle}>
                                    LEXIS
                                </a>{' '}
                                Platform World of complex computing
                            </Typography>
                        )}

                        <Box sx={{ mt: 10, ml: 25 }}>
                            {' '}
                            <a href='https://docs.lexis.tech/' target='_blank' rel='noreferrer'>
                                <LearnMoreButton>Learn More</LearnMoreButton>
                            </a>
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        <img
                            src={mode === ThemeMode.LIGHT ? DarkDiagram : LightDiagram}
                            alt='LEXIS Image'
                            style={{ maxWidth: '100%', height: 'auto', borderRadius: '30px' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Container disableGutters maxWidth='md' component='main' sx={{ pt: 15 }}>
                            <Grid
                                container
                                spacing={3}
                                margin='auto'
                                marginBottom={10}
                                maxWidth='90vh'
                                textAlign='center'>
                                <Grid item xs={12} marginBottom={5}>
                                    <Typography variant='h3'>
                                        The{' '}
                                        <a
                                            href='https://lexis-project.eu/web/'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            style={lexisLinkStyle}>
                                            LEXIS
                                        </a>{' '}
                                        Platform for advanced computing, big data, and cloud
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} marginBottom={5}>
                                    <Typography variant='h4'>
                                        The{' '}
                                        <a
                                            href='https://lexis-project.eu/web/'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            style={lexisLinkStyle}>
                                            LEXIS
                                        </a>{' '}
                                        Platform aims to overcome the shortcomings of complicated access to a
                                        high-performance computing (HPC) system and management of terabyte-scale
                                        datasets by providing a clean and concise interface which will abstract both HPC
                                        and cloud resources using workflow and data management orchestration.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='h4'>
                                        The{' '}
                                        <a
                                            href='https://exa4mind.eu/'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            style={exa4mindLinkStyle}>
                                            EXA4MIND
                                        </a>{' '}
                                        project (Extreme Analytics for Mining Data spaces, Horizon Europe GA 101092944)
                                        will develop the Extreme Data Database (EDD), extending on the potential of the{' '}
                                        <a
                                            href='https://lexis-project.eu/web/'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            style={lexisLinkStyle}>
                                            LEXIS
                                        </a>{' '}
                                        DDI in terms of adaptivity. The EDD will feature a wealth of distributed data
                                        solutions, including top-notch SQL and noSQL database management systems.
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent='center' alignItems='center' textAlign='center'>
                                <iframe
                                    width='560'
                                    height='315'
                                    src='https://www.youtube.com/embed/Vgz0BF-0TTs?autoplay=0'
                                    title='YouTube video player'
                                    allowFullScreen
                                />
                            </Grid>
                            <Grid
                                container
                                justifyContent='center'
                                alignItems='center'
                                textAlign='center'
                                marginTop={5}>
                                <a
                                    href='https://exa4mind.eu/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    style={exa4mindLinkStyle}>
                                    <img width='40%' src={exa4mindLogo} alt='' />
                                </a>{' '}
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
            </Container>
            <Container
                maxWidth='lg'
                component='main'
                sx={{
                    borderTop: theme => `1px solid ${theme.palette.divider}`,
                    mt: 8,
                    py: [3, 6],
                }}>
                <Typography variant='h4' marginTop={5} marginBottom={5} align='center'>
                    Used by
                </Typography>
                <Grid container spacing={5} alignItems='flex-end'>
                    {usedByProjects.map(project => (
                        <Grid item key={project.url} xs={12} sm={6} md={4}>
                            <Card>
                                <CardHeader
                                    title={
                                        <a href={project.url} target='_blank' rel='noreferrer'>
                                            <img
                                                width={project.logoWidth}
                                                height={project.logoHeight}
                                                src={project.logo}
                                                alt=''
                                            />
                                        </a>
                                    }
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        minHeight: '12rem',
                                        backgroundColor: theme => theme.palette.secondary.main,
                                    }}
                                />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Container
                maxWidth='xl'
                component='footer'
                sx={{
                    borderTop: theme => `1px solid ${theme.palette.divider}`,
                    mt: 8,
                    py: [3, 6],
                }}>
                <Typography variant='h4' marginTop={5} marginBottom={5} align={'center'}>
                    Technologies
                </Typography>
                <Grid container spacing={6} alignItems='flex-end'>
                    {technologies.map(technology => (
                        <Grid item key={technology.url} xs={12} sm={2} md={2}>
                            <Card>
                                <CardHeader
                                    title={
                                        <a href={technology.url} target='_blank' rel='noreferrer'>
                                            <img
                                                width={technology.logoWidth}
                                                height={technology.logoHeight}
                                                src={technology.logo}
                                                alt=''
                                            />
                                        </a>
                                    }
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        minHeight: '7rem',
                                        minWidth: '7rem',
                                        backgroundColor: theme => theme.palette.secondary.main,
                                    }}
                                />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Container
                maxWidth='xl'
                component='footer'
                sx={{
                    borderTop: theme => `1px solid ${theme.palette.divider}`,
                    mt: 8,
                    py: [3, 6],
                }}>
                <Grid container spacing={8} justifyContent='space-evenly'>
                    {footers.map(footer => (
                        <Grid item xs={6} sm={3} key={footer.title}>
                            {footer.title && (
                                <Typography variant='h6' gutterBottom>
                                    {footer.title}
                                </Typography>
                            )}
                            {footer.mail && (
                                <SuccessLink href={`mailto: ${footer.mail}`}>
                                    <MailIcon /> <Typography component={'span'}>{footer.mail}</Typography>
                                </SuccessLink>
                            )}
                            {footer.link && footer.linkTitle && (
                                <SuccessLink href={footer.link} target='_blank' rel='noreferrer'>
                                    <Typography component={'span'}>{footer.linkTitle}</Typography>
                                </SuccessLink>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </AuthWrapper>
    );
};

export default IntroPage;
