import { Link } from 'react-router-dom';
import { To } from 'history';
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';
import HeaderLogo from './HeaderLogoMain';
import HeaderLogoIcon from './HeaderLogoIcon';
import { APP_DEFAULT_PATH } from 'config';

interface Props {
    reverse?: boolean;
    isIcon?: boolean;
    sx?: SxProps;
    to?: To;
    disabled?: boolean;
}

const HeaderLogoSection = ({ reverse, isIcon, sx, to, disabled }: Props) => (
    <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to} disabled={disabled} sx={sx}>
        {isIcon ? <HeaderLogoIcon /> : <HeaderLogo reverse={reverse} />}
    </ButtonBase>
);

export default HeaderLogoSection;
