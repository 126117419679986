import { createSlice } from '@reduxjs/toolkit';

const initialState = { projectShortName: localStorage.getItem('selectedWorkflowsTabProject') ? localStorage.getItem('selectedWorkflowsTabProject') : undefined };

const selectedWorkflowsTabProjectSlice = createSlice({
    name: 'selectedWorkflowsTabProject',
    initialState,
    reducers: {
        setSelectedWorkflowsTabProject: (state, action) => {
            state.projectShortName = action.payload;
            localStorage.setItem('selectedWorkflowsTabProject', action.payload);
        },

        resetSelectedWorkflowsTabProject: state => {
            state.projectShortName = undefined;
            localStorage.removeItem('selectedWorkflowsTabProject');
        },
    },
});

export const { setSelectedWorkflowsTabProject, resetSelectedWorkflowsTabProject } =
    selectedWorkflowsTabProjectSlice.actions;
export default selectedWorkflowsTabProjectSlice.reducer;
