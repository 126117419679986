import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Badge,
    Box,
    ClickAwayListener,
    Divider,
    List,
    Paper,
    Popper,
    useMediaQuery,
    Tooltip,
    ButtonGroup,
    Button,
} from '@mui/material';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import { ThemeMode } from 'types/config';
import { Typography } from '@mui/material';
import {
    useGetApiRequestsQuery,
    usePutApiRequestsByRequestIdAcceptMutation,
    usePutApiRequestsByRequestIdRejectMutation,
} from 'store/userOrg/enhancedUserOrgApi';
import { permissions } from 'helpers/data/permissions';
import useCheckPermission from 'hooks/useCheckPermission';
import { toast } from 'react-toastify';
import KeyIcon from '@mui/icons-material/Key';

const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem',
};

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',

    transform: 'none',
};

const AccessRequestNotifications = () => {
    const [accept] = usePutApiRequestsByRequestIdAcceptMutation();
    const [reject] = usePutApiRequestsByRequestIdRejectMutation();
    const { data: accessRequests, isLoading } = useGetApiRequestsQuery({}, { pollingInterval: 60000 });
    const perms = useCheckPermission();
    const allowedAccessRequests =
        Array.isArray(accessRequests) &&
        accessRequests?.filter(
            request =>
                perms(permissions.prjUserAssignment, request.ProjectShortName) &&
                request.State !== 4 &&
                request.State !== 5 &&
                request.Type === 1
        );

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const anchorRef = useRef<any>(null);
    const [active, setActive] = useState(Array.isArray(allowedAccessRequests) && allowedAccessRequests?.length);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setActive(Array.isArray(allowedAccessRequests) ? allowedAccessRequests?.length : 0);
    }, [allowedAccessRequests]);

    const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';
    const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

    const onAccept = (requestId: string | null | undefined) => {
        requestId &&
            accept({ requestId: requestId })
                .unwrap()
                .then(() => {
                    toast.success('Accepted', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        theme: 'colored',
                        autoClose: 1000,
                    });
                });
    };

    const onReject = (requestId: string | null | undefined) => {
        requestId &&
            reject({ requestId: requestId })
                .unwrap()
                .then(() => {
                    toast.warning('Rejected', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        theme: 'colored',
                        autoClose: 1000,
                    });
                });
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }} id='accessRequestNotifications'>
            <Tooltip title={'Project Access Requests'}>
                <IconButton
                    color='secondary'
                    variant='light'
                    sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
                    aria-label='open profile'
                    ref={anchorRef}
                    aria-controls={open ? 'profile-grow' : undefined}
                    aria-haspopup='true'
                    disabled={isLoading}
                    onClick={handleToggle}>
                    <Badge badgeContent={active} color='primary'>
                        <KeyIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? -5 : 0, 9],
                            },
                        },
                    ],
                }}>
                {({ TransitionProps }) => (
                    <Transitions
                        type='grow'
                        position={matchesXs ? 'top' : 'top-right'}
                        sx={{ overflow: 'hidden' }}
                        in={open}
                        {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: '100%',
                                minWidth: 285,
                                maxWidth: 420,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 285,
                                },
                                maxHeight: 500,
                                overflow: 'auto',
                            }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard title='Access Requests' elevation={0} border={false} content={false}>
                                    {Array.isArray(allowedAccessRequests) &&
                                        allowedAccessRequests?.map(request => (
                                            <Box margin={3}>
                                                <Typography variant='h6' textAlign='center'>
                                                    User: {request.Email}
                                                </Typography>
                                                <Typography variant='h6' textAlign='center'>
                                                    Project: {request.ProjectShortName}
                                                </Typography>
                                                <Box textAlign='center' margin={1}>
                                                    <ButtonGroup size='small'>
                                                        <Button
                                                            variant='contained'
                                                            color='success'
                                                            onClick={() => onAccept(request.RequestId)}>
                                                            Accept
                                                        </Button>
                                                        <Button
                                                            variant='contained'
                                                            color='warning'
                                                            onClick={() => onReject(request.RequestId)}>
                                                            Reject
                                                        </Button>
                                                    </ButtonGroup>
                                                </Box>
                                                <Divider />
                                            </Box>
                                        ))}
                                    {(!allowedAccessRequests || allowedAccessRequests?.length === 0) && (
                                        <List
                                            component='nav'
                                            sx={{
                                                p: 0,
                                                '& .MuiListItemButton-root': {
                                                    py: 0.5,
                                                    '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                                                    '& .MuiAvatar-root': avatarSX,
                                                    '& .MuiListItemSecondaryAction-root': {
                                                        ...actionSX,
                                                        position: 'relative',
                                                    },
                                                },
                                            }}>
                                            <Typography variant='h6' margin={2}>
                                                No Access Requests{' '}
                                            </Typography>
                                        </List>
                                    )}
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default AccessRequestNotifications;
