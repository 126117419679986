import { LinearProgressProps } from '@mui/material/LinearProgress';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

export interface LoaderProps extends LinearProgressProps {}

const Loader = () => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        }}>
        <CircularProgress color='inherit' />
    </Box>
);

export default Loader;
