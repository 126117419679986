import { useLayoutEffect } from 'react';
import { Tour } from 'antd';
import type { TourStepProps } from 'antd';
import useCheckPermission from 'hooks/useCheckPermission';
import { permissions } from 'helpers/data/permissions';
import { useGetApiUserPreferencesQuery, usePutApiUserPreferencesMutation } from 'store/userOrg/enhancedUserOrgApi';
import useConfig from 'hooks/useConfig';

interface ExtendedTourStepProps extends TourStepProps {
    permission?: string;
}

const steps: ExtendedTourStepProps[] = [
    {
        title: 'Welcome',
        description: 'Welcome to the LEXIS Platform.',
        target: null,
    },
    {
        title: 'Main menu',
        description: 'This is the main menu. Here you can see several items. I will tell more about them in next steps',
        placement: 'right',
        target: () => document.getElementById('menu') || document.body,
    },
    {
        title: 'Dashboard',
        description: 'Here you can find main information about your projects, workflows and datasets',
        placement: 'right',
        target: () => document.getElementById('dashboard') || document.body,
    },
    {
        title: 'Datasets',
        description: 'Here you can upload and see different types of datasets',
        placement: 'right',
        target: () => document.getElementById('datasets') || document.body,
    },
    {
        title: 'Applications',
        description: 'You can Custom Workflows upload datasets or check all public datasets and workflows',
        placement: 'right',
        target: () => document.getElementById('applications') || document.body,
    },
    {
        title: 'Workflows',
        description: 'You can create your Workflow and check your workflow execution',
        placement: 'right',
        target: () => document.getElementById('workflows') || document.body,
    },
    {
        title: 'Projects',
        description: 'You can create your project or request project access ',
        placement: 'right',
        target: () => document.getElementById('projects') || document.body,
    },
    {
        permission: permissions.locList,
        title: 'Locations',
        description: 'You can manage your locations',
        placement: 'right',
        target: () => document.getElementById('locations') || document.body,
    },
    {
        permission: permissions.resList,
        title: 'Resource managment',
        description: 'You can manage your resources and assignments ',
        placement: 'right',
        target: () => document.getElementById('resourceManagement') || document.body,
    },
    {
        title: 'Documentation',
        description: 'You can check documentation for more information',
        placement: 'right',
        target: () => document.getElementById('documentation') || document.body,
    },

    {
        title: 'Project filter',
        description: 'You can check your project filter notifications',
        placement: 'bottom',
        target: () => document.getElementById('globalFilterNotifications') || document.body,
    },
    {
        title: 'Download results',
        description: 'You can check your download results notifications',
        placement: 'bottom',
        target: () => document.getElementById('downloadResultNotifications') || document.body,
    },
    {
        title: 'Workflow Executions',
        description: 'You can check your workflow executions notifications',
        placement: 'bottom',
        target: () => document.getElementById('workflowExecutionNotifications') || document.body,
    },
    {
        title: 'Project Access Requests',
        description: 'You can check project access requests notifications',
        placement: 'bottom',
        target: () => document.getElementById('accessRequestNotifications') || document.body,
    },
    {
        title: 'User Settings',
        description: 'Here you can find information about the user and start this guide again',
        placement: 'bottom',
        target: () => document.getElementById('userSettings') || document.body,
    },
];

const BeginTour = () => {
    const { firstStart, showGuide, onChangeShowGuide } = useConfig();

    const [putPreferences] = usePutApiUserPreferencesMutation();
    const { data: preferences } = useGetApiUserPreferencesQuery();

    const preferencesObj = preferences ? JSON.parse(preferences) : {};

    const checkPerms = useCheckPermission();

    const filteredSteps = steps?.filter(step => (step.permission ? checkPerms(step.permission, '') : step));

    useLayoutEffect(() => {
        !firstStart ? onChangeShowGuide(true) : onChangeShowGuide(false);
    }, [firstStart]);

    const changeShowGuide = () =>
        !preferencesObj?.firstStart &&
        putPreferences({
            body: {
                ...preferencesObj,
                firstStart: true,
            },
        });

    return (
        <>
            <Tour
                open={showGuide}
                onClose={() => {
                    onChangeShowGuide(false);
                    changeShowGuide();
                }}
                steps={filteredSteps}
                onFinish={() => changeShowGuide()}
            />
        </>
    );
};

export default BeginTour;
