//material-ui
import { styled } from '@mui/material/styles';

// third-party
import { SnackbarProvider } from 'notistack';

// project import
import { useSelector } from 'store';

// assets
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoIcon from '@mui/icons-material/Info';
// custom styles
const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
    '&.notistack-MuiContent-default': {
        backgroundColor: theme.palette.primary.main,
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: theme.palette.error.main,
    },
    '&.notistack-MuiContent-success': {
        backgroundColor: theme.palette.success.main,
    },
    '&.notistack-MuiContent-info': {
        backgroundColor: theme.palette.info.main,
    },
    '&.notistack-MuiContent-warning': {
        backgroundColor: theme.palette.warning.main,
    },
}));

// ===========================|| SNACKBAR - NOTISTACK ||=========================== //

const Notistack = ({ children }: any) => {
    const snackbar = useSelector(state => state.snackbar);

    return (
        <StyledSnackbarProvider
            maxSnack={snackbar.maxStack}
            dense={snackbar.dense}
            iconVariant={
                snackbar.iconVariant === 'useemojis'
                    ? {
                          success: <CheckCircleOutlineIcon />,
                          error: <HighlightOffIcon />,
                          warning: <WarningAmberIcon />,
                          info: <InfoIcon />,
                      }
                    : undefined
            }
            hideIconVariant={snackbar.iconVariant === 'hide' ? true : false}>
            {children}
        </StyledSnackbarProvider>
    );
};

export default Notistack;
