import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';
import NavGroup from './NavGroup';
import menuItems from 'menu-items';
import { useSelector } from 'store';
import useConfig from 'hooks/useConfig';
import { HORIZONTAL_MAX_ITEM } from 'config';
import { NavItemType } from 'types/menu';
import { MenuOrientation } from 'types/config';
import useCheckPermission from 'hooks/useCheckPermission';

const Navigation = () => {
    const theme = useTheme();
    const downLG = useMediaQuery(theme.breakpoints.down('lg'));
    const checkPermission = useCheckPermission();
    const { menuOrientation } = useConfig();
    const { drawerOpen } = useSelector(state => state.menu);
    const [selectedItems, setSelectedItems] = useState<string | undefined>('');
    const [selectedLevel, setSelectedLevel] = useState<number>(0);

    const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

    const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
    let lastItemIndex = menuItems.items.length - 1;
    let remItems: NavItemType[] = [];
    let lastItemId: string;

    if (lastItem && lastItem < menuItems.items.length) {
        lastItemId = menuItems.items[lastItem - 1].id!;
        lastItemIndex = lastItem - 1;
        remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map(item => ({
            title: item.title,
            elements: item.children,
            icon: item.icon,
        }));
    }

    const filteredItems = menuItems.items
        .filter(item => !item.permissions || item.permissions.some(p => checkPermission(p, '')))
        .map(item => ({
            ...item,
            children: item.children?.filter(
                item => !item.permissions || item.permissions.some(p => checkPermission(p, ''))
            ),
        }));

    const navGroups = filteredItems.slice(0, lastItemIndex + 1).map(item => {
        switch (item.type) {
            case 'group':
                return (
                    <NavGroup
                        key={item.id}
                        setSelectedItems={setSelectedItems}
                        setSelectedLevel={setSelectedLevel}
                        selectedLevel={selectedLevel}
                        selectedItems={selectedItems}
                        lastItem={lastItem!}
                        remItems={remItems}
                        lastItemId={lastItemId}
                        item={item}
                    />
                );
            default:
                return (
                    <Typography key={item.id} variant='h6' color='error' align='center'>
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return (
        <Box id='menu'
            sx={{
                pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
                '& > ul:first-of-type': { mt: 0 },
                display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block',
            }}>
            {navGroups}
        </Box>
    );
};

export default Navigation;
