import { ListingApiArg, ListingApiResponse, QueryDatasetsApiArg, QueryDatasetsApiResponse, iRODS } from './iRODSApi';

const extendedIRODSApi = iRODS.injectEndpoints({
    endpoints: build => ({
        getMetadata: build.query<QueryDatasetsApiResponse, QueryDatasetsApiArg>({
            query: queryArg => ({
                url: `/dataset/search/metadata`,
                params: queryArg.metadataQuery,
            }),
        }),
        getListing: build.query<ListingApiResponse, ListingApiArg>({
            query: queryArg => ({
                url: `/dataset/listing`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetMetadataQuery, useGetListingQuery } = extendedIRODSApi;
