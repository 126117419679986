import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types/menu';
import {
    workflowsDashboardPath,
    workflowsPath,
    projectsPath,
    containersPath,
    uploadDataSetsPath,
    workflowDataSetsPath,
    HPCApplicationsPath,
    publicDataSetsPath,
    downloadTransferStatusesDashboardPath,
    uploadTransferStatusesDashboardPath,
    customHPCJobsPath,
    userWorkflowsPath,
} from 'routes/paths';

import PieChartIcon from '@mui/icons-material/PieChart';
import StorageIcon from '@mui/icons-material/Storage';
import ArticleIcon from '@mui/icons-material/Article';
import GridViewIcon from '@mui/icons-material/GridView';
import UploadIcon from '@mui/icons-material/Upload';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShareIcon from '@mui/icons-material/Share';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
const icons = {
    StorageIcon,
    PieChartIcon,
    ArticleIcon,
    GridViewIcon,
    UploadIcon,
    AccountTreeIcon,
    FilterDramaIcon,
    SwapHorizIcon,
    ListAltIcon,
    ShareIcon,
    CloudUploadIcon,
    CloudDownloadIcon,
    DashboardIcon,
    DescriptionIcon,
};

const menu: NavItemType = {
    id: 'menu',
    title: <FormattedMessage id='menu' />,
    type: 'group',

    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id='dashboard' />,
            type: 'collapse',
            icon: icons.PieChartIcon,

            children: [
                // {
                //     id: 'generalDashboard',
                //     title: <FormattedMessage id='general' />,
                //     type: 'item',
                //     url: generalDashboardPath(),
                //     icon: icons.DashboardIcon,
                // },
                {
                    id: 'workflowsDashboard',
                    title: <FormattedMessage id='workflows' />,
                    type: 'item',
                    url: workflowsDashboardPath(),
                    icon: icons.AccountTreeIcon,
                },
                {
                    id: 'dataOperations',
                    title: <FormattedMessage id='dataOperations' />,
                    type: 'collapse',
                    icon: icons.SwapHorizIcon,
                    children: [
                        {
                            id: 'uploadTransferStatusesDashboard',
                            title: <FormattedMessage id='uploadTransferStatusesDashboard' />,
                            type: 'item',
                            url: uploadTransferStatusesDashboardPath(),
                            icon: icons.CloudUploadIcon,
                        },
                        {
                            id: 'downloadTransferStatusesDashboard',
                            title: <FormattedMessage id='downloadTransferStatusesDashboard' />,
                            type: 'item',
                            url: downloadTransferStatusesDashboardPath(),
                            icon: icons.CloudDownloadIcon,
                        },
                    ],
                },
            ],
        },
        {
            id: 'datasets',
            title: <FormattedMessage id='datasets' />,
            type: 'collapse',
            icon: icons.StorageIcon,
            children: [
                {
                    id: 'uploadDataSets',
                    title: <FormattedMessage id='uploads' />,
                    type: 'item',
                    url: uploadDataSetsPath(),
                    icon: icons.UploadIcon,
                },
                {
                    id: 'workflowDataSets',
                    title: <FormattedMessage id='workflows' />,
                    type: 'item',
                    url: workflowDataSetsPath(),
                    icon: icons.AccountTreeIcon,
                },
                {
                    id: 'publicDataSets',
                    title: <FormattedMessage id='publicDataSets' />,
                    type: 'item',
                    url: publicDataSetsPath(),
                    icon: icons.ShareIcon,
                },
            ],
        },

        {
            id: 'applications',
            title: <FormattedMessage id='applications' />,
            type: 'collapse',
            icon: icons.GridViewIcon,
            children: [
                {
                    id: 'containers',
                    title: <FormattedMessage id='containers' />,
                    type: 'item',
                    url: containersPath(),
                    icon: icons.FilterDramaIcon,
                },
                // {
                //     id: 'workflowTemplates',
                //     title: <FormattedMessage id='workflowTemplates' />,
                //     type: 'item',
                //     url: workflowTemplatesPath(),
                //     icon: icons.AccountTreeIcon,
                // },
                {
                    id: 'HPCApplications',
                    title: <FormattedMessage id='HPCApplications' />,
                    type: 'item',
                    url: HPCApplicationsPath(),
                    icon: icons.ListAltIcon,
                },
                {
                    id: 'userWorkflows',
                    title: <FormattedMessage id='userWorkflows' />,
                    type: 'item',
                    url: userWorkflowsPath(),
                    icon: icons.AccountTreeIcon,
                },
                {
                    id: 'customHPCJobs',
                    title: <FormattedMessage id='customHPCJobs' />,
                    type: 'item',
                    url: customHPCJobsPath(),
                    icon: icons.DescriptionIcon,
                },
            ],
        },
        {
            id: 'workflows',
            title: <FormattedMessage id='workflows' />,
            type: 'item',
            url: workflowsPath(),
            icon: icons.AccountTreeIcon,
        },
        {
            id: 'projects',
            title: <FormattedMessage id='projects' />,
            type: 'item',
            url: projectsPath(),
            icon: icons.ArticleIcon,
        },
    ],
};

export default menu;
