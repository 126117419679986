import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const { protocol, host, port } = window.location;

export const workflowContainersApi = createApi({
    reducerPath: 'workflowContainersReducer',
    baseQuery: fetchBaseQuery({
        baseUrl: `${protocol}//${host}${port.length > 0 ? '' : `:${port}`}/api/airflow/api/lexis`,
    }),
    endpoints: () => ({}),
});
