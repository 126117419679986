import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Badge,
    Box,
    ClickAwayListener,
    List,
    ListItemText,
    ListItemSecondaryAction,
    Paper,
    Popper,
    useMediaQuery,
    Tooltip,
    MenuItem,
    ListItemIcon,
} from '@mui/material';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import { ThemeMode } from 'types/config';
import { useGetTransferStatusByRequestIdQuery, useGetTransferStatusQuery } from 'store/transfer/transferApi';
import { Typography } from '@mui/material';
import { downloadFile } from 'pages/dashboard/transferStatuses/transferStatusesHelpers';
import DownloadIcon from '@mui/icons-material/Download';

const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem',
};

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',

    transform: 'none',
};

const DownloadResultsNotifications = () => {
    const [requestId, setRequestId] = useState('');

    const getTransferStatuses = useGetTransferStatusQuery(void [], { pollingInterval: 60000 });
    const transferStatuses = getTransferStatuses?.data as any[];

    const { data: transferStatus } = useGetTransferStatusByRequestIdQuery(
        {
            requestId: requestId ?? '',
        },
        { pollingInterval: 2000, skip: !requestId }
    );

    useEffect(() => {
        transferStatus && downloadFile(transferStatus);
        setRequestId('');
    }, [transferStatus]);

    const activeDownloadTransferStatuses = transferStatuses
        ?.filter(
            trStatus =>
                trStatus.task_state === 'SUCCESS' &&
                trStatus.transfer_type === 'download' &&
                new Date(new Date(trStatus.created_at).setDate(new Date(trStatus.created_at).getDate() + 7)) >=
                    new Date()
        )
        ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const anchorRef = useRef<any>(null);
    const [active, setActive] = useState(activeDownloadTransferStatuses?.length);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setActive(activeDownloadTransferStatuses?.length);
    }, [transferStatuses, activeDownloadTransferStatuses]);

    const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';
    const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }} id='downloadResultNotifications'>
            <Tooltip title={'Download results'}>
                <IconButton
                    color='secondary'
                    variant='light'
                    sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
                    aria-label='open profile'
                    ref={anchorRef}
                    aria-controls={open ? 'profile-grow' : undefined}
                    aria-haspopup='true'
                    disabled={getTransferStatuses.isLoading}
                    onClick={handleToggle}>
                    <Badge badgeContent={active} color='primary'>
                        <DownloadIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? -5 : 0, 9],
                            },
                        },
                    ],
                }}>
                {({ TransitionProps }) => (
                    <Transitions
                        type='grow'
                        position={matchesXs ? 'top' : 'top-right'}
                        sx={{ overflow: 'hidden' }}
                        in={open}
                        {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: '100%',
                                minWidth: 285,
                                maxWidth: 600,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 285,
                                },
                                maxHeight: 500,
                                overflow: 'auto',
                            }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    title='Download Transfer Statuses'
                                    elevation={0}
                                    border={false}
                                    content={false}>
                                    {activeDownloadTransferStatuses?.map(activeTransferStatus => (
                                        <MenuItem
                                            key={`${activeTransferStatus.project}-${activeTransferStatus.dataset_id}-${activeTransferStatus.request_id}`}
                                            onClick={() => setRequestId(activeTransferStatus.request_id)}>
                                            <ListItemIcon>
                                                <DownloadIcon color='success' />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography noWrap variant='body2'>
                                                        {activeTransferStatus?.filename?.length
                                                            ? activeTransferStatus.filename.substring(
                                                                  activeTransferStatus.filename.lastIndexOf('/') + 1
                                                              )
                                                            : 'file'}
                                                    </Typography>
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography variant='caption' noWrap>
                                                    {new Date(activeTransferStatus.created_at).toLocaleDateString()},
                                                    {new Date(activeTransferStatus.created_at).getHours()} :
                                                    {new Date(activeTransferStatus.created_at).getMinutes()}
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </MenuItem>
                                    ))}
                                    {(!activeDownloadTransferStatuses ||
                                        activeDownloadTransferStatuses?.length === 0) && (
                                        <List
                                            component='nav'
                                            sx={{
                                                p: 0,
                                                '& .MuiListItemButton-root': {
                                                    py: 0.5,
                                                    '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                                                    '& .MuiAvatar-root': avatarSX,
                                                    '& .MuiListItemSecondaryAction-root': {
                                                        ...actionSX,
                                                        position: 'relative',
                                                    },
                                                },
                                            }}>
                                            <Typography variant='h6' margin={2}>
                                                No Download Results
                                            </Typography>
                                        </List>
                                    )}
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default DownloadResultsNotifications;
