import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    projectShortName: localStorage.getItem('selectedTransferStatusesTabProject')
        ? localStorage.getItem('selectedTransferStatusesTabProject')
        : undefined,
};

const selectedTransferStatusesTabProjectSlice = createSlice({
    name: 'selectedTransferStatusesTabProject',
    initialState,
    reducers: {
        setSelectedTransferStatusesTabProject: (state, action) => {
            state.projectShortName = action.payload;
            localStorage.setItem('selectedTransferStatusesTabProject', action.payload);
        },

        resetSelectedTransferStatusesTabProject: state => {
            state.projectShortName = undefined;
            localStorage.removeItem('selectedTransferStatusesTabProject');
        },
    },
});

export const { setSelectedTransferStatusesTabProject, resetSelectedTransferStatusesTabProject } =
    selectedTransferStatusesTabProjectSlice.actions;
export default selectedTransferStatusesTabProjectSlice.reducer;
