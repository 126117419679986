import { CircularProgress, FormControl, FormControlLabel, FormLabel, Switch } from '@mui/material';
import useConfig from 'hooks/useConfig';
import { usePutApiUserPreferencesMutation, useGetApiUserPreferencesQuery } from 'store/userOrg/enhancedUserOrgApi';
import { ThemeMode } from 'types/config';

const ModeSelection = () => {
    const { mode } = useConfig();
    const { data: preferences, isLoading: preferencesIsLoading } = useGetApiUserPreferencesQuery();
    const [putPreferences, { isLoading: putPreferencesIsLoading }] = usePutApiUserPreferencesMutation();

    const preferencesObj = preferences ? JSON.parse(preferences) : {};

    return (
        <>
            <FormControl>
                <FormLabel>Mode</FormLabel>
                {preferencesIsLoading || putPreferencesIsLoading ? (
                    <CircularProgress />
                ) : (
                    <FormControlLabel
                        control={
                            <Switch
                                onClick={() =>
                                    putPreferences({
                                        body: {
                                            ...preferencesObj,
                                            mode:
                                                preferencesObj?.mode === ThemeMode.LIGHT
                                                    ? ThemeMode.DARK
                                                    : ThemeMode.LIGHT,
                                        },
                                    })
                                }
                                checked={mode === ThemeMode.DARK}
                            />
                        }
                        label={mode === ThemeMode.DARK ? 'Dark Mode on' : 'Light Mode on'}
                    />
                )}
            </FormControl>
        </>
    );
};

export default ModeSelection;
