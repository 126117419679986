import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    projectShortName: localStorage.getItem('selectedDashboardTabProject')
        ? localStorage.getItem('selectedDashboardTabProject')
        : undefined,
};

const selectedDashboardTabProjectSlice = createSlice({
    name: 'selectedDashboardTabProject',
    initialState,
    reducers: {
        setSelectedDashboardTabProject: (state, action) => {
            state.projectShortName = action.payload;
            localStorage.setItem('selectedDashboardTabProject', action.payload);
        },

        resetSelectedDashboardTabProject: state => {
            state.projectShortName = undefined;
            localStorage.removeItem('selectedDashboardTabProject');
        },
    },
});

export const { setSelectedDashboardTabProject, resetSelectedDashboardTabProject } =
    selectedDashboardTabProjectSlice.actions;
export default selectedDashboardTabProjectSlice.reducer;
