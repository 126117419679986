import { petApi } from './airflowApi';

export const enhancedAirflowApi = petApi.enhanceEndpoints({
    addTagTypes: ['GetDagRuns', 'GetDags'],
    endpoints: {
        getDags: {
            providesTags: ['GetDags'], //airflow catalog should be added to airflow api at first.
        },
        getDagRuns: {
            providesTags: ['GetDagRuns'],
        },
        postDagRun: {
            invalidatesTags: ['GetDagRuns'],
        },
    },
});

export const {
    useGetConnectionsQuery,
    usePostConnectionMutation,
    useGetConnectionQuery,
    usePatchConnectionMutation,
    useDeleteConnectionMutation,
    useTestConnectionMutation,
    useGetDagsQuery,
    usePatchDagsMutation,
    useGetDagQuery,
    usePatchDagMutation,
    useDeleteDagMutation,
    usePostClearTaskInstancesMutation,
    useSetTaskInstanceNoteMutation,
    useSetMappedTaskInstanceNoteMutation,
    usePostSetTaskInstancesStateMutation,
    useGetDagRunsQuery,
    usePostDagRunMutation,
    useGetDagRunsBatchMutation,
    useGetDagRunQuery,
    useDeleteDagRunMutation,
    useUpdateDagRunStateMutation,
    useClearDagRunMutation,
    useGetUpstreamDatasetEventsQuery,
    useSetDagRunNoteMutation,
    useGetEventLogsQuery,
    useGetEventLogQuery,
    useGetImportErrorsQuery,
    useGetImportErrorQuery,
    useGetPoolsQuery,
    usePostPoolMutation,
    useGetPoolQuery,
    usePatchPoolMutation,
    useDeletePoolMutation,
    useGetProvidersQuery,
    useGetTaskInstancesQuery,
    useGetTaskInstanceQuery,
    usePatchTaskInstanceMutation,
    useGetMappedTaskInstanceQuery,
    usePatchMappedTaskInstanceMutation,
    useGetMappedTaskInstancesQuery,
    useGetTaskInstancesBatchMutation,
    useGetVariablesQuery,
    usePostVariablesMutation,
    useGetVariableQuery,
    usePatchVariableMutation,
    useDeleteVariableMutation,
    useGetXcomEntriesQuery,
    useGetXcomEntryQuery,
    useGetExtraLinksQuery,
    useGetLogQuery,
    useGetDagDetailsQuery,
    useGetTasksQuery,
    useGetTaskQuery,
    useGetDagSourceQuery,
    useGetDagWarningsQuery,
    useGetDatasetsQuery,
    useGetDatasetQuery,
    useGetDatasetEventsQuery,
    useGetConfigQuery,
    useGetHealthQuery,
    useGetVersionQuery,
    useGetPluginsQuery,
    useGetRolesQuery,
    usePostRoleMutation,
    useGetRoleQuery,
    usePatchRoleMutation,
    useDeleteRoleMutation,
    useGetPermissionsQuery,
    useGetUsersQuery,
    usePostUserMutation,
    useGetUserQuery,
    usePatchUserMutation,
    useDeleteUserMutation,
} = enhancedAirflowApi;
