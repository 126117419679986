import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const tagColorsSlice = createSlice({
    name: 'tagColors',
    initialState,
    reducers: {
        assignTagColor: (state, action) => {
            const { tagName, bgColor, textColor, iconColor } = action.payload;
            state[tagName] = { bgColor, textColor, iconColor };
        },
        removeTagColor: (state, action) => {
            const { tagName } = action.payload;
            delete state[tagName];
        },
    },
});

export const { assignTagColor, removeTagColor } = tagColorsSlice.actions;
export default tagColorsSlice.reducer;
