import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    runTimer: true,
    timer: 30,
};

const workflowsTimerSlice = createSlice({
    name: 'workflowsTimer',
    initialState,
    reducers: {
        setWorkflowsRunTimer: (state, action) => {
            state.runTimer = action.payload;
        },
        setWorkflowsTimer: (state, action) => {
            state.timer = action.payload;
        },
        resetWorkflowsTimer: state => {
            state.timer = 30;
        },
    },
});

export const { setWorkflowsRunTimer, setWorkflowsTimer, resetWorkflowsTimer } = workflowsTimerSlice.actions;
export default workflowsTimerSlice.reducer;
