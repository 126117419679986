import { iRODSApi as api } from './iRODSSlice';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        postStagingDownload: build.mutation<PostStagingDownloadApiResponse, PostStagingDownloadApiArg>({
            query: queryArg => ({ url: `/staging/download`, method: 'POST', body: queryArg.body }),
        }),
        certificate: build.query<CertificateApiResponse, CertificateApiArg>({
            query: () => ({ url: `/cert` }),
        }),
        downloadDataset: build.mutation<DownloadDatasetApiResponse, DownloadDatasetApiArg>({
            query: queryArg => ({ url: `/dataset/download`, method: 'POST', body: queryArg.body }),
        }),
        deleteDataset: build.mutation<DeleteDatasetApiResponse, DeleteDatasetApiArg>({
            query: queryArg => ({ url: `/dataset`, method: 'DELETE', body: queryArg.body }),
        }),
        createDataset: build.mutation<CreateDatasetApiResponse, CreateDatasetApiArg>({
            query: queryArg => ({ url: `/dataset`, method: 'POST', body: queryArg.body }),
        }),
        queryDatasets: build.mutation<QueryDatasetsApiResponse, QueryDatasetsApiArg>({
            query: queryArg => ({ url: `/dataset/search/metadata`, method: 'POST', body: queryArg.metadataQuery }),
        }),
        deleteDatasetByMetadata: build.mutation<DeleteDatasetByMetadataApiResponse, DeleteDatasetByMetadataApiArg>({
            query: queryArg => ({ url: `/dataset/search/metadata`, method: 'DELETE', body: queryArg.metadataQuery }),
        }),
        checkPermission: build.mutation<CheckPermissionApiResponse, CheckPermissionApiArg>({
            query: queryArg => ({ url: `/dataset/checkpermission`, method: 'POST', body: queryArg.body }),
        }),
        listing: build.mutation<ListingApiResponse, ListingApiArg>({
            query: queryArg => ({ url: `/dataset/listing`, method: 'POST', body: queryArg.body }),
        }),
        postDatasetUpload: build.mutation<PostDatasetUploadApiResponse, any>({
            query: queryArg => ({
                url: `/dataset/upload/`,
                method: 'POST',
                headers: {
                    'Content-Length': queryArg['Content-Length'],
                    'Upload-Length': queryArg['Upload-Length'],
                    'Tus-Resumable': queryArg['Tus-Resumable'],
                    'Upload-Metadata': queryArg['Upload-Metadata'],
                    'Upload-Concat': queryArg['Upload-Concat'],
                    'Upload-Defer-Length': queryArg['Upload-Defer-Length'],
                    'Upload-offset': queryArg['Upload-offset'],
                    'Upload-Checksum': queryArg['Upload-Checksum'],
                },
            }),
        }),
        optionsDatasetUpload: build.mutation<OptionsDatasetUploadApiResponse, OptionsDatasetUploadApiArg>({
            query: () => ({ url: `/dataset/upload/`, method: 'OPTIONS' }),
        }),
        filesDelete: build.mutation<FilesDeleteApiResponse, FilesDeleteApiArg>({
            query: queryArg => ({
                url: `/dataset/upload/${queryArg.id}`,
                method: 'DELETE',
                headers: { 'Tus-Resumable': queryArg['Tus-Resumable'] },
            }),
        }),
        filesHead: build.mutation<FilesHeadApiResponse, FilesHeadApiArg>({
            query: queryArg => ({
                url: `/dataset/upload/${queryArg.id}`,
                method: 'HEAD',
                headers: { 'Tus-Resumable': queryArg['Tus-Resumable'] },
            }),
        }),
        filePatch: build.mutation<FilePatchApiResponse, any>({
            query: queryArg => ({
                url: `/dataset/upload/${queryArg.id}`,
                method: 'PATCH',
                body: queryArg.body,
                headers: {
                    'Tus-Resumable': queryArg['Tus-Resumable'],
                    'Content-Length': queryArg['Content-Length'],
                    'Upload-offset': queryArg['Upload-offset'],
                    'Upload-Checksum': queryArg['Upload-Checksum'],
                },
            }),
        }),
        createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
            query: queryArg => ({ url: `/user`, method: 'POST', body: queryArg.body }),
        }),
        deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
            query: queryArg => ({ url: `/user`, method: 'DELETE', body: queryArg.body }),
        }),
        createProject: build.mutation<CreateProjectApiResponse, CreateProjectApiArg>({
            query: queryArg => ({ url: `/project`, method: 'POST', body: queryArg.body }),
        }),
        deleteProject: build.mutation<DeleteProjectApiResponse, DeleteProjectApiArg>({
            query: queryArg => ({ url: `/project`, method: 'DELETE', body: queryArg.body }),
        }),
        addProjectUser: build.mutation<AddProjectUserApiResponse, AddProjectUserApiArg>({
            query: queryArg => ({ url: `/project/user`, method: 'POST', body: queryArg.userInProject }),
        }),
        deleteProjectUser: build.mutation<DeleteProjectUserApiResponse, DeleteProjectUserApiArg>({
            query: queryArg => ({ url: `/project/user`, method: 'DELETE', body: queryArg.userInProject }),
        }),
        addProjectAdmin: build.mutation<AddProjectAdminApiResponse, AddProjectAdminApiArg>({
            query: queryArg => ({ url: `/project/admin`, method: 'POST', body: queryArg.userInProject }),
        }),
        deleteProjectAdmin: build.mutation<DeleteProjectAdminApiResponse, DeleteProjectAdminApiArg>({
            query: queryArg => ({ url: `/project/admin`, method: 'DELETE', body: queryArg.userInProject }),
        }),
        addSupportUser: build.mutation<AddSupportUserApiResponse, AddSupportUserApiArg>({
            query: queryArg => ({ url: `/support`, method: 'POST', body: queryArg.body }),
        }),
        deleteSupportUser: build.mutation<DeleteSupportUserApiResponse, DeleteSupportUserApiArg>({
            query: queryArg => ({ url: `/support`, method: 'DELETE', body: queryArg.body }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as iRODS };
export type PostStagingDownloadApiResponse = /** status 200 Successful request */ Blob;
export type PostStagingDownloadApiArg = {
    body: {
        source_system: string;
        source_path: string;
    };
};
export type CertificateApiResponse = unknown;
export type CertificateApiArg = void;
export type DownloadDatasetApiResponse = unknown;
export type DownloadDatasetApiArg = {
    /** parameters */
    body: {
        internalID: string;
        access: AccessMode;
        project: string;
        push_method: PushMethod;
        archivetype?: ArchiveType;
        path?: string;
        enc?: 'yes' | 'no';
        comp?: 'yes' | 'no';
    };
};
export type DeleteDatasetApiResponse =
    /** status 201 The delete request was enqueued. StagingAPI and requestID can be concatenated to obtain a query URL about this request. */
    | {
          internalID?: string;
          stagingAPI?: string;
          request_id?: string;
      }
    | /** status 204 Dataset does not exist */ undefined;
export type DeleteDatasetApiArg = {
    /** parameter */
    body: {
        internalID: string;
        access: AccessMode;
        project: string;
        path?: string;
    };
};
export type CreateDatasetApiResponse = /** status 201 dataset created */ ItemCreatedResponse;
export type CreateDatasetApiArg = {
    /** parameters */
    body: {
        zone?: string;
        path?: string;
        push_method: PushMethod;
        compress_method?: CompressMethod;
        file?: string;
        name?: string;
        internalID?: string;
        access: AccessMode;
        project: string;
        metadata?: Metadata;
        enc?: 'yes' | 'no';
        comp?: 'yes' | 'no';
    };
};
export type QueryDatasetsApiResponse =
    /** status 200 Metadata for matching datasets returned */ MetadataQueryResponse[];
export type QueryDatasetsApiArg = {
    /** metadata relating to dataset which is being searched for */
    metadataQuery: MetadataQuery;
};
export type DeleteDatasetByMetadataApiResponse = /** status 204 datasets were deleted */ undefined;
export type DeleteDatasetByMetadataApiArg = {
    /** metadata relating to dataset which is being searched for */
    metadataQuery: MetadataQuery;
};
export type CheckPermissionApiResponse = /** status 200 User has permission to write */ {
    status: string;
};
export type CheckPermissionApiArg = {
    body: {
        access: AccessMode;
        project: string;
    };
};
export type ListingApiResponse = /** status 200 Content of the dataset */ DatasetContent;
export type ListingApiArg = {
    /** parameter */
    body: {
        internalID: string;
        access: AccessMode;
        project: string;
        path?: string;
        zone?: string;
        recursive?: boolean;
    };
};
export type PostDatasetUploadApiResponse = /** status 201 Created */ undefined;
export type PostDatasetUploadApiArg = {
    /** Must be 0 for creation extension. May be a positive number for creation-with-upload extension. */
    'Content-Length'?: number;
    /** The Upload-Length request and response header indicates the size of the entire upload in bytes. The value MUST be a non-negative integer. In the concatenation extension, the Client MUST NOT include the Upload-Length header in the final upload creation */
    'Upload-Length'?: number;
    /** Protocol version */
    'Tus-Resumable': '1.0.0';
    /** Added by the Creation extension. The Upload-Metadata request and response header MUST consist of one or more comma-separated
    key-value pairs. The key and value MUST be separated by a space. The key MUST NOT contain spaces and commas and MUST NOT be empty.
    The key SHOULD be ASCII encoded and the value MUST be Base64 encoded. All keys MUST be unique. The value MAY be empty.
    In these cases, the space, which would normally separate the key and the value, MAY be left out. Since metadata can contain arbitrary
    binary values, Servers SHOULD carefully validate metadata values or sanitize them before using them as header values to avoid header
    smuggling.
    In this API, the following metadata fields are required and will be checked against values in the createDataset endpoint:
    user, project, filename
     */
    'Upload-Metadata'?: string;
    /** Added by the Concatenation extension. The Upload-Concat request and response header MUST be set in both partial and final upload creation requests. It indicates whether the upload is either a partial or final upload. If the upload is a partial one, the header value MUST be partial. In the case of a final upload, its value MUST be final followed by a semicolon and a space-separated list of partial upload URLs that will be concatenated. The partial uploads URLs MAY be absolute or relative and MUST NOT contain spaces as defined in RFC 3986. */
    'Upload-Concat'?: string;
    /** Added by the creation-defer-length extension. The Upload-Defer-Length request and response header indicates that the size of the upload is not known currently and will be transferred later. Its value MUST be 1. If the length of an upload is not deferred, this header MUST be omitted. */
    'Upload-Defer-Length'?: 1;
    'Upload-offset'?: number;
    /** Added by the checksum extension. The Upload-Checksum request header contains information about the checksum of the current body payload. The header MUST consist of the name of the used checksum algorithm and the Base64 encoded checksum separated by a space. */
    'Upload-Checksum'?: string;
};
export type OptionsDatasetUploadApiResponse = /** status 200 Success */ undefined | /** status 204 Success */ undefined;
export type OptionsDatasetUploadApiArg = void;
export type FilesDeleteApiResponse = /** status 204 Upload was terminated */ undefined;
export type FilesDeleteApiArg = {
    id: string;
    /** Protocol version */
    'Tus-Resumable': '1.0.0';
};
export type FilesHeadApiResponse = /** status 200 Returns offset */ undefined;
export type FilesHeadApiArg = {
    id: string;
    /** Protocol version */
    'Tus-Resumable': '1.0.0';
};
export type FilePatchApiResponse = /** status 204 Upload offset was updated */ undefined;
export type FilePatchApiArg = {
    id: string;
    /** Protocol version */
    'Tus-Resumable': '1.0.0';
    'Content-Length': number;
    /** The Upload-Offset request and response header indicates a byte offset within a resource. The value MUST be a non-negative integer. */
    'Upload-offset': number;
    /** Added by the checksum extension. The Upload-Checksum request header contains information about the checksum of the current body payload. The header MUST consist of the name of the used checksum algorithm and the Base64 encoded checksum separated by a space. */
    'Upload-Checksum'?: string;
    body: Blob;
};
export type CreateUserApiResponse = /** status 201 User was created */ undefined;
export type CreateUserApiArg = {
    body: {
        username: string;
        id: string;
    };
};
export type DeleteUserApiResponse = /** status 204 User was deleted */ undefined;
export type DeleteUserApiArg = {
    body: {
        username: string;
    };
};
export type CreateProjectApiResponse = /** status 201 Project was created */ undefined;
export type CreateProjectApiArg = {
    body: {
        projectname: string;
    };
};
export type DeleteProjectApiResponse = /** status 204 Project was deleted or does not exist */ undefined;
export type DeleteProjectApiArg = {
    body: {
        name: string;
    };
};
export type AddProjectUserApiResponse = /** status 201 User was added to the project */ ErrorResponse;
export type AddProjectUserApiArg = {
    userInProject: UserInProject;
};
export type DeleteProjectUserApiResponse = /** status 204 User was removed from project */ undefined;
export type DeleteProjectUserApiArg = {
    userInProject: UserInProject;
};
export type AddProjectAdminApiResponse = /** status 201 Admin was added to Project */ undefined;
export type AddProjectAdminApiArg = {
    userInProject: UserInProject;
};
export type DeleteProjectAdminApiResponse = /** status 204 User was deleted from admin status */ undefined;
export type DeleteProjectAdminApiArg = {
    userInProject: UserInProject;
};
export type AddSupportUserApiResponse = /** status 201 User was added to support group */ undefined;
export type AddSupportUserApiArg = {
    body: {
        username: string;
    };
};
export type DeleteSupportUserApiResponse = /** status 204 User was removed from support duties */ undefined;
export type DeleteSupportUserApiArg = {
    body: {
        username: string;
    };
};
export type ErrorResponse = {
    errorString: string;
};
export type AccessMode = 'public' | 'project' | 'user';
export type PushMethod = 'empty' | 'directupload' | 'tus';
export type ArchiveType = 'zip' | 'file';
export type ItemCreatedResponse = {
    status: string;
    internalID: string;
};
export type CompressMethod = 'zip' | 'file';
export type Metadata = {
    CreationDate?: string;
    CustomMetadata?: object;
    CustomMetadataSchema?: object[];
    creator?: string[];
    contributor?: string[];
    publisher?: string[];
    owner?: string[];
    identifier?: string;
    AlternateIdentifier?: string[][];
    publicationYear?: string;
    resourceType?: string;
    resourceTypeGeneral?:
        | 'Audiovisual'
        | 'Collection'
        | 'DataPaper'
        | 'Dataset'
        | 'Event'
        | 'Image'
        | 'InteractiveResource'
        | 'Model'
        | 'PhysicalObject'
        | 'Service'
        | 'Software'
        | 'Sound'
        | 'Text'
        | 'Workflow'
        | 'Other';
    title?: string;
    relatedIdentifier?: string[];
    rights?: string[];
    rightsIdentifier?: string[];
    rightsURI?: string[];
    workflowEx_id?: string;
    workflow_id?: string;
};
export type Location = {
    access: AccessMode;
    project: string;
    internalID: string;
    zone: string;
};
export type Eudat = {
    'EUDAT/FIO'?: string;
    'EUDAT/FIXED_CONTENT'?: string;
    'EUDAT/PARENT'?: string;
    'EUDAT/ROR'?: string;
    PID?: string;
    'EUDAT/REPLICA'?: string;
};
export type MetadataQueryResponse = {
    location: Location;
    metadata: Metadata;
    eudat?: Eudat;
    flags: any;
};
export type MetadataQuery = {
    CustomMetadata?: object;
    access?: AccessMode;
    project?: string;
    creator?: string;
    contributor?: string;
    publisher?: string;
    owner?: string;
    identifier?: string;
    internalID?: string;
    AlternateIdentifier?: string;
    AlternateIdentifierType?: string;
    publicationYear?: string;
    resourceType?: string;
    resourceTypeGeneral?: string;
    title?: string;
    relatedIdentifier?: string;
    rights?: string;
    rightsIdentifier?: string;
    rightsURI?: string;
    PID?: string;
    'EUDAT/FIO'?: string;
    'EUDAT/PARENT'?: string;
    'EUDAT/ROR'?: string;
    'EUDAT/FIXED_CONTENT'?: string;
    'EUDAT/REPLICA'?: string;
};
export type DatasetContent = {
    name: string;
    type: string;
    size?: number;
    create_time?: string;
    checksum?: string;
    contents?: DatasetContent[];
};
export type UserInProject = {
    username: string;
    projectname: string;
};
export const {
    usePostStagingDownloadMutation,
    useCertificateQuery,
    useDownloadDatasetMutation,
    useDeleteDatasetMutation,
    useCreateDatasetMutation,
    useQueryDatasetsMutation,
    useDeleteDatasetByMetadataMutation,
    useCheckPermissionMutation,
    useListingMutation,
    usePostDatasetUploadMutation,
    useOptionsDatasetUploadMutation,
    useFilesDeleteMutation,
    useFilesHeadMutation,
    useFilePatchMutation,
    useCreateUserMutation,
    useDeleteUserMutation,
    useCreateProjectMutation,
    useDeleteProjectMutation,
    useAddProjectUserMutation,
    useDeleteProjectUserMutation,
    useAddProjectAdminMutation,
    useDeleteProjectAdminMutation,
    useAddSupportUserMutation,
    useDeleteSupportUserMutation,
} = injectedRtkApi;
