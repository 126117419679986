import { createContext, ReactNode } from 'react';
import config from 'config';
import useLocalStorage from 'hooks/useLocalStorage';
import {
    CustomizationProps,
    FirstStart,
    FontFamily,
    I18n,
    MenuOrientation,
    PresetColor,
    ThemeDirection,
    ThemeMode,
} from 'types/config';

const initialState: CustomizationProps = {
    ...config,
    onChangeContainer: () => {},
    onChangeLocalization: (lang: I18n) => {},
    onChangeMode: (mode: ThemeMode) => {},
    onChangePresetColor: (theme: PresetColor) => {},
    onChangeDirection: (direction: ThemeDirection) => {},
    onChangeMiniDrawer: (miniDrawer: boolean) => {},
    onChangeMenuOrientation: (menuOrientation: MenuOrientation) => {},
    onChangeFontFamily: (fontFamily: FontFamily) => {},
    onChangeFirstStart: (firstStart: FirstStart) => {},
    onChangeShowGuide: (showGuide: boolean) => {},
    onChangeTableOptions: (tableOptions: object) => {},
};

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
    children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
    const [config, setConfig] = useLocalStorage('lexis-theme-settings', initialState);

    const onChangeContainer = () => {
        setConfig({
            ...config,
            container: !config.container,
        });
    };

    const onChangeLocalization = (lang: I18n) => {
        setConfig({
            ...config,
            i18n: lang,
        });
    };

    const onChangeMode = (mode: ThemeMode) => {
        setConfig({
            ...config,
            mode,
        });
    };

    const onChangePresetColor = (theme: PresetColor) => {
        setConfig({
            ...config,
            presetColor: theme,
        });
    };

    const onChangeDirection = (direction: ThemeDirection) => {
        setConfig({
            ...config,
            themeDirection: direction,
        });
    };

    const onChangeMiniDrawer = (miniDrawer: boolean) => {
        setConfig({
            ...config,
            miniDrawer,
        });
    };

    const onChangeMenuOrientation = (layout: MenuOrientation) => {
        setConfig({
            ...config,
            menuOrientation: layout,
        });
    };

    const onChangeFontFamily = (fontFamily: FontFamily) => {
        setConfig({
            ...config,
            fontFamily,
        });
    };

    const onChangeFirstStart = (firstStart: FirstStart) => {
        setConfig({
            ...config,
            firstStart,
        });
    };
    const onChangeShowGuide = (showGuide: boolean) => {
        setConfig({
            ...config,
            showGuide,
        });
    };
    const onChangeTableOptions = (tableOptions: object) => {
        setConfig({
            ...config,
            tableOptions,
        });
    };

    return (
        <ConfigContext.Provider
            value={{
                ...config,
                onChangeContainer,
                onChangeLocalization,
                onChangeMode,
                onChangePresetColor,
                onChangeDirection,
                onChangeMiniDrawer,
                onChangeMenuOrientation,
                onChangeFontFamily,
                onChangeFirstStart,
                onChangeShowGuide,
                onChangeTableOptions,
            }}>
            {children}
        </ConfigContext.Provider>
    );
}

export { ConfigProvider, ConfigContext };
