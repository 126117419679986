import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar, LinearProgress } from '@mui/material';
import Drawer from './Drawer';
import Header from './Header';
import HorizontalBar from './Drawer/HorizontalBar';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import useConfig from 'hooks/useConfig';
import { dispatch } from 'store';
import { openDrawer } from 'store/reducers/menu';
import { MenuOrientation } from 'types/config';
import { useGetApiUserInfoQuery, useGetApiUserPreferencesQuery } from 'store/userOrg/enhancedUserOrgApi';
import { error500Path } from 'routes/paths';
import BeginTour from 'components/welcome/tour';
import _ from 'lodash';

const MainLayout = () => {
    const {
        data: user,
        isLoading: userIsLoading,
        isError: userIsError,
        isSuccess: userIsSuccess,
    } = useGetApiUserInfoQuery();

    const theme = useTheme();
    const [skipGePreferences, setSkipGePreferences] = useState(true);
    const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));
    const downLG = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const {
        container,
        miniDrawer,
        tableOptions,
        menuOrientation,
        onChangeMode,
        onChangePresetColor,
        mode,
        presetColor,
        firstStart,
        onChangeFontFamily,
        onChangeFirstStart,
        onChangeTableOptions,
        fontFamily,
    } = useConfig();
    const {
        data: preferences,
        isSuccess: preferencesIsSuccess,
        isLoading: preferencesIsLoading,
        isError: preferencesIsError,
    } = useGetApiUserPreferencesQuery(undefined, { skip: skipGePreferences });
    const preferencesObj = preferences ? JSON.parse(preferences) : {};

    useEffect(() => {
        preferencesIsSuccess &&
            (preferencesObj.presetColor !== presetColor && onChangePresetColor(preferencesObj.presetColor),
            preferencesObj.mode !== mode && onChangeMode(preferencesObj.mode),
            preferencesObj.fontFamily !== fontFamily && onChangeFontFamily(preferencesObj.fontFamily),
            preferencesObj.firstStart !== firstStart && onChangeFirstStart(preferencesObj.firstStart),
            !_.isEqual(preferencesObj.tableOptions, tableOptions) && onChangeTableOptions(preferencesObj.tableOptions));
    }, [
        preferencesObj.mode,
        preferencesObj.presetColor,
        preferencesObj.firstStart,
        preferencesObj.fontFamily,
        preferencesObj.tableOptions,
        firstStart,
        tableOptions,
        mode,
        presetColor,
        fontFamily,
    ]);

    const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

    useEffect(() => {
        (preferencesIsError || userIsError) && navigate(error500Path(true));
        userIsSuccess && setSkipGePreferences(false);
    }, [userIsError, preferencesIsError, userIsSuccess]);

    useEffect(() => {
        if (!miniDrawer) {
            dispatch(openDrawer(!matchDownXL));
        }
    }, [matchDownXL]);

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            {userIsLoading || preferencesIsLoading ? (
                <LinearProgress />
            ) : (
                userIsSuccess &&
                user &&
                preferencesIsSuccess && (
                    <>
                        <Header />
                        {!isHorizontal ? <Drawer /> : <HorizontalBar />}
                        <BeginTour />
                        <Box component='main' sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                            <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />
                            <Container
                                maxWidth={container ? 'xl' : false}
                                sx={{
                                    ...(container && { px: { xs: 0, sm: 2 } }),
                                    position: 'relative',
                                    minHeight: 'calc(100vh - 110px)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <Breadcrumbs />
                                <Outlet />
                            </Container>
                        </Box>
                    </>
                )
            )}
        </Box>
    );
};

export default MainLayout;
