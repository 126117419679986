import { lazy } from 'react';

// project import
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import IntroPage from 'pages/intro';
import { resourceRequestMailPath } from './paths';

// render - login
const GuestGuard = Loadable(lazy(() => import('utils/route-guard/GuestGuard')));
const ResourceRequestMailPath = Loadable(lazy(() => import('pages/emailRequest/resourceRequest')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <GuestGuard>
                    <CommonLayout />
                </GuestGuard>
            ),
            children: [
                {
                    path: '/',
                    element: <IntroPage />,
                },
                {
                    path: 'login',
                    element: <IntroPage />,
                },
                {
                    path: resourceRequestMailPath(),
                    element: <ResourceRequestMailPath />,
                },
            ],
        },
    ],
};

export default LoginRoutes;
