import { useState } from 'react';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import ThemeSelection from './themeSelection';
import ModeSelection from './modeSelection';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ThemeFontFamily from './themeFontFamily';

const ThemeSettings = () => {
    const [expandedThemeSettings, setExpandedThemeSettings] = useState(false);

    const expandThemeSettings = () => {
        setExpandedThemeSettings(true);
    };

    const hideThemeSettings = () => {
        setExpandedThemeSettings(false);
    };

    return (
        <>
            <Button
                sx={{ m: 3 }}
                onClick={() => (!expandedThemeSettings ? expandThemeSettings() : hideThemeSettings())}>
                Theme settings {!expandedThemeSettings ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </Button>

            {expandedThemeSettings && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ModeSelection />
                    </Grid>
                    <Grid item xs={12}>
                        <ThemeSelection />
                    </Grid>
                    <Grid item xs={12}>
                        <ThemeFontFamily />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default ThemeSettings;
