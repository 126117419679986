import { FormattedMessage } from 'react-intl';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { NavItemType } from 'types/menu';
const icons = {
    MenuBookIcon,
};

const additional: NavItemType = {
    id: 'additional',
    title: <FormattedMessage id='additional' />,
    type: 'group',
    children: [
        {
            id: 'documentation',
            title: <FormattedMessage id='documentation' />,
            type: 'item',
            url: 'https://docs.lexis.tech/',
            icon: icons.MenuBookIcon,
            external: true,
            target: true,
        },
        {
            id: 'version',
            title: <FormattedMessage id='version' />,
            type: 'item',
            disabled: true,
            external: true,
            target: true,
        },
    ],
};

export default additional;
