import axios from 'axios';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

export const downloadFile = async transferStatus => {
    try {
        const { protocol, host, port } = window.location;

        let progressToastId;

        const response = await axios.get(
            `${protocol}//${host}${port.length > 0 ? '' : `:${port}`}/api/transfer/download/${
                transferStatus?.request_id
            }`,
            {
                responseType: 'blob',
                onDownloadProgress: progressEvent => {
                    const percentComplete =
                        progressEvent.total !== undefined
                            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            : undefined;

                    if (percentComplete !== undefined) {
                        if (!progressToastId) {
                            progressToastId = toast.info(`Downloading... ${percentComplete}%`, { autoClose: false });
                        } else {
                            toast.update(progressToastId, { render: `Downloading... ${percentComplete}%` });
                        }
                    }
                },
            }
        );

        const fileData = response.data;
        const blob = new Blob([fileData], { type: 'application/gzip' });
        saveAs(blob, transferStatus?.filename);

        if (progressToastId) {
            toast.update(progressToastId, {
                render: 'Download complete',
                type: toast.TYPE.SUCCESS,
                autoClose: 3000,
            });
        }
    } catch (error) {
        console.error('Error downloading file:', error);
        toast.error('Error downloading file');
    }
};
